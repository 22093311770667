import { useState, useEffect } from 'react';


const useCurrencyMark = () => {
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    const fetchLKRRate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
            })
            if (response.status == 200) {
              const data = await response.json()
              setCurrency(data.result.currency);
            }
          } catch (error) {
            console.error('Error:', error)
          }
    };

    fetchLKRRate();
    
  }, []); // Empty array means this will only run on component mount

  return { currency};
};

export default useCurrencyMark;
