import React from 'react';
import {
  Container,
  Card,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Button,
  Paper,
} from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
// import SideMenu from '../components/sidemenu';
import UpcomingCard from '../components/upcomingcard';
import TodayCard from '../components/todaycard';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CircularIndeterminate from '../components/loading';
import MoneyIcon from '@mui/icons-material/Money';
import { Dialog, DialogContent, DialogTitle, Box, TextField, Tooltip, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PendingApprovalCard from '../components/pendingApprovalcard';
import { useNavigate, useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TempSidebar from '../components/TempSidebar';
import useLogout from '../hooks/logouthook';
import LinearProgress from '@mui/material/LinearProgress';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';
import Swal from 'sweetalert2';
import { Icon } from '@iconify/react/dist/iconify.js';

function Dashboard() {
  const studentID = localStorage.getItem('studentID');
  const [stu_Data, setStu_Data] = useState({
    stu_id: '',
    stu_fname: '',
    stu_lname: '',
    profile: '',
    acc_balance: '',
    phone_num: '',
    email: '',
    NIC: '',
    city: '',
    country: '',
    address: '',
    gender: '',
    birthday: '',
    email_verification: 2
  });
  const [currency, setCurrency] = useState('');
  const [todayClassCount, setTodayClassCount] = useState(0);
  const [upcomingClassCount, setUpcomingClassCount] = useState(0);
  const [pendingApprovalsCount, setPendingApprovalsCount] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);
  const [amount, setAmount] = useState('');
  const [accBalance, setAccBalance] = useState({ totalAmount: 0, acc_balance: 0 });
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const { handleLogout } = useLogout();
  const [commpleteProStatus, setCommpleteProStatus] = useState(2);
  const [t_email, setT_email] = useState('');

  useEffect(() => {
    if (studentID) {
      fetchProfileStatus()
      fetchStu_Data();
      fetchTodayClassCount();
      fetchUpcomingClassCount();
      fetchPendingApprovalsCount();
      window.scrollTo(0, 0);
    }
  }, [studentID]);




  const handleProfilestatus = () => {
    navigate('/profile_setting')
  }

  const fetchProfileStatus = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/complete_pro_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: studentID
      })
    });
    const data = await response.json();
    if (response.status === 200) {
      setCommpleteProStatus(data.result.profile_complete);
    }

  }

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const fetchStu_Data = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
        method: 'POST',
        body: JSON.stringify({
          studentID

        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchStu_Data()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        let getData = await response.json();
        console.log(getData?.student?.email_verification)
        setStu_Data({
          stu_id: getData?.student?.stu_id || '',
          stu_fname: getData?.student?.stu_fname || '',
          stu_lname: getData?.student?.stu_lname || '',
          profile: getData?.student?.profile || '',
          acc_balance: getData?.student?.acc_balance || '',
          phone_num: getData?.student?.phone_num || '',
          email: getData?.student?.email || '',
          NIC: getData?.student?.NIC || '',
          city: getData?.student?.city || '',
          country: getData?.student?.country || '',
          address: getData?.student?.address || '',
          gender: getData?.student?.gender || '',
          birthday: getData?.student?.birthday || '',
          email_verification: getData.student.email_verification
        });
        setCurrency(getData?.currency?.currency || '');
      }
    } catch (err) {

    }
  };



  const fetchTodayClassCount = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/todayclass_count`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          studentID

        }),
      });
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchTodayClassCount()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        let getData = await response.json();

        setTodayClassCount(getData)
        setLoading(false);
      }

    } catch (err) {

    }
  };

  const fetchUpcomingClassCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upcomingclass_count`, {
        method: 'POST',
        body: JSON.stringify({
          studentID

        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }


      });
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchUpcomingClassCount()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        let getData = await response.json();

        setUpcomingClassCount(getData)
      }

    } catch (err) {

    }
  };

  const fetchPendingApprovalsCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pendingApprovals_count`, {
        method: 'POST',
        body: JSON.stringify({
          studentID

        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }


      });
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchPendingApprovalsCount()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        let getData = await response.json();

        setPendingApprovalsCount(getData)
      }


    } catch (err) {

    }
  };


  // Top Up Modal

  const handleOpenTopUpModal = () => {
    setOpenTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setOpenTopUpModal(false);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setErrors("")


    if (isNaN(value)) {
      setErrors("Please enter a valid amount");
    } else {
      setErrors('');
    }
    setAmount(value);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmitPay(event);
    }
  };

  const handleSubmitPay = async (event) => {
    event.preventDefault();

    const storedStudentID = localStorage.getItem('studentID');

    setErrors("");

    if (!amount) {
      setErrors("Please add an amount");
      return;
    }
    const numericAmount = parseInt(amount, 10);
    if (numericAmount < 1000 || numericAmount > 15000) {
      setErrors("Amount must be between 1000 and 15000");
      return;
    }


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account_topup`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          amount


        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            handleSubmitPay(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        const data = await response.json();

        if (data.error) {
          setErrors(data.error);
        } else {
          // setStu_Data1(data);
          setAmount("");


          window.payhere.onCompleted = function onCompleted(orderId) {
            console.log("Payment completed. OrderID:" + orderId);
            window.location.reload();

          };


          window.payhere.onDismissed = function onDismissed() {

            console.log("Payment dismissed");
          };


          window.payhere.onError = function onError(error) {

            console.log("Error:" + error);
          };

          // console.log('stu_Data1.order_id',stu_Data1.order_id)

          const payment = {
            sandbox: true,
            merchant_id: data.merchantId,
            return_url: `${process.env.PRODUCTION_URL}/account_topup`,
            cancel_url: `${process.env.PRODUCTION_URL}/account_topup`,
            notify_url: 'https://englishteacher.sparkglobalsolutions.net/notifyWeb_Production.php',
            order_id: data.order_id.toString(),
            items: 'Account Topup',
            currency: data.currency.toString(),
            amount: amount.toString(),
            first_name: data.first_name.toString(),
            last_name: data.last_name.toString(),
            email: data.email.toString(),
            phone: data.phone.toString(),
            address: data.address.toString(),
            custom_1: data.studentID.toString(),
            city: data.city.toString(),
            country: 'Sri Lanka',
            hash: data.hash.toString(),
          };
          window.payhere.startPayment(payment);
          setOpenTopUpModal(false);
        }
      }

    } catch (error) {
      console.error("Error fetching data: ", error);

    }
  };

  const handleverifyEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/email-verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          studentID,
          email: stu_Data.email,

        })
      })

      const data = await response.json()

      if (response.status == 200) {
        Swal.fire({
          title: 'Success!',
          text: data.message,
          icon: 'success'
        })
      }
    } catch (error) {
      console.error(error)
    }
  }


  console.log(stu_Data)

  return (
    <div className="home-page">
      <Helmet>
        <title>Dashboard - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
      <NavbarLog />
      <Container sx={{ py: 3, mt: 4 }}>
        <Card sx={{ my: 3 }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
            <Grid container spacing={2} sx={{ mb: 2, backgroundColor: '' }}>

              <Grid item xs={12} md={3.2} sx={{}}>
                <TempSidebar />
              </Grid>

              <Grid item xs={12} md={8.8} >


                <Grid container spacing={2} sx={{ color: 'white', mb: '8px' }}>

                  {loading ? (
                    <Grid item xs={12} sx={{ textAlign: 'center', py: 5 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularIndeterminate />
                      </Box>
                    </Grid>
                  ) : (
                    <>


                      <Grid item xs={4} sm={2.5}>
                        <Link to={`/today_class`} style={{ textDecoration: 'none', color: 'black' }}>
                          <Card
                            // sx={{
                            //   borderRadius: '8px',
                            //   boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
                            //   border: '1px solid #00669e',
                            //   position: 'relative',
                            //   overflow: 'hidden',
                            //   mb:1,
                            //   backgroundImage:'/images/CardBack.png',
                            // }}
                            sx={{
                              borderRadius: '8px',
                              boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
                              border: '1px solid #00669e',
                              position: 'relative',
                              overflow: 'hidden',
                              mb: 1,
                              backgroundImage: 'url(/images/CardBack.png)',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                            }}
                          >

                            <CardContent
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px !important',
                                height: '90px',
                                zIndex: 1,
                                position: 'relative',
                              }}
                            >
                              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ lineHeight: '0.5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontWeight: 'bold',
                                      fontSize: 20,
                                      pl: 0.1,
                                      mr: { xs: 0, sm: 2 },
                                    }}
                                  >
                                    {todayClassCount.classes_today < 10 ? `0${todayClassCount.classes_today}` : todayClassCount.classes_today}

                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'white',
                                      fontSize: 12,
                                      mr: { xs: 0, sm: 2 },
                                      textAlign: { xs: 'center', sm: 'right' },
                                    }}
                                  >
                                    Today
                                  </Typography>
                                </Box>
                                <Box sx={{ display: { xs: 'none', sm: 'flex', lg:'flex' }, justifyContent: 'center', alignItems: 'center', backgroundColor: '', borderRadius: '8px', border: 3, borderColor: 'white', width: '40px' }}>
                                  <TodayIcon sx={{ color: 'white', fontSize: 30, py: 'auto' }} />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>


                      <Grid item xs={4} sm={2.5}>
                        <Link to={`/upcoming_class`} style={{ textDecoration: 'none', color: 'black' }}>
                          <Card
                            // sx={{
                            //   borderRadius: '8px',
                            //   boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
                            //   border: '1px solid #00669e',
                            //   position: 'relative',
                            //   overflow: 'hidden',
                            //   mb:1,
                            //   backgroundImage:'/images/CardBack.png',
                            // }}
                            sx={{
                              borderRadius: '8px',
                              boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
                              border: '1px solid #00669e',
                              position: 'relative',
                              overflow: 'hidden',
                              mb: 1,
                              backgroundImage: 'url(/images/CardBack.png)',
                              backgroundSize: 'cover', // Ensures the image covers the card fully
                              backgroundPosition: 'center', // Centers the image in the card
                              backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            }}
                          >

                            <Box
                              sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                zIndex: 0,
                                pointerEvents: 'none',
                              }}
                            >

                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '-20px',
                                  left: '-20px',
                                  width: '80px',
                                  height: '80px',
                                  backgroundColor: 'rgba(0, 102, 158, 0.2)',
                                  borderRadius: '50%',
                                }}
                              />


                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: '-20px',
                                  right: '-20px',
                                  width: '100px',
                                  height: '100px',
                                  backgroundColor: 'rgba(0, 102, 158, 0.2)',
                                  borderRadius: '50%',
                                }}
                              />
                            </Box>

                            <CardContent
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px !important',
                                height: '90px',
                                zIndex: 1,
                                position: 'relative',
                              }}
                            >
                              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ lineHeight: '0.5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontWeight: 'bold',
                                      fontSize: 20,
                                      pl: 0.1,
                                      mr: { xs: 0, sm: 2 },
                                    }}
                                  >

                                    {upcomingClassCount.classes_upcoming < 10 ? `0${upcomingClassCount.classes_upcoming}` : upcomingClassCount.classes_upcoming}

                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'white',
                                      fontSize: 12,
                                      mr: { xs: 0, sm: 2 },
                                      textAlign: { xs: 'center', sm: 'right' },
                                    }}
                                  >
                                    Upcoming
                                  </Typography>
                                </Box>
                                <Box sx={{ display: { xs: 'none', sm: 'flex', lg:'flex'}, justifyContent: 'center', alignItems: 'center', backgroundColor: '', borderRadius: '8px', border: 3, borderColor: 'white', width: '40px' }}>
                                  <CalendarMonthIcon sx={{ color: "white", fontSize: 30, py: 'auto' }} />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={4} sm={2.5}>
                        <Link to={`/pendingApproval_class`} style={{ textDecoration: 'none', color: 'black' }}>
                          <Card
                            sx={{
                              borderRadius: '8px',
                              boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
                              border: 'px solid #00669e',
                              position: 'relative',
                              overflow: 'hidden',
                              mb: 1,
                              backgroundImage: 'url(/images/CardBack.png)',
                              backgroundSize: 'cover', // Ensures the image covers the card fully
                              backgroundPosition: 'center', // Centers the image in the card
                              backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            }}
                          >

                            <Box
                              sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                zIndex: 0,
                                pointerEvents: 'none',
                              }}
                            >

                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '-20px',
                                  left: '-20px',
                                  width: '80px',
                                  height: '80px',
                                  backgroundColor: 'rgba(0, 102, 158, 0.2)',
                                  borderRadius: '50%',
                                }}
                              />


                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: '-20px',
                                  right: '-20px',
                                  width: '100px',
                                  height: '100px',
                                  backgroundColor: 'rgba(0, 102, 158, 0.2)',
                                  borderRadius: '50%',
                                }}
                              />
                            </Box>

                            <CardContent
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px !important',
                                height: '90px',
                                zIndex: 1,
                                position: 'relative',
                              }}
                            >
                              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ lineHeight: '0.5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontWeight: 'bold',
                                      fontSize: 20,
                                      pl: 0.1,
                                      mr: { xs: 0, sm: 2 },
                                    }}
                                  >

                                    {pendingApprovalsCount.classes_pendingApprovals < 10 ? `0${pendingApprovalsCount.classes_pendingApprovals}` : pendingApprovalsCount.classes_pendingApprovals}

                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'white',
                                      fontSize: 12,
                                      mr: { xs: 0, sm: 2 },
                                      textAlign: {  xs: 'none', md: 'none', lg:'flex' },
                                    }}
                                  >
                                    Pending
                                  </Typography>
                                </Box>
                                <Box sx={{ display: {  xs: 'none', sm: 'flex', lg:'flex' }, justifyContent: 'center', alignItems: 'center', backgroundColor: '', borderRadius: '8px', border: 3, borderColor: 'white', width: '40px' }}>
                                  <PendingActionsIcon sx={{ color: "white", fontSize: 30, py: 'auto' }} />

                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={4.5}>
                        <Card sx={{
                          borderRadius: '8px',
                          boxShadow: '0 8px 15px rgba(0.1, 0.1, 0.1, 0.1)',
                        }}
                          style={{
                            border: '3px solid #00669e'
                          }}>
                          <CardContent sx={{ padding: '8px !important', height: '100%', }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:" space-between" }}>
                              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                                {Number(stu_Data?.acc_balance).toFixed(2)}
                              </Typography>

                              <Box sx={{ display: { xs: 'flex', sm: 'none',lg:'flex' }, ml: 3 }} >
                                <Icon icon="logos:visa" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />
                                <Icon icon="logos:mastercard" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />
                                <Icon icon="simple-icons:americanexpress" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }}/>
                                <img
                                  src="/images/sampath-bank-logo.png"
                                  alt="sampath"
                                  style={{ width: '1.4rem', height: '1.4rem'}}
                                />
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2" sx={{ color: 'black', display: 'flex', flexDirection: 'row' }}>
                                  Account Balance ({currency})
                                </Typography>

                                <Box sx={{ display: { xs: 'none', sm: 'flex', lg:'none' } }}>
                                  <Icon icon="logos:visa" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />
                                  <Icon icon="logos:mastercard" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />
                                  <Icon icon="simple-icons:americanexpress" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />
                                  <img
                                    src="/images/sampath-bank-logo.png"
                                    alt="sampath"
                                    style={{ width: '1.4rem', height: '1.4rem' }}
                                  />
                                </Box> 
                             </Box> 

                              <Button
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#00669e',
                                  '&:hover': { backgroundColor: '#0a1b4b' },
                                  borderRadius: '6px',
                                  padding: '3px 9px',
                                  fontSize: '12px',
                                  textTransform: 'none',
                                  minWidth: '80px',

                                }}
                                onClick={handleOpenTopUpModal}
                              >
                                Top-Up
                              </Button>
                              {errors ?
                                <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0 }}>
                                  {errors}
                                </Typography>
                                : null
                              }
                            </Box>

                          </CardContent>
                        </Card>

                      </Grid>
                    </>
                  )}
                </Grid>

                {commpleteProStatus === 0 ? (
                  // <Card sx={{ width: '600px', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px', width: '100%', mb: 1, cursor: 'pointer' }} onClick={handleProfilestatus}>
                  //   <CardContent>
                  //     <Typography>Complete your profile</Typography>
                  //     <Box sx={{ width: '100%' }}>
                  //       <LinearProgress variant="determinate" value={80} />
                  //     </Box>
                  //   </CardContent>
                  // </Card>
                  <Link to="/profile_setting" style={{ textDecoration: 'none', color: 'black' }}>
                    <Card
                      sx={{
                        // width: '600px',
                        boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px',
                        border: '1px solid rgba(76, 78, 100, 0.12)',
                        borderRadius: '10px',
                        width: '100%',
                        mb: 1,
                        cursor: 'pointer',
                        position: 'relative', // Add this to position the circle
                      }}

                    >
                      <CardContent>
                        <Typography sx={{display:'flex', justifyContent:'space-between'}}>Complete your profile 
                        <Link to="/profile_setting"> 
                          <Typography
                          style={{
                            textDecoration: 'underline',
                            color: 'gray',
                            cursor: 'pointer',
                            transition: 'color 0.3s',
                          }}
                          >Complete</Typography>
                          </Link>
                        </Typography>
                       
                        <Box sx={{ position: 'relative', width: '100%' }}>
                          {/* Progress bar */}
                          <LinearProgress
                            variant="determinate"
                            value={80}
                            sx={{
                              height: '8px',
                              borderRadius: '5px',
                            }}
                          />

                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: `${80}%`,
                              transform: 'translate(-50%, -50%)',
                              width: '16px',
                              height: '16px',
                              borderRadius: '50%',
                              backgroundColor: 'blue',
                              border: '2px solid white', // Optional border for better contrast
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                ) : null}

                {stu_Data.email_verification === 0 && (
                  <Box  sx={{ cursor: 'pointer' }}>
                    <Alert icon={false} severity='warning' sx={{ mb: 1, display: 'flex', justifyContent: 'center', backgroundColor: '#fff6e5', color: '#e4a52b', boxShadow: "0px 0px 6px #10184730" }}>
                      <AlertTitle sx={{ fontWeight: 400, mt: '8px', mb: (theme) => `${theme.spacing(1)} !important` }}>
                        Check your inbox to verify your email address {' '} <span
                          style={{
                            fontWeight: 700,
                            color: '#00669E',
                            cursor: 'pointer',

                          }}
                        >
                          {stu_Data.email}
                        </span> {' '}<span
                          style={{
                            textDecoration: 'underline',
                            color: 'gray',
                            cursor: 'pointer',
                            transition: 'color 0.3s',
                          }}
                          onMouseEnter={(e) => (e.target.style.color = '#00669E')}
                          onMouseLeave={(e) => (e.target.style.color = 'gray')}
                          onClick={handleverifyEmail}
                        >
                          Resend
                        </span>
                      </AlertTitle>
                      {/* Minimum 8 characters long, uppercase & symbol */}
                    </Alert>
                  </Box>
                )}

                <TodayCard />

                <UpcomingCard />

                <PendingApprovalCard />

              </Grid>

            </Grid>
          </CardContent>
        </Card>

      </Container>

      <Dialog open={openTopUpModal} onClose={handleCloseTopUpModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', mb: 2 }}>
          <DialogTitle>Top-Up your Account</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTopUpModal} />
          </Tooltip>
        </Grid>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card xs={12} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'left', width: '100%' }}>
            <CardContent>
              <Grid container direction="column" alignItems="left" sx={{ width: '100%' }}>
                <Grid sx={{ display: 'flex', alignContent: 'flex-end' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#fafafa', borderRadius: 2, boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)', p: 1.2 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: "500", color: 'black', }}>Account Balance : {Number(stu_Data.acc_balance).toFixed(2)}</Typography>
                    <Typography sx={{ ml: 0.5, pb: 0.2, fontSize: 15, fontWeight: "500", color: '#888885', alignContent: 'flex-end' }}>{stu_Data?.currency} </Typography>
                  </Box>
                </Grid>

                <Stack direction="row" justifyContent='space-between' spacing={2} sx={{ mt: 3 }} >
                  <Grid item xs={12} sx={{ mt: 5 }} >
                    <TextField
                      label="Amount"
                      value={amount}
                      onChange={handleAmountChange}
                      onKeyDown={handleEnter}
                      fullWidth
                    />
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    size="md"
                    sx={{ border: '1px solid black', borderRadius: 1, backgroundColor: '#101847', width: '65%', mt: 5 }}
                    onClick={(event) => handleSubmitPay(event, studentID, amount)}
                  >
                    Pay with card
                  </Button>
                </Stack>
                {errors ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0 }}>
                    {errors}
                  </Typography>
                  : null
                }



                <Grid item xs={12} sx={{ mt: 3, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href="https://www.payhere.lk" target="_blank" rel="noopener noreferrer">
                    <img src="https://www.payhere.lk/downloads/images/payhere_long_banner.png" alt="PayHere" style={{ maxWidth: '100%', height: 'auto' }} />
                  </a>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
      <Footer />

    </div>
  );
}

export default Dashboard;
