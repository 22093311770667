import React from 'react';
import { useParams } from 'react-router-dom';
import OneTicketView from '../../../components/tickets/OneTicketView';
import {
  Container,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import NavbarLog from '../../../components/navbarlogged';
import TempSidebar from '../../../components/TempSidebar';
import Footer from '../../../components/footer';
import {Helmet} from "react-helmet";
import themeConfig from '../../../config/themeConfig';


const TicketViewPage = () => {

  const { id } = useParams();

  return (
    <div>
      <Helmet>
        <title>Ticket View ({id}) - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
       <NavbarLog />
      <Container sx={{ py: 3, mt: 4 }}>
        <Card sx={{ my: 3 }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
            <Grid container spacing={2} sx={{ mb: 2, backgroundColor: '' }}>

              <Grid item xs={12} md={3.2} sx={{}}>
                <TempSidebar />
              </Grid>

              <Grid item xs={12} md={8.8} >
              <OneTicketView id={id} />
              </Grid>
              
            </Grid>
          </CardContent>
        </Card>

      </Container>
     <Footer/>
     
    </div>
  )
}

export default TicketViewPage
