import React from 'react';

import {
  Container,
  Card,
  CardHeader,
  CardContent, 
  Grid, 
  Avatar, 
  Typography,
  Box,
  Stack,
  Paper,
  Button, 
  TextField,
  Table

} from '@mui/material';
import {styled} from '@mui/material/styles';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
// import SideMenu from '../components/sidemenu';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SlipUploadTable from '../components/sliptable';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { Icon } from "@iconify/react";
import useLogout from '../hooks/logouthook';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';
import { useLocation } from 'react-router-dom';



const Item = styled(Paper)(({theme})=>({
    backgroundColor: theme.palette.mode === 'dark'? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  
  
function SlipUpload() {

  const [stu_Data, setStu_Data] = useState({
    stu_id: '',
    stu_fname: '',
    stu_lname: '',
    profile: '',
    acc_balance: '',
    phone_num: '',
    email: '',
    NIC: '',
    city: '',
    country: '',
    address: '',
    gender: '',
    birthday: ''
  });
  const [currency, setCurrency] = useState('');
  const studentID = localStorage.getItem('studentID');
  const [pay_amount, setPay_amount] = useState("");
  const [note, setNote] = useState("");
  const [file, setFile] = useState("");
  const [data,setData]= useState("");
  const [errors, setErrors] = useState("");
  const [errors1, setErrors1] = useState("");
  const [errors2, setErrors2] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {handleLogout} = useLogout()

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const removeuploadfile = () => {
    setFile("");
  };

  useEffect(() => {
    if (studentID) { 
      fetchStu_Data();
      window.scrollTo(0, 0);
    }
  }, [studentID]);
  
  const fetchStu_Data = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
        method: 'POST',
        body: JSON.stringify({
          studentID

        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }
        
       
      });
      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }
    
        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });
    
          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken); 
            token = tokendata.accessToken;
    
    
            return fetchStu_Data();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout(); 
        }
    
        return; 
      }else{
        let getData = await response.json();

      setStu_Data({
        stu_id: getData?.student?.stu_id || '',
        stu_fname: getData?.student?.stu_fname || '',
        stu_lname: getData?.student?.stu_lname || '',
        profile: getData?.student?.profile || '',
        acc_balance: getData?.student?.acc_balance || '',
        phone_num: getData?.student?.phone_num || '',
        email: getData?.student?.email || '',
        NIC: getData?.student?.NIC || '',
        city: getData?.student?.city || '',
        country: getData?.student?.country || '',
        address: getData?.student?.address || '',
        gender: getData?.student?.gender || '',
        birthday: getData?.student?.birthday || ''
      });
      setCurrency(getData?.currency?.currency || '');
      }


      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
      
    } catch (err) {
      
    } 
  };


  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Capture the selected file
    e.target.value =""
    setErrors2("")
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setErrors1("")
    

    if ( isNaN(value)) {
      setErrors1("Please enter a valid amount");
    } else {
      setErrors1(''); 
    }
    setPay_amount(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors("");
    setErrors1("");
    setErrors2("");
  
    if (!pay_amount) {
      setErrors1("Please add the amount");
      return;
    }

    const numericAmount = parseInt(pay_amount, 10);

    if (!(numericAmount)) {
      setErrors("Please enter a valid amount");
      return;
    }
    // if (numericAmount < 1000 || numericAmount > 15000) {
    //   setErrors("You can Top-Up between 1000 and 15000 amount");
    //   return;
    // }
  
    if (!file) {
      setErrors2("Please upload the slip");
      return;
    }
  
    const formData = new FormData();
    formData.append('studentID', studentID);
    formData.append('pay_amount', pay_amount);
    formData.append('note', note);
    formData.append('file', file);
    
    setLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/slip_upload`, {
        method: 'POST',
        body: formData, 
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
  
      const data = await response.json();

      if (data === 'updated') {  
        setPay_amount(""); 
        setNote("");      
        setFile(null);
        
        Swal.fire({
          icon: 'success',
          title: 'Payment Successful!',
          text: 'Bank payment has been successfully processed.',
          confirmButtonText: 'OK',
          confirmButtonColor: '#3085d6'
        });
        window.location.reload();

      } else {
        setErrors(data.error);
      }
        
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false); 
  };



  return (
    <div className="home-page">
      <Helmet>
        <title>Bank Payments - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                <Hidden smDown>
                    <Common />
                  </Hidden>

                <Grid xs={12} md={8.8} sx={{mb:2}}>

                <div>
                     <Card sx={{ mb: 1,width: '100%' }}>
                              {/* <CardHeader title={<Typography sx={{display:'flex', justifyContent:'center', fontSize:20}}>Bank Payments</Typography>} /> */}
                              <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Bank Payments</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} /> 
                              <CardContent>
                                <Card xs={12} sx={{borderRadius: 2,boxShadow: 2,display: 'flex', justifyContent:'left',  width: '100%'}}>
                                  <CardContent sx={{ width: '100%' }}>
                                    <Grid container direction="column" alignItems="flex-start" sx={{ width: '100%' }}>
                                
                                    <Grid container  alignItems="center" justifyContent="space-between">
                                      <Grid item>
                                        <Typography>
                                          Bank Slip Upload
                                        </Typography>
                                      </Grid>

                                      <Grid item>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#fafafa', borderRadius: 2, boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)', p: 1.2 }}>
                                          <Typography sx={{ fontSize: 15, fontWeight: "500", color: 'black' }}>
                                            Account Balance : {Number(stu_Data.acc_balance).toFixed(2)}
                                          </Typography>
                                          <Typography sx={{ ml: 0.5, pb: 0.2, fontSize: 15, fontWeight: "500", color: '#888885' }}>
                                            {currency}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>

                                      {/* <Grid 
                                        item 
                                        xs={12} 
                                        sx={{ 
                                          border: '1px solid black', 
                                          borderRadius: 1,
                                          width: '100%',
                                          mt: 2,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center', 
                                          alignItems: 'center' 
                                        }}
                                      >
                                        <Stack direction="row" sx={{ mt: 2 }}>
                                          <label htmlFor="file-upload-input" style={{ cursor: 'pointer' }}>
                                            <FileUploadIcon />
                                          </label>
                                          <input
                                            id="file-upload-input"
                                            type="file"
                                            accept=".jpg, .png, .pdf" 
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                          />
                                          <Typography>Upload your slip</Typography>
                                        </Stack>
                                        
                                        {file && (
                                          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                            JPG, PNG, or PDF format, Maximum 5 MB file size
                                          </Typography>
                                        )}

                                        {file && (
                                          <Typography variant="body2" color="textSecondary">
                                            Selected file: {file.name}
                                          </Typography>
                                        )}

                                        {errors && (
                                          <Grid item xs={12}>
                                            <Typography color="error" sx={{  textAlign: "center", fontSize: "16px" }}>{errors}</Typography>
                                          </Grid>
                                        )}
                                      </Grid> */}

                                    <Grid 
                                      item 
                                      xs={12} 
                                      sx={{ 
                                        border: '1px solid black', 
                                        borderRadius: 1,
                                        width: '100%',
                                        mt: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        cursor: 'pointer', 
                                        position: 'relative', 
                                        textAlign: 'center' ,
                                      }}
                                    >
                                   
                                      <label htmlFor="file-upload-input" style={{ width: '100%', height: '100%', cursor: "pointer", }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          <FileUploadIcon sx={{ fontSize: 50 }} />
                                          <Typography sx={{ mt: 1 }}>Upload your slip</Typography>
                                        </div>

                                  
                                        <input
                                          id="file-upload-input"
                                          type="file"
                                          accept=".jpg, .png, .pdf" 
                                          style={{ display: 'none' }}
                                          onChange={handleFileChange}
                                        />
                                      </label>

                                      {file && (
                                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                          JPG, PNG, or PDF format, Maximum 5 MB file size
                                        </Typography>
                                      )}

                                      {/* {file && (
                                        <Typography variant="body2" color="textSecondary">
                                           {file.name}
                                        </Typography>
                                      )} */}
                                      {file && (
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                              sx={{
                                                backgroundColor: "#b1dbf1",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: 25,
                                              }}
                                            >
                                            <Typography variant="body2" color="textSecondary" style={{ marginRight: '8px' }}>
                                              {file.name}
                                            </Typography>
                                            </Box>
                                            <Box
                                              aria-label="sendsms"
                                              sx={{
                                                backgroundColor: "#b1dbf1",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: 25,
                                                cursor: "pointer",
                                              }}
                                              onClick={removeuploadfile}
                                            >
                                              <Icon icon="iconamoon:close-fill" />
                                            </Box>
                                            
                                            
                                            
                                          </div>
                                        )}

                                      {errors2 && (
                                        <Grid item xs={12}>
                                          <Typography color="error" sx={{ textAlign: "center", fontSize: "16px" }}>
                                            {errors2}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>


                                   
                                      <Stack direction="row" justifyContent='space-between' spacing={4} sx={{ width: '100%',mt:2 }}>
                                      <Grid item xs={12}  >
                                      <TextField
                                        label="Amount"
                                        required
                                        onChange={(e) => {
                                          handleAmountChange(e);
                                          setPay_amount(e.target.value)
                                        }}

                                        // onChange={(e) => {
                                        //   handleFileChange(e);
                                        //   setEdit_Data({ ...edit_Data, profile: URL.createObjectURL(e.target.files[0]) });
                                        // }}
                                        fullWidth
                                      />
                                      {errors1 && (
                                          <Grid item xs={12}>
                                            <Typography color="error" sx={{  textAlign: "center", fontSize: "16px" }}>{errors1}</Typography>
                                          </Grid>
                                        )}
                                      </Grid>


                                      <Grid item xs={12}  >
                                      <TextField
                                        label="note"
                                        onChange={(e) => setNote(e.target.value)}
                                        fullWidth
                                      />
                                      </Grid>
                                     
                                      <Button 
                                        type="submit"
                                        variant="contained"
                                        size="md"
                                        sx={{ border: '1px solid black', borderRadius: 4, backgroundColor: '#101847', width: '30%',height: '55px', mt: 3 }}
                                        onClick={(event) => handleSubmit(event,  pay_amount,note)}
                                        disabled={loading} 
                                      >
                                        {loading ? "Submitting..." : "Submit"}
                                      </Button>
                                      </Stack>
                                      </Grid>
                                   </CardContent>     
                                </Card>

                                <Grid sx={{ mt: 3, width: '100%' }}>
                                  <Typography sx={{ fontSize: 20, mb:2 }}>Recent Uploaded</Typography>
                                    <SlipUploadTable/>
                                </Grid>
                        </CardContent>
                    </Card>
                    </div>
                
                </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
      <Footer/>
    </div>
  );
}

    
    export default SlipUpload;