import { CircularProgress, Typography, Chip, IconButton } from '@mui/material'
import React, { useState, forwardRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { DataGrid } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import DatePicker from 'react-datepicker'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
// import Icon from 'src/@core/components/icon'
import TodayIcon from '@mui/icons-material/Today';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Swal from 'sweetalert2'
import ViewPost from './ViewPost'
import SearchIcon from '@mui/icons-material/Search';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PhotoIcon from '@mui/icons-material/Photo';

import "react-datepicker/dist/react-datepicker.css";
import EditPost from './EditPost'

const CustomInput = forwardRef(({ ...props }, ref) => {
  return (
    <TextField
      size='small'
      inputRef={ref}
      {...props}
      sx={{ width: '100%', '& .MuiInputBase-input': { color: 'text.secondary' } }}
    />
  )
})

const YourPost = () => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [tabledata, setTableData] = useState([])
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 })
  const [status, setStatus] = useState(0)
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchIcon, setSearchIcon] = useState(false)
  const [total, setTotal] = useState(0)
  const [postObj, setPostObj] = useState(null)

  let adminId = ''
  if (typeof localStorage !== 'undefined') {
    adminId = window.localStorage.getItem('studentID')
  }

  const columns = [
    {
      flex: 0.15,
      minWidth: 120,
      field: 'id',
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <div>
          <Typography variant='subtitle1'>
            {row.fro_id}
          </Typography>
          <Typography variant='subtitle2'>
            {row.date_time ? new Date(row.date_time).toLocaleDateString('en-CA') : ''}
          </Typography>
        </div>
      )
    },
    // {
    //   flex: 0.15,
    //   minWidth: 200,
    //   field: 'image',
    //   headerName: 'image',
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: ({ row }) => (
    //     <Box>
    //       {row.img.length > 0 ? (
    //         <PhotoIcon color="primary" />
    //       ) : (
    //         <Typography variant='subtitle2'>No Image</Typography>
    //       )}
    //     </Box>
    //   )
    // },

    {
      flex: 0.15,
      minWidth: 250,
      headerName: 'DESCRIPTION',
      headerAlign: 'center',
      align: 'center',
      field: 'description',
      renderCell: ({ row }) => (
        <Box sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
        >
          {truncateText(row.text, 30)}
        </Box>
      )
    },
    {
      flex: 0.15,
      minWidth: 60,
      field: 'like',
      headerName: 'like',
      renderCell: ({ row }) => (
        <Box>
          <Typography>{row?.det_like}</Typography>
        </Box>
      )
    },
    {
      flex: 0.15,
      minWidth: 80,
      field: 'comment',
      headerName: 'comment',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <Box>
          <Typography>{row?.comments}</Typography>
        </Box>
      )
    },
    {
      flex: 0.2,
      minWidth: 90,
      headerName: 'STATUS',
      field: 'status',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <Chip label={getStatus(row.admin_status)} size='small' color={getStatusColor(row.admin_status)} />
      )
    },
    {
      flex: 0.55,
      minWidth: 150,
      field: 'actions',
      headerAlign: 'center',
      align: 'center',
      headerName: 'ACTION',
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 1 }}>
          <Button
            variant='contained'
            sx={{ fontSize: '10px', backgroundColor: '#007BFF', ':hover': { backgroundColor: '#007BFF' } }}
            size='small'
            onClick={() => handleClickOpen(row)}
          >
            VIEW
          </Button>
          <Button
            variant='contained'
            sx={{ fontSize: '10px' }}
            color="warning"
            size='small'
            onClick={() => handleClickEdit(row)}
          >
            Edit
          </Button>
        </Box>
      )
    }
  ]

  const getRowId = row => row.fro_id

  const theme = useTheme()

  useEffect(() => {
    fetchAllClass()
  }, [paginationModel.page, paginationModel.pageSize])

  const fetchAllClass = async () => {
    setIsLoading(true)

    if (startDate || endDate || status) {
      setSearchIcon(true)
    }

    const sstartDate =
      startDate instanceof Date
        ? `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(
          startDate.getDate()
        ).padStart(2, '0')}`
        : null

    const eendDate =
      endDate instanceof Date
        ? `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(
          endDate.getDate()
        ).padStart(2, '0')}`
        : null

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/your-post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          id: adminId,
          startDate: sstartDate,
          endDate: eendDate,
          status: status
        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setTableData(data.result)
        setIsLoading(false)
        setTotal(data.totalRecords)
      } else {
        setTableData([])
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const ResetAllClass = async () => {
    setIsLoading(true)

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/your-post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          id: adminId,
          startDate: '',
          endDate: '',
          status: ''
        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setTableData(data.result)
        setIsLoading(false)
      } else {
        setTableData([])
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    ResetAllClass()
    setSearchIcon(false)
    setStatus('')
    setPaginationModel({ page: 0, pageSize: 10 })
  }

  const getStatusColor = status => {
    return status == '0' ? 'warning' : status == '1' ? 'success' : status == '2' ? 'error' : null
  }

  const getStatus = status => {
    return status == '0' ? 'Pending' : status == '1' ? 'Approved' : status == '2' ? 'Reject' : null
  }

  const handleClickOpen = row => {
    setOpen(true)
    setPostObj(row)
  }

  const handleClickEdit = row => {
    setEditOpen(true)
    setPostObj(row)
  }

  const handleChangeAdminStatus = e => {
    setStatus(e.target.value)
  }

  const cleanHtml = (text) => {

    // Remove style attributes
    const noStyleText = text.replace(/style="[^"]*"/g, "");

    // Remove HTML tags
    const plainText = noStyleText.replace(/<\/?[^>]+(>|$)/g, "");

    return plainText;
  };

  const truncateText = (text, length) => {
    // Clean HTML content and limit the text length
    const cleanText = cleanHtml(text);

    return cleanText.length > length ? `${cleanText.substring(0, length)}...` : cleanText;
  };

  return (
    <div>


      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>

              <DatePicker
                id='basic-input'
                selected={startDate}
                customInput={<CustomInput label='From' />}
                onChange={date => setStartDate(date)}
                wrapperClassName='full-width-datepicker'
              />

            </Grid>
            <Grid item md={4} sm={6} xs={12}>

              <DatePicker
                id='picker-clear'
                selected={endDate}
                customInput={<CustomInput label='To' />}
                onChange={date => setEndDate(date)}
                wrapperClassName='full-width-datepicker'
              />

            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl size='small' sx={{ width: '100%' }}>
                <InputLabel id='controlled-select-label'>Status</InputLabel>
                <Select
                  value={status}
                  label='Status'
                  id='controlled-select'
                  onChange={handleChangeAdminStatus}
                  labelId='controlled-select-label'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='0'>Pending</MenuItem>
                  <MenuItem value={1}>Approved</MenuItem>
                  <MenuItem value={2}>Reject</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <Button
                  variant='contained'
                  sx={{}}
                  fullWidth
                  endIcon={<SettingsBackupRestoreIcon />}
                  onClick={handleReset}
                  color='error'
                >
                  RESET
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  endIcon={<SearchIcon />}
                  onClick={fetchAllClass}
                  fullWidth
                >
                  SEARCH
                </Button>
              </Box>
            </Grid>
          </Grid>

          {searchIcon && (
            <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
              <div>Filter :</div>

              {startDate && <Chip size='small' label={startDate.toLocaleString()} color='primary' />}
              {endDate && <Chip size='small' label={endDate.toLocaleString()} color='primary' />}
              {status && <Chip size='small' label={status} color='primary' />}
            </Box>
          )}
        </CardContent>

        <Divider sx={{ my: 0.5 }} />

        <Box sx={{ height: '60vh', width: '100%' }}>
          {isLoading ? (
            <Box sx={{ mt: 6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <CircularProgress sx={{ mb: 4 }} />
            </Box>
          ) : (
            <>
              {tabledata && (
                <DataGrid
                  rowHeight={60}
                  pagination
                  rows={tabledata}
                  columns={columns}
                  getRowId={getRowId}
                  rowCount={total}
                  paginationMode='server'
                  disableRowSelectionOnClick
                  pageSizeOptions={[10, 25, 50]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </>
          )}
        </Box>
      </Card>
      <ViewPost setOpen={setOpen} open={open} postObj={postObj} fetchAllClass={fetchAllClass} />
      <EditPost setEditOpen={setEditOpen} editOpen={editOpen} postObj={postObj} fetchAllClass={fetchAllClass} />
    </div>
  )
}

export default YourPost
