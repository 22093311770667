import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import CircularIndeterminate from '../components/loading';
import { Box } from '@mui/material';
import useLogout from '../hooks/logouthook';
import { useNavigate } from 'react-router-dom';




const columns = [
    { id: 'pay_id', label: 'Top-Up ID', minWidth: 100 ,align: 'left'},
    { id: 'voucherDate', label: 'Date-Time', minWidth: 100,align: 'left' },
    { id: 'pay_method', label: 'Method', minWidth: 100, align: 'center'},
    { id: 'pay_amount', label: 'Amount', minWidth: 100, align: 'right',format: (value) => ` ${value.toFixed(2)}`},
  ];

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function TopUpHistoryTable() {

    const [payHistoryData, setPayHistoryData] = useState([]);
    const studentID = localStorage.getItem('studentID');
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 25;
    const [loading, setLoading] = React.useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const navigate = useNavigate();
    const {handleLogout} = useLogout()

    let token = ''
    if (typeof localStorage !== 'undefined') {
      token = window.localStorage.getItem('token')
    }
  
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  

    useEffect(() => {
          fetchPayHistoryData();
      }, [studentID, page, rowsPerPage]);
      
      const fetchPayHistoryData = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/topup-history`, {
            method: 'POST',
            body: JSON.stringify({ 
              studentID, 
              page: page + 1, 
              limit: rowsPerPage
             }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`

            }
          });
          if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken');
            }
        
            try {
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  refreshToken: refreshToken,
                }),
              });
        
              if (tokenResponse.status === 200) {
                const tokendata = await tokenResponse.json();
                window.localStorage.setItem('token', tokendata.accessToken); 
                token = tokendata.accessToken;
        
        
                return fetchPayHistoryData();
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error('Error refreshing token:', error);
              handleLogout(); 
            }
        
            return; 
          }else{
            const getData = await response.json();
          setPayHistoryData(getData.data);
          setTotalItems(getData.totalItems); 
          setLoading(false);
          }
      
          if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
          }
      
          
        } catch (err) {
          console.error('Failed to fetch data', err);
          setPayHistoryData([]);
          setLoading(false);
        }
      };
      
  
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 700 }}>
        {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
             <CircularIndeterminate />
           </Box>
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {payHistoryData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No rows available
                  </TableCell>
                </TableRow>
              ) : (
            payHistoryData.map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'pay_method' ? (
                            value === 'Online' ? (
                              <Chip label="Online" color="success" sx={{width:70, height:19}} />
                            ) : (
                              <Chip label="Bank" color="primary" sx={{width:70, height:19}} />
                            )
                          ) : column.id === 'voucherDate' ? (
                            formatDate(value)
                          ) : (
                            column.format && typeof value === 'number' ? column.format(value) : value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  ))
                )}
            </TableBody>
          </Table>
        )}
        </TableContainer>
        {totalItems > 25 && (
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        )}
      </Paper>
    );
  }

  

    
export default TopUpHistoryTable;