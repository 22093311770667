import React, { useState, forwardRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Avatar, Box } from '@mui/material'
import Swal from 'sweetalert2'
import TextField from '@mui/material/TextField'
import { CircularProgress, Chip, Card, CardContent, Divider, Container, ClickAwayListener } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAvatar from '@mui/material/Avatar'
import { Icon } from "@iconify/react";
import { useTheme } from '@mui/material/styles'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImagePreview from './ImagePreview'
import Grid from '@mui/material/Grid'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getInitials } from '../name'
import { useNavigate, useParams } from 'react-router-dom';
import NavbarLog from '../navbarlogged'
import Navbar from '../navbar'
import Footer from '../footer'
import RandomTeachers from '../common_forum/RandomTeachers'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SendIcon from '@mui/icons-material/Send';
import useLogout from '../../hooks/logouthook'
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";
import themeConfig from '../../config/themeConfig';

const ChatFormWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: '3px', // specify the border width
    borderColor: theme.palette.divider,
    padding: theme.spacing('5px', 2),
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
    maxWidth: '100%'
}))

const LogOnePostView = ({ }) => {
    const [post, setPost] = useState(null)
    const [imageUrl, setImageUrl] = useState([])
    const [imagePreview, setImagePreview] = useState(false)
    const [likesArray, setLikesArray] = useState([])
    const [subComments, setSubComments] = useState([])
    const [isLoadingComments, setIsLoadingComments] = useState(false)
    const [subPage, setSubPage] = useState(1)
    const [message, setMessage] = useState('')
    const [totalComments, setTotalComments] = useState(0)
    const [randomTeacherP, setRandomTeacherP] = useState([])
    const [userProfile, setUserProfile] = useState('')
    const [banner, setBanner] = useState([])
    const [openDropdown, setOpenDropdown] = useState(null);
    const [editIndex, setEditIndex] = useState(null); // Track the index of the comment being edited
    const [editedComment, setEditedComment] = useState('');
    const [commentID, setCommentID] = useState('')
    let [userID, setUserID] = useState('');

    const { handleLogout } = useLogout();
    const { id } = useParams();
    const navigate = useNavigate();

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    useEffect(() => {
        fetchStu_Data()
        fetchRandomTeacher()
        fetchLikes()
        fetchSubMessage(id)
        fetchonePost(id)
        fetchBanner()
    }, [id])

    useEffect(() => {
        if (subPage > 1) {
            fetchSubMessage(id);
        }
    }, [subPage]);


    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setUserID(decoded.user_id)

        } else {
            console.error('Token is invalid or not found.');
        }

    }, [token])



    const handleImagePreview = img => {
        setImagePreview(true)
        setImageUrl(img)
    }

    const handleKeyPress = event => {
        // Check if the pressed key is Enter (key code 13)
        if (event.key === 'Enter') {
            event.preventDefault()
            if (message) {
                handleSendSMS(id)
            }
        }
    }

    const fetchonePost = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/one-post`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: id
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                    refreshToken = window.localStorage.getItem('refreshToken')
                }

                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            refreshToken: refreshToken
                        })
                    })
                    if (tokenResponse.status == 200) {
                        const tokendata = await tokenResponse.json()
                        window.localStorage.setItem('token', tokendata.accessToken)
                        token = tokendata.accessToken
                        fetchonePost()
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.error(error)
                }
            }

            if (response.status == 200) {
                const data = await response.json()
                setPost(data.result)
            } else {
                setPost(null)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchLikes = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/like`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: adminId
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                    refreshToken = window.localStorage.getItem('refreshToken')
                }

                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            refreshToken: refreshToken
                        })
                    })
                    if (tokenResponse.status == 200) {
                        const tokendata = await tokenResponse.json()
                        window.localStorage.setItem('token', tokendata.accessToken)
                        token = tokendata.accessToken
                        fetchLikes()
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.error(error)
                }
            } else if (response.status == 200) {
                const data = await response.json()
                setLikesArray(data.result)
            } else {
                setLikesArray([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchSubMessage = async (id) => {
        setIsLoadingComments(true)
        console.log(subPage)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/sub-comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    post_id: id,
                    page: subPage
                })
            })
            if (response.status == 200) {
                const data = await response.json()
                setSubComments(prevComments => [...prevComments, ...data.result])
                setIsLoadingComments(false)
                setTotalComments(data.totalRecords)
            } else {
                setIsLoadingComments(false)
                setSubComments(prevComments => [...prevComments])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleCommentPage = () => {
        setSubPage(subPage + 1)
    }

    const handleLike = async post => {
        const index = likesArray.findIndex(item => item.fro_id === post.fro_id)

        if (index !== -1) {

            // Update the post ensuring you spread its current state properly
            setPost(prevPostLike => ({
                ...prevPostLike,
                det_like: prevPostLike.det_like - 1
            }))

            // Remove the object if it exists
            setLikesArray(prevLikes => prevLikes.filter(item => item.fro_id !== post.fro_id))
        } else {
            // Update anotherState when the item is not found
            setPost(prevPostLike => ({
                ...prevPostLike,
                det_like: prevPostLike.det_like + 1
            }))

            // Add the object if it does not exist
            setLikesArray(prevLikes => [...prevLikes, { fro_id: post.fro_id }])
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/addLike`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: post.fro_id,
                    t_id: adminId
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                    refreshToken = window.localStorage.getItem('refreshToken')
                }

                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            refreshToken: refreshToken
                        })
                    })
                    if (tokenResponse.status == 200) {
                        const tokendata = await tokenResponse.json()
                        window.localStorage.setItem('token', tokendata.accessToken)
                        token = tokendata.accessToken
                        handleLike(post)
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleSendSMS = async id => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/add-comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: id,
                    t_id: adminId,
                    message: message
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                    refreshToken = window.localStorage.getItem('refreshToken')
                }

                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            refreshToken: refreshToken
                        })
                    })
                    if (tokenResponse.status == 200) {
                        const tokendata = await tokenResponse.json()
                        window.localStorage.setItem('token', tokendata.accessToken)
                        token = tokendata.accessToken
                        handleSendSMS(id)
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.error(error)
                }
            } else if (response.status == 200) {
                setMessage('')
                const data = await response.json()

                // Update the post ensuring you spread its current state properly
                setPost(prevPostComment => ({
                    ...prevPostComment,
                    comments: prevPostComment.comments + 1
                }))

                setTotalComments(totalComments + 1)

                setSubComments(preData => {
                    const updatedData = [...preData, data.result]

                    const sortData = updatedData.sort((a, b) => b.d_id - a.d_id)

                    return sortData
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchRandomTeacher = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/random-teacher`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            })

            if (response.status == 200) {
                const data = await response.json()
                setRandomTeacherP(data.results)
            } else {
                setRandomTeacherP([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchStu_Data = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
                method: 'POST',
                body: JSON.stringify({
                    studentID: adminId

                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                    refreshToken = window.localStorage.getItem('refreshToken')
                }

                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            refreshToken: refreshToken
                        })
                    })
                    if (tokenResponse.status == 200) {
                        const tokendata = await tokenResponse.json()
                        window.localStorage.setItem('token', tokendata.accessToken)
                        token = tokendata.accessToken
                        fetchStu_Data()
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.error(error)
                }
            }

            if (!response.ok) {
                throw new Error(`HTTP error: Status ${response.status}`);
            }

            const getData = await response.json();

            setUserProfile(getData?.student?.profile || '');
        } catch (err) {
            console.log(err)
        }
    };

    const fetchBanner = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/banner`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            })

            if (response.status == 200) {
                const data = await response.json()
                setBanner(data.result)
            } else {
                setBanner([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleOpenDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index); // Toggle the dropdown
    };

    const handleCloseDropdown = () => {
        setOpenDropdown(null); // Close the dropdown
    };

    const handleDelete = async (id) => {
        setOpenDropdown(null);
        Swal.fire({
            title: `Delete Comment`,
            text: 'Are you sure you want to Delete the comment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true,
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/comment-delete`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            id: id,
                        })
                    })

                    if (response.status == 403 || response.status == 401) {

                        let refreshToken = ''
                        if (typeof localStorage !== 'undefined') {
                            refreshToken = window.localStorage.getItem('refreshToken')
                        }

                        try {
                            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    refreshToken: refreshToken
                                })
                            })
                            if (tokenResponse.status == 200) {
                                const tokendata = await tokenResponse.json()
                                window.localStorage.setItem('token', tokendata.accessToken)
                                token = tokendata.accessToken
                                handleDelete(id)
                            } else {
                                handleLogout();
                            }
                        } catch (error) {
                            console.error(error)
                        }
                    } else if (response.status == 200) {
                        Swal.fire({
                            title: 'Confirmed!',
                            icon: 'success',
                            confirmButtonColor: '#3085d6',
                            customClass: {
                                container: 'custom-swal-container' // Add a custom class to the container
                            }
                        })
                        setSubComments((prevState) =>
                            prevState.filter((item) => item.d_id !== id)
                        )
                        setTotalComments(totalComments - 1)
                    }
                } catch (error) {
                    console.error(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })

    };

    const handleEditClick = (index, comment) => {
        setEditIndex(index); // Set the comment to be edited
        setEditedComment(comment.comment);
        setCommentID(comment.d_id)
        setOpenDropdown(null); // Close the dropdown
    };

    const cancelComment = () => {
        setEditIndex(null); // Set the comment to be edited
        setEditedComment('');
        setCommentID('')
    }

    const handlSave = async (index) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/comment-edit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: commentID,
                    comment: editedComment
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                    refreshToken = window.localStorage.getItem('refreshToken')
                }

                try {
                    const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            refreshToken: refreshToken
                        })
                    })
                    if (tokenResponse.status == 200) {
                        const tokendata = await tokenResponse.json()
                        window.localStorage.setItem('token', tokendata.accessToken)
                        token = tokendata.accessToken
                        handlSave()
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.error(error)
                }
            } else if (response.status == 200) {

                const updatedComments = subComments.map((item, idx) =>
                    idx === index ? { ...item, comment: editedComment } : item
                );
                setSubComments(updatedComments); // Update state with edited comment
                setEditIndex(null);
            }
        } catch (error) {
            console.log(error)
        }
    }


    const cleanTextForUrl = (text) => {
        // Strip HTML tags

        const stripHtmlTags = (html) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            return tempDiv.innerText || tempDiv.textContent || '';
        };

        // Get plain text without HTML tags
        const plainText = stripHtmlTags(text);

        // Encode special characters and replace spaces with hyphens
        return plainText
    };

    return (
        <>

            <Helmet>
                <title>{post?.title ? post?.title : 'Community'} - {themeConfig.templateName}</title>
                <meta
                    name="description"
                    content={post?.text.length > 9 ? cleanTextForUrl(post?.text) : "This is the community page of your site."}
                    key={post?.text.length > 9 ? cleanTextForUrl(post?.text) : "defaultDescription"}
                />
            </Helmet>

            {localStorage.getItem('studentID') ? <NavbarLog /> : <Navbar />}

            <Container maxWidth="lg" sx={{ mb: 15, mt: 13 }}  >

                <Box sx={{ display: 'flex', gap: 5 }}>

                    <Box sx={{ flexGrow: 1 }}>

                        <Card sx={{ boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px' }}>

                            <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box>
                                        {post?.type == 'student' ? (
                                            <>
                                                {post?.additionalInfo?.profile ? (
                                                    <MuiAvatar
                                                        src={post?.additionalInfo?.profile}
                                                        alt={`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`}
                                                        sx={{ width: 40, height: 40 }}
                                                    />
                                                ) : (
                                                    <MuiAvatar
                                                        skin='light'
                                                        sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                                    >
                                                        {getInitials(`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`)}
                                                    </MuiAvatar>
                                                )}
                                            </>
                                        ) : post?.type == 'teacher' ? (
                                            <>
                                                {post?.additionalInfo?.profile ? (
                                                    <MuiAvatar
                                                        src={post?.additionalInfo?.profile}
                                                        alt={`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`}
                                                        sx={{ width: 40, height: 40 }}
                                                    />
                                                ) : (
                                                    <MuiAvatar
                                                        skin='light'
                                                        sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                                    >
                                                        {getInitials(`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`)}
                                                    </MuiAvatar>
                                                )}
                                            </>
                                        ) : (
                                            <MuiAvatar src='/images/et_logo.png' alt='admin' sx={{ width: 40, height: 40 }} />
                                        )}
                                    </Box>
                                    <Box>
                                        {post?.type == 'student' && (
                                            <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>
                                                {post.additionalInfo ? post.additionalInfo?.stu_fname : ''}{' '}
                                                {post.additionalInfo ? post.additionalInfo?.stu_lname : ''}
                                            </Typography>
                                        )}
                                        {post?.type == 'teacher' && (
                                            <>
                                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>
                                                    {post.additionalInfo ? post.additionalInfo?.t_fname : ''}{' '}
                                                    {post.additionalInfo ? post.additionalInfo?.t_lname : ''}
                                                </Typography>
                                                {post.type == 'teacher' && (
                                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize', color: '#4c4e6499' }}>Teacher</Typography>
                                                )}
                                            </>
                                        )}
                                        {post?.type == 'admin' && (
                                            <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>
                                                EnglishTeacher.lk
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>

                                {post?.title && post.type == 'student' ? (
                                     <Typography sx={{ fontSize: '30px', fontWeight: 600, }} >
                                     <Icon icon="clarity:help-solid-badged" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />

                                         {post?.additionalInfo?.stu_fname} {post?.title}
                                     </Typography>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }} >
                                    <Typography sx={{ fontSize: '30px', fontWeight: 600, pt: 2 }}>{post?.title}</Typography>
                                       
                                    </Box>
                                )}

                                <Divider sx={{ my: 1 }} />

                                {post?.text.length > 9 && (
                                    <Box
                                        className="post-content"
                                        dangerouslySetInnerHTML={{ __html: post.text }}
                                        sx={{
                                            img: {
                                                maxWidth: '100%', // Prevent image overflow
                                                height: 'auto',   // Maintain aspect ratio
                                            },
                                            iframe: {
                                                maxWidth: '100%', // Make the video responsive
                                                height: 'auto', // Maintain the aspect ratio
                                                minHeight: '300px', // Set a minimum height for better appearance
                                            }
                                        }}
                                    ></Box>
                                )}

                                {post?.img.length == 1 ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            paddingBottom: '56.25%',
                                            mt: 1,
                                            borderRadius: 0.5,
                                            backgroundColor: '#dbdbdb',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleImagePreview(post?.img)}
                                    >
                                        <img
                                            src={post?.img[0]}
                                            alt='post'
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: 1
                                            }} // Ensure image fills the container
                                        />
                                    </Box>
                                ) : post?.img.length > 1 ? (
                                    <Box sx={{ width: '100%', mt: 1 }}>
                                        <Grid container rowSpacing={1} columnSpacing={2}>
                                            {post?.img.map((item, index) => (
                                                <Grid item xs={12} sm={6} key={index} onClick={() => handleImagePreview(post?.img)}>
                                                    <img
                                                        src={item}
                                                        alt={index}
                                                        loading='lazy'
                                                        style={{ objectFit: 'cover', width: '100%', height: 200, cursor: 'pointer' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                ) : null}

                                {post?.url2 && (
                                    <Box
                                        className='youtube-container'
                                        sx={{
                                            position: 'relative',
                                            paddingBottom: '56.25%',
                                            height: 0,
                                            overflow: 'hidden',
                                            maxWidth: '100%',
                                            background: '#000',
                                            mt: 1
                                        }}
                                    >
                                        <iframe
                                            title='YouTube video'
                                            src={post.url2}
                                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                            allowFullScreen
                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                        />
                                    </Box>
                                )}

                                <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1, mt: 1.5 }}>
                                    <Chip icon={<ChatBubbleOutlineOutlinedIcon />} label={totalComments} />
                                    <Chip
                                        icon={likesArray.some(item => item.fro_id === post?.fro_id) ? <FavoriteIcon color='error' /> : <FavoriteBorderOutlinedIcon />}
                                        label={post?.det_like}
                                        onClick={() => handleLike(post)}
                                    />
                                </Box>
                            </CardContent>

                            <CardContent>
                                <Typography variant='h6'>
                                    Comments - {totalComments}
                                </Typography>
                            </CardContent>

                            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <Avatar
                                    alt='John Doe'
                                    sx={{ width: 40, height: 40 }}
                                    src={userProfile ? userProfile : '/images/avatars/1.png'}
                                />
                                <ChatFormWrapper>
                                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            autoFocus
                                            id='comment-section'
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            value={message}
                                            size='small'
                                            placeholder='Type your comment here…'
                                            onChange={e => setMessage(e.target.value)}
                                            sx={{ '& .MuiOutlinedInput-input': { pl: 0 }, '& fieldset': { border: '0 !important' } }}
                                            onKeyDown={handleKeyPress}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton aria-label='sendsms' onClick={() => handleSendSMS(post?.fro_id)} disabled={!message}>
                                            <SendIcon />
                                        </IconButton>
                                    </Box>
                                </ChatFormWrapper>
                            </CardContent>

                            {subComments.length > 0 && (
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                    {subComments.map((comments, index) => (
                                        <Box key={index}>
                                            {comments.comment && (
                                                <Box sx={{ px: '20px' }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <Box>
                                                                {comments?.type == 'student' ? (
                                                                    <>
                                                                        {comments?.userDetails?.profile ? (
                                                                            <MuiAvatar
                                                                                src={comments?.userDetails?.profile}
                                                                                alt={`${comments.userDetails?.stu_fname} ${comments.userDetails?.stu_lname}`}
                                                                                sx={{ width: 36, height: 36 }}
                                                                            />
                                                                        ) : (
                                                                            <MuiAvatar
                                                                                skin='light'
                                                                                sx={{ width: 36, height: 36, fontSize: '1rem', textTransform: 'uppercase' }}
                                                                            >
                                                                                {getInitials(
                                                                                    `${comments.userDetails?.stu_fname} ${comments.userDetails?.stu_lname}`
                                                                                )}
                                                                            </MuiAvatar>
                                                                        )}
                                                                    </>
                                                                ) : comments?.type == 'teacher' ? (
                                                                    <>
                                                                        {comments?.userDetails?.profile ? (
                                                                            <MuiAvatar
                                                                                src={comments?.userDetails?.profile}
                                                                                alt={`${comments.userDetails?.t_fname} ${comments.userDetails?.t_lname}`}
                                                                                sx={{ width: 36, height: 36 }}
                                                                            />
                                                                        ) : (
                                                                            <MuiAvatar
                                                                                skin='light'
                                                                                sx={{ width: 36, height: 36, fontSize: '1rem', textTransform: 'uppercase' }}
                                                                            >
                                                                                {getInitials(`${comments.userDetails?.t_fname} ${comments.userDetails?.t_lname}`)}
                                                                            </MuiAvatar>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <MuiAvatar src='/images/et_logo.png' alt='admin' sx={{ width: 36, height: 36 }} />
                                                                )}
                                                            </Box>
                                                            <Box>
                                                                {comments?.type == 'student' && (
                                                                    <Typography sx={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>
                                                                        {comments.userDetails ? comments.userDetails?.stu_fname : ''}{' '}
                                                                        {comments.userDetails ? comments.userDetails?.stu_lname : ''}
                                                                    </Typography>
                                                                )}
                                                                {comments?.type == 'teacher' && (
                                                                    <>
                                                                        <Typography sx={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>
                                                                            {comments.userDetails ? comments.userDetails?.t_fname : ''}{' '}
                                                                            {comments.userDetails ? comments.userDetails?.t_lname : ''}
                                                                        </Typography>
                                                                        {comments.type == 'teacher' && (
                                                                            <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize', color: '#4c4e6499' }}>Teacher</Typography>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {comments?.type == 'admin' && (
                                                                    <Typography sx={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>
                                                                        EnglishTeacher.lk
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Box>

                                                        {comments.type == 'student' && comments.s_id == userID && (
                                                            <Box sx={{ position: 'relative' }}>
                                                                <IconButton
                                                                    aria-label='close'
                                                                    onClick={() => handleOpenDropdown(index)}
                                                                    sx={{ p: 0 }}
                                                                >
                                                                    <Icon icon='pepicons-pop:dots-x' />
                                                                </IconButton>
                                                                {openDropdown === index && (
                                                                    <ClickAwayListener onClickAway={handleCloseDropdown}>
                                                                        <Box
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: 30,
                                                                                right: 0,
                                                                                bgcolor: 'background.paper',
                                                                                boxShadow: 5,
                                                                                borderRadius: 1,
                                                                                p: 1,
                                                                                minWidth: 100,
                                                                                zIndex: 1
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                onClick={() => handleEditClick(index, comments)}
                                                                                sx={{ p: 1, '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' } }}
                                                                            >
                                                                                <Typography variant="body2">Edit</Typography>
                                                                            </Box>
                                                                            <Box
                                                                                onClick={() => handleDelete(comments.d_id)}
                                                                                sx={{ p: 1, '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' } }}
                                                                            >
                                                                                <Typography variant="body2">Delete</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </ClickAwayListener>
                                                                )}
                                                            </Box>
                                                        )}

                                                    </Box>

                                                    {editIndex === index ? (
                                                        <Box sx={{ mt: 1 }}>
                                                            <TextField
                                                                fullWidth
                                                                value={editedComment}
                                                                onChange={(e) => setEditedComment(e.target.value)}
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{}}
                                                            />
                                                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                                <Button
                                                                    onClick={() => handlSave(index)}
                                                                    sx={{ px: 4 }}
                                                                    variant="contained"
                                                                    size="small"
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    onClick={() => cancelComment(index)}
                                                                    sx={{}}
                                                                    variant="outlined"
                                                                    size="small"
                                                                >
                                                                    cancel
                                                                </Button>
                                                            </Box>
                                                        </Box>

                                                    ) : (
                                                        <Typography sx={{ mt: 1, fontSize: 16, fontWeight: 500, ml: 5 }}>{comments.comment}</Typography>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                </CardContent>
                            )}

                        </Card>

                        {isLoadingComments && (
                            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <CircularProgress sx={{ mb: 4 }} />
                            </Box>
                        )}

                        {totalComments > subComments.length && (
                            <Button
                                variant='contained'
                                size='small'
                                sx={{ mt: 1 }}
                                startIcon={<KeyboardArrowDownIcon />}
                                onClick={() => handleCommentPage()}
                            >
                                view more comments
                            </Button>
                        )}
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: '',
                            width: '300px',
                            display: { xs: 'none', md: 'block' }
                        }}
                    >
                        {post?.timeslot ? (
                            <Box sx={{
                                maxWidth: '300px',
                                width: '300px', mb: 1
                            }}>
                                <RandomTeachers teacher={post?.additionalInfo} />
                            </Box>
                        ) : (
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '300px',
                                width: '300px', mb: 1
                            }}>
                                {randomTeacherP.map((data, index) => (
                                    <RandomTeachers teacher={data} key={index} />
                                ))}
                            </Box>
                        )}

                        <Box sx={{
                            maxWidth: '300px',
                            width: '300px', mb: 1,
                            position: 'sticky',
                            top: 80
                        }}>
                            {banner.map((data, index) => (
                                <img
                                    key={index}
                                    src={data.url}
                                    alt='post'
                                    style={{ width: '100%', height: 'auto', objectFit: 'contain', }} // Ensure image fills the container
                                />
                            ))}
                        </Box>

                    </Box>

                </Box>


            </Container>

            <Footer />
            <ImagePreview imagePreview={imagePreview} setImagePreview={setImagePreview} imgUrls={imageUrl} />

        </>
    )
}

export default LogOnePostView
