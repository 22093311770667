import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import CircularIndeterminate from '../components/loading';
import { useNavigate } from 'react-router-dom';
import useLogout from '../hooks/logouthook';


const columns = [
    { id: 'pay_id', label: 'Slip ID', minWidth: 100,align: 'left' },
    { id: 'voucherDate', label: 'Date-Time', minWidth: 100,align: 'left'},
    { id: 'pay_Status', label: 'Status', minWidth: 100, align: 'center'},
    { id: 'pay_amount', label: 'Amount', minWidth: 100, align: 'right', format: (value) => ` ${value.toFixed(2)}`},
    {id: 'download',label: 'Download',minWidth: 100,align: 'center'},
  ];

  
  

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }


  function SlipUploadTable() {


    const [payData, setPayData] = useState([]);
    const studentID = localStorage.getItem('studentID');
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 25;
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    const {handleLogout} = useLogout()

    let token = ''
    if (typeof localStorage !== 'undefined') {
      token = window.localStorage.getItem('token')
    }
  
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleDownload = (row) => {
      const url = row.URL || row.payData?.URL; // Adjust this based on structure
      
      if (url) {
        window.open(url, '_blank');
      } else {
        console.error("URL not found in row:", row);
      }
    };
    
    useEffect(() => {
          fetchPayData();
      }, [studentID, page, rowsPerPage]);
      
      const fetchPayData = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/slip_uploadtable`, {
            method: 'POST',
            body: JSON.stringify({ 
              studentID, 
              page: page + 1, 
              limit: rowsPerPage  }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken');
            }
        
            try {
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  refreshToken: refreshToken,
                }),
              });
        
              if (tokenResponse.status === 200) {
                const tokendata = await tokenResponse.json();
                window.localStorage.setItem('token', tokendata.accessToken); 
                token = tokendata.accessToken;
        
        
                return fetchPayData();
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error('Error refreshing token:', error);
              handleLogout(); 
            }
        
            return; 
          }
      
          if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
          }
      
          const getData = await response.json();
          setPayData(getData.data);
          setTotalItems(getData.totalItems); 
          setLoading(false);
          
        } catch (err) {
          console.error('Failed to fetch data', err);
          setPayData([]);
          setLoading(false);
        }
      };

      
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
        {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
             <CircularIndeterminate />
           </Box>
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                {payData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No rows available
                  </TableCell>
                </TableRow>
              ) : (
                payData.map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'pay_Status' ? (
                              row['pay_Status'] === 0 ? (
                                <Chip label="Pending" sx={{ backgroundColor: 'orange', color: 'white', width: 80, height: 19 }} />
                              ) : row['pay_Status'] === 1 ? (
                                <Chip label="Paid" color="success" sx={{ width: 80, height: 19 }} />
                              ) : row['pay_Status'] === 2 ? (
                                <Chip label="Rejected" sx={{ backgroundColor: 'red', color: 'white', width: 80, height: 19 }} />
                              ) : null
                            ) : column.id === 'download' ? (
                              <Button
                              variant="contained"
                              onClick={() => handleDownload(row)}
                              size="small"
                              sx={{
                                fontSize: { xs: 7.5, sm: 10 },  
                                padding: { xs: '2px 6px' },  
                                background: '#00669e',
                                minWidth: { xs: '80px', sm: 'auto' }, 
                              }}
                            >
                              Download slip
                            </Button>
                            ) : column.id === 'voucherDate' ? (
                              formatDate(value)
                            ) : (
                              column.format && typeof value === 'number' ? column.format(value) : value
                            )}
                          </TableCell>
                               );
                          })}
                    </TableRow>
                  ))
                )}
            </TableBody>
          </Table>
        )}
        </TableContainer>
        {totalItems > 25 && (
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        )}
      </Paper>
    );
  }

  function handleDownload(row) {
    console.log('Downloading', row);
}
   
export default SlipUploadTable;