import React from 'react';
import {
  Container,
  Card,
  CardContent,
  CardHeader, 
  Grid, 
  Avatar, 
  Typography, 

} from '@mui/material';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
import CompletedClassForm from '../components/completedform';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';
import { useLocation } from 'react-router-dom';



function CompletedClasses() {

  const [stu_Data, setStu_Data] = useState({});
  const studentID = localStorage.getItem('studentID');
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 

  return (
    <div className="home-page">
      <Helmet>
        <title>Completed Classes - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                  <Hidden smDown>
                    <Common />
                  </Hidden>

                  <Grid xs={12} md={8.8} sx={{mb:2}}>
                  <Card sx={{ mb: 1, width: "100%" }}>
                  <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Completed Classes</Typography>} sx={{ background: '#00669e', color: 'white' }} />
                    <CardContent>
                    <CompletedClassForm/>
                    </CardContent>
                  </Card>
                   
                  </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
        <Footer/>
    </div>
  );
}

    
    export default CompletedClasses;