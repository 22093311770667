// ** React Imports
import { Fragment } from "react";
import { useState } from "react";
import MuiAvatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { TextField, Avatar, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Icon } from "@iconify/react";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import MessageView from "./MessageView";

// ** Styled Components
const ChatFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: 8,
  alignItems: "center",
  boxShadow: theme.shadows[1],
  padding: '5px 16px',
  justifyContent: "space-between",
  backgroundColor: theme.palette.background.paper,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),

  marginBottom: theme.spacing(2),
}));

const ChatWrapperStartChat = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: "100%",
  display: "flex",
  borderRadius: 1,
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: theme.palette.action.hover,
}));

const MessageContent = ({
  selectedUserProfile,
  handleLeftSidebarToggle,
  allmessages,
  handleSendSMS,
  message,
  setMessage,
  tableData,
  handleChange,
  file,
  loading,
  filePath,
  removeuploadfile,
  selectedRoom,
}) => {
  const theme = useTheme();

  const smAbove = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAbove = useMediaQuery(theme.breakpoints.up("md"));
  const [error, setError] = useState("");

  const placeholderText = smAbove ? "Type your message here…" : "Type";

  const handleMessageChange = (e) => {
    const input = e.target.value;

    const phoneRegex = /^(?=.*\d{10}$)^\d{10}$/; 
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (phoneRegex.test(input) || emailRegex.test(input)) {
      setError("To protect personal privacy, please do not include contact details in your messages.");
    } else {
      setError("");
      setMessage(input);
    }
  };


  const truncateText = (text) => {
    if (text.length <= 20) {
      return text;
    }
    return text.slice(0, 20) + "...";
  };

  return (
    <div>
      <Box
        sx={{
          maxHeight: "calc(100vh - 170px)",
          minHeight: "calc(100vh - 170px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {selectedRoom > 0 && tableData.length > 0 && tableData ? (
          <>
            <Box
              sx={{
                py: 1.5,
                px: 2.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: '1px solid rgba(76, 78, 100, 0.12)',
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {mdAbove ? null : (
                  <IconButton onClick={handleLeftSidebarToggle} sx={{ mr: 2 }}>

                    <MenuIcon />
                  </IconButton>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ mr: 2.5 }}>
                    {selectedUserProfile?.profile ? (
                      <MuiAvatar
                        src={selectedUserProfile?.profile}
                        alt={`${selectedUserProfile?.t_fname} ${selectedUserProfile?.t_lname}`}
                        sx={{ width: 40, height: 40 }}
                      />
                    ) : selectedUserProfile.t_id == 0 ? (
                      <MuiAvatar
                        src='/images/et_logo.png'
                        alt='admin'
                        sx={{
                          width: 40, height: 40,

                        }}
                      />
                    ) : (
                      <Avatar
                        skin="light"
                        sx={{
                          width: 40,
                          height: 40,
                          fontSize: "1rem",
                          textTransform: "uppercase",
                        }}
                      >
                        {`Image`}
                      </Avatar>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        textTransform: "capitalize",
                      }}
                    >{selectedUserProfile?.t_id == 0 ? 'EnglishTeacher.lk' : `${selectedUserProfile?.t_fname} ${selectedUserProfile?.t_lname}`}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* {mdAbove ? (
                            <Fragment>
                                <IconButton size='small' sx={{ color: 'text.secondary' }}>
                                    <Icon icon='mdi:magnify' />
                                </IconButton>
                            </Fragment>
                        ) : null} */}
              </Box>
            </Box>

            <Box sx={{
              flexGrow: 1, overflowY: "auto", maxHeight: "100%",
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c1c1c1", // Scrollbar color
                borderRadius: "10px", // Rounded corners for the scrollbar
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#a39b9b", // Darker color on hover
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#F5F5F5", // Track color
              },
            }}>
              <MessageView allmessages={allmessages} />
            </Box>

            {file && (
              <Box
                sx={{
                  mx: 4,
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    color: filePath ? "" : "text.disabled",
                    backgroundColor: "#b1dbf1",
                    height: 30,
                  }}
                >
                  {file ? truncateText(file.name) : ""}
                </Box>
                {loading && (
                  <Box
                    sx={{
                      backgroundColor: "#b1dbf1",
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={18} color="inherit" />
                  </Box>
                )}
                <Box
                  aria-label="sendsms"
                  disabled={!filePath}
                  sx={{
                    backgroundColor: "#b1dbf1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 30,
                    cursor: "pointer",
                  }}
                  onClick={removeuploadfile}
                >
                  <Icon icon="iconamoon:close-fill" />
                </Box>
              </Box>
            )}
            {selectedUserProfile?.t_id == 0 ? null : (
              <ChatFormWrapper>
                <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={6}
                    value={message}
                    size="small"
                    placeholder={placeholderText}
                    // onChange={(e) => setMessage(e.target.value)}
                    onChange={handleMessageChange}
                    sx={{
                      "& .MuiOutlinedInput-input": { pl: 0 },
                      "& fieldset": { border: "0 !important" },
                    }}
                    error={Boolean(error)}
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    size="small"
                    component="label"
                    htmlFor="upload-img"
                    sx={{ mr: 2.75, color: "text.primary" }}
                  >
                    <AttachmentIcon fontSize="1.375rem" />

                    <input
                      hidden
                      type="file"
                      id="upload-img"
                      onChange={handleChange}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="sendsms"
                    onClick={handleSendSMS}
                    disabled={!message && !filePath}
                  >
                    <SendIcon />
                  </IconButton>
                </Box>
              </ChatFormWrapper>
            )}
            {error && (
                <Typography variant="caption" display="block" sx={{ color: 'red', textAlign:'center',mb:'16px' }}>
                  {error}
                </Typography>
              )}
          </>
        ) : (
          <ChatWrapperStartChat
            sx={{
              ...(mdAbove
                ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                : {}),
            }}
          >
            <MuiAvatar
              src='/images/et_logo.png'
              alt='admin'
              sx={{
                mb: 5,

                width: 110,
                height: 110,
                boxShadow: 3,
                '& svg': { color: 'action.active' },
                backgroundColor: 'background.paper'

              }}
            >
              {/* <Icon icon='mdi:message-outline' fontSize='3.125rem' /> */}
            </MuiAvatar>
            <Box
              sx={{
                px: 3,
                py: 1.2,
                boxShadow: 3,
                borderRadius: 5,
                backgroundColor: "background.paper",
                cursor: mdAbove ? "default" : "pointer",
              }}
              onClick={handleLeftSidebarToggle}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  cursor: mdAbove ? "default" : "pointer",
                }}
              >
                Stay connected with your teachers
              </Typography>
            </Box>
          </ChatWrapperStartChat>
        )}
      </Box>
    </div>
  );
};

export default MessageContent;
