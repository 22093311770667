// ** React Imports
import { useState, useEffect } from 'react'

// ** MUI Components
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Swal from 'sweetalert2'
import { tryEach } from 'async'


const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
})

const defaultValues = {
    password: '',
    email: ''
}

const LoginPage = () => {

    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const {
        control,
        setError,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema)
    })

    const onSubmit = async data => {
        const { email, password } = data
        console.log(email, password)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/email/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });
            const data = await response.json();
            if (response.status === 200) {
                window.sessionStorage.setItem('studentID', data.studentID)
                window.sessionStorage.setItem('token', data.accessToken)
                window.localStorage.setItem('refreshToken', data.refreshToken)
                navigate("/dashboard");
            } else{
                setError('password', {
                    type: 'manual',
                    message: data.message
                  })
            }
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <>
            {/* <Box sx={{ mb: 10 }}>

                <Typography sx={{ color: '', fontWeight: 700, fontSize: 40, textShadow: 10}}>
                    Teacher Login
                </Typography>
            </Box> */}
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <Controller
                        name='email'
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                                autoFocus
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                error={Boolean(errors.email)}
                                placeholder='Email'
                                size='small'
                                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                                InputProps={{
                                    style: {
                                        color: '#4c4e64de',
                                        '&::placeholder': {
                                            color: '#4c4e64de',
                                        },
                                    },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderColor: 'white',
                                        backgroundColor: 'white'
                                    },
                                }}
                            />
                        )}
                    />
                    {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <Controller
                        name='password'
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                id='auth-login-v2-password'
                                error={Boolean(errors.password)}
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Password'
                                size='small'
                                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                edge='end'
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        color: '#4c4e64de',
                                        '&::placeholder': {
                                            color: '#4c4e64de',
                                        },
                                    },
                                }}
                                sx={{

                                }}
                            />
                        )}
                    />
                    {errors.password && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                            {errors.password.message}
                        </FormHelperText>
                    )}
                </FormControl>

                <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 1, backgroundColor: '#5BB4E4', ":hover": { backgroundColor: '#5BB4E4' } }}>
                    Login
                </Button>
            </form>
        </>

    )
}


export default LoginPage
