import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ** import MUI
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Swal from 'sweetalert2'
import { Modal, Container } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Icon } from '@iconify/react/dist/iconify.js'
import useLogout from '../../hooks/logouthook'




const AddTickets = ({ addTicketOpen, setAddTicketOpen, fetchAllTicket }) => {
    const theme = useTheme()

    const [priority, setPriority] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [selectedDocFiles, setSelectedDocFiles] = useState('')
    const [url, setUrl] = useState('')
    const [errorMessage1, setErrorMessage1] = useState('')
    const [errorMessage2, setErrorMessage2] = useState('')
    const [errorMessage3, setErrorMessage3] = useState('')
    const [errorMessage4, setErrorMessage4] = useState('')
    const [errorMessage5, setErrorMessage5] = useState('')
    const { handleLogout } = useLogout();

    const navigate = useNavigate();


    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    const handleDocChange = e => {
        setSelectedDocFiles(e.target.files[0])
        e.target.value = ''
    }

    const handlePriority = e => {
        setPriority(e.target.value)
        setErrorMessage4('')
    }

    const handleSubject = e => {
        setSubject(e.target.value)
        setErrorMessage1('')
    }

    const handleSubmitUpClick = async () => {
        // Update the regular expression to exclude specific special characters

        if (!subject) {
            setErrorMessage1(' Please fill in all required fields.')
        } else if (!priority) {
            setErrorMessage4(' Please fill in all required fields.')
        } else if (errorMessage5 == null) {
            setErrorMessage5(' Please fill in with valid system URL')
        } else if (!message) {
            setErrorMessage2(' Please fill in all required fields.')
        } else {
            setErrorMessage1('')
            setErrorMessage2('')
            setErrorMessage3('')
            setErrorMessage4('')
            try {

                const formData = new FormData()
                formData.append('priority', priority)
                formData.append('subject', subject)
                formData.append('message', message)
                formData.append('file', selectedDocFiles)
                formData.append('sys_url', url)
                formData.append('id', adminId)

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/add-ticket`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,

                    },
                    body: formData
                })

                if (response.status == 403 || response.status == 401) {

                    let refreshToken = ''
                    if (typeof localStorage !== 'undefined') {
                        refreshToken = window.localStorage.getItem('refreshToken')
                    }

                    try {
                        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                refreshToken: refreshToken
                            })
                        })
                        if (tokenResponse.status == 200) {
                            const tokendata = await tokenResponse.json()
                            window.localStorage.setItem('token', tokendata.accessToken)
                            token = tokendata.accessToken
                            handleSubmitUpClick()
                        } else {
                            handleLogout();
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }

                if (response.status === 201) {
                    Swal.fire({
                        title: 'Good job!',
                        text: 'Successfully added your ticket!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0482da',
                        customClass: {
                            container: 'custom-swal-container' // Add a custom class to the container
                        }
                    })

                    setAddTicketOpen(false)
                    setSelectedDocFiles('')
                    setSubject('')
                    setPriority('')
                    setUrl('')
                    setMessage('')
                    fetchAllTicket()
                    document.getElementById('account-settings-upload-image').value = ''


                }
                if (response.status == 500 || response.status == 400) {
                    Swal.fire({
                        title: 'Warning',
                        text: 'sorry there was an error!',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0482da',
                        customClass: {
                            container: 'custom-swal-container' // Add a custom class to the container
                        }
                    })
                }
            } catch (error) {
                console.error('Error submitting data:', error)
            } finally {
            }
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault()
            const { selectionStart, selectionEnd } = event.target
            setMessage(prevText => {
                const start = prevText.substring(0, selectionStart)
                const end = prevText.substring(selectionEnd)

                return `${start}\n${end}`
            })
        }
    }

    const handleClose = () => {
        setAddTicketOpen(false)

    }

    const truncateText = (text) => {
        if (text.length <= 20) {
            return text;
        }

        return text.slice(0, 15) + '...';
    };

    const removeuploadfile = () => {
        setSelectedDocFiles('')
    }

    return (
        <Dialog onClose={handleClose} open={addTicketOpen} fullWidth maxWidth='md'>

            <DialogTitle id='customized-dialog-title' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 0 }}>
                <Box sx={{ width: 40, height: 40 }}> </Box>
                <Typography variant='' sx={{ textAlign: 'center' }}></Typography>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{}}
                >
                    <Icon icon='mdi:close' />
                </IconButton>
            </DialogTitle>

            <DialogContent>

                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontWeight: 600, mb: 1.5, fontSize: '1.25rem' }}>Add Support Ticket</Typography>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>

                        <Grid container spacing={3} sx={{ mb: 2.5 }}>
                            <Grid item xs={12} sm={5.5}>
                                {/* <Grid container spacing={3} > */}
                                    {/* <Grid item xs={12} sm={12}> */}
                                        <Typography sx={{ fontWeight: 600, mb: 1 }}>Subject:</Typography>
                                    {/* </Grid> */}
                                    {/* <Grid item xs={12} sm={12}> */}
                                        <TextField
                                            fullWidth
                                            label=''
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            sx={{ marginRight: '0px' }}
                                            value={subject}
                                            onChange={handleSubject}
                                            error={!!errorMessage1}
                                            helperText={errorMessage1}
                                            inputProps={{
                                                maxLength: 35
                                            }}
                                        />
                                    {/* </Grid> */}
                                {/* </Grid> */}
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                {/* <Grid container spacing={6} > */}
                                    {/* <Grid item xs={12} sm={12}> */}
                                        <Typography sx={{ fontWeight: 600, mb: 1 }}>Priority :</Typography>
                                    {/* </Grid> */}
                                    {/* <Grid item xs={12} sm={12}> */}
                                        <Select
                                            label=''
                                            defaultValue='Select Priority'
                                            id='form-layouts-tabs-select'
                                            labelId='form-layouts-tabs-select-label'
                                            fullWidth
                                            size='small'
                                            value={priority}
                                            onChange={handlePriority}
                                        >
                                            <MenuItem value=''>Select Priority</MenuItem>
                                            <MenuItem value='immediate'>Immediate</MenuItem>
                                            <MenuItem value='high'>High</MenuItem>
                                            <MenuItem value='medium'>Medium</MenuItem>
                                            <MenuItem value='low'>Low</MenuItem>
                                        </Select>
                                        {errorMessage4 && (
                                            <Typography variant='body2' color='error' gutterBottom>
                                                {errorMessage4}
                                            </Typography>
                                        )}
                                    {/* </Grid> */}
                                {/* </Grid> */}
                            </Grid>

                            <Grid item xs={12} sm={3.5}></Grid>

                            <Grid item xs={12} sm={11.5}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            value={message}
                                            onChange={e => setMessage(e.target.value)}
                                            sx={{ '& .MuiOutlinedInput-root': { alignItems: 'baseline', whiteSpace: 'pre-line' } }}
                                            onKeyDown={handleKeyDown}
                                            minRows={4}
                                            label='Message'
                                            placeholder='Message...'
                                            error={!!errorMessage2}
                                            helperText={errorMessage2}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={11.5}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12}>
                                        {/* <Typography sx={{ fontWeight: 600, mb: -1 }}>Attachment:</Typography> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <IconButton
                                            size='small'
                                            component='label'
                                            htmlFor='account-settings-upload-image'
                                            sx={{ color: 'text.primary', fontSize: '1rem', fontWeight: 600, }}
                                        >
                                            Attachment : <Icon icon='mdi:attachment' fontSize='1.375rem' />
                                            <input hidden type='file' onChange={handleDocChange} id='account-settings-upload-image' />
                                        </IconButton>
                                        {selectedDocFiles && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto', }}>
                                                <Box sx={{ p: 1, color: selectedDocFiles ? '' : 'text.disabled', backgroundColor: '#b1dbf1', height: 30 }}>
                                                    {selectedDocFiles ? truncateText(selectedDocFiles.name) : ''}
                                                </Box>
                                                <Box aria-label='sendsms' sx={{ backgroundColor: '#b1dbf1', display: 'flex', justifyContent: 'center', alignItems: "center", height: 30, cursor: 'pointer' }} onClick={removeuploadfile}>
                                                    <Icon icon='iconamoon:close-fill' />
                                                </Box>
                                            </Box>
                                        )}
                                        <Typography sx={{ mt: 0, color: 'text.disabled' }}>(Allowed PDF ,JPG)</Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={11.5}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12}>
                                        <Typography sx={{ fontWeight: 600, mb: -1 }}>System URL:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            label=''
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            sx={{ marginRight: '0px' }}
                                            value={url}
                                            onChange={e => {
                                                const inputUrl = e.target.value

                                                const urlRegex = /^https?:\/\/englishteacher\.lk\/.*$/
                                                if (urlRegex.test(inputUrl)) {
                                                    setUrl(inputUrl)
                                                    setErrorMessage5('')
                                                } else {
                                                    setErrorMessage5('Please enter a URL from englishteacher.lk')
                                                }
                                            }}
                                            error={!!errorMessage5}
                                            helperText={errorMessage5}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={11.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant='contained' sx={{ mt: 2 }} onClick={handleSubmitUpClick}>

                                    <Icon icon='mdi:send' />
                                    Submit Ticket
                                </Button>{' '}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </DialogContent>

        </Dialog>

    )
}

export default AddTickets
