import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Box,
  Stack,
  Paper,
  Badge,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, MenuItem, Select, TextField, Tooltip,Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Star from '../components/star';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CircularIndeterminate from '../components/loading';
import TablePagination from '@mui/material/TablePagination';
import useLogout from '../hooks/logouthook';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Icon } from '@iconify/react/dist/iconify.js';
import {CircularProgress} from "@mui/material";



const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));

function TeacherCard() {
  const { t_id } = useParams();
  const studentID = localStorage.getItem('studentID');
  const [teacherData, setTeacherData] = useState([])
  const [teacherID, setTeacherID] = useState("");
  const [errors, setErrors] = useState('');
  const [errors1, setErrors1] = useState('');
  const [teacher, setTeacher] = useState([]);
  const [date, setDate] = useState("");
  const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);
  const [amount, setAmount] = useState('');
  const [openAccountBalanceModal, setOpenAccountBalanceModal] = useState(false);
  const [accBalance, setAccBalance] = useState({ totalAmount: 0, acc_balance: 0 });
  const [schedule_date, setSchedule_date] = useState("");
  const [availableDate, setAvailableDate] = useState([]);
  const [availableTime, setAvailableTime] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [maxHour, setMaxHour] = useState(0);
  const [loading1, setLoading1] = React.useState(true);
  const [currency, setCurrency] = useState("");
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 4;
  const navigate = useNavigate('');
  const { handleLogout } = useLogout()
  const [loading, setLoading] = useState(false);


  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleButtonClick = (t_id) => {
    handleOpenTimeSlotModal(t_id);
  };


  useEffect(() => {
    fetchTeacherData();
  }, [studentID, page]);

  const fetchTeacherData = async () => {
    setLoading1(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/myteachers`, {
        method: 'POST',
        body: JSON.stringify({
          studentID,
          page,
          itemsPerPage

        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }

      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          console.log("object")
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;
            return fetchTeacherData();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      let getData = await response.json();
      setTeacherData(getData.Data)
      setCurrency(getData.currency);
      setTotalItems(getData.totalItems);
      setLoading1(false);
    } catch (err) {

    }
  };

  const fetchTeacher = async (t_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacherData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ t_id })
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchTeacher(t_id);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      const getData = await response.json();
      setTeacher(getData.Data[0]);
    } catch (err) {
      console.error('Error fetching teacher data:', err);
    }
  };


  // Time Slot Modal

  const handleOpenTimeSlotModal = (id) => {
    setOpenTimeSlotModal(true);
    fetchAvailableDate(id);
    fetchTeacher(id);
    setErrors("");
    setErrors1("");
  };

  const handleCloseTimeSlotModal = () => {
    setOpenTimeSlotModal(false);
    setDate("");
    setSelectedSlots([]);
    setErrors("");
    setErrors1("");
  };


  // AccountBalance Modal

  const newAccountBalance = accBalance.acc_balance - accBalance.totalAmount;

  const handleopenAccountBalanceModal = () => {
    if (!date) {
      setErrors("Please select a date");
    } else if (selectedSlots.length === 0) {
      setErrors1('Please select time slots that you want.');

    } else {

      setOpenAccountBalanceModal(true);
      setOpenTimeSlotModal(false);
      setDate("");
      fetchAccBalance();
    }
  };

  const handleCloseAccountBalanceModal = () => {
    setOpenAccountBalanceModal(false);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);
    fetchAvailableTime(selectedDate);
    setSelectedSlots([]);
    setErrors("");
  };

  const handleTimeChange = () => {
    setErrors1("");
  };

  const handleChangeSheduleDate = () => {
    setOpenAccountBalanceModal(false);
    setOpenTimeSlotModal(true);
  };

  useEffect(() => {
    if (t_id) {
      fetchAvailableDate(t_id);
    }
  }, [t_id]);

  const fetchAvailableDate = async (t_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/book_date/${t_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setAvailableDate(getData.Data);
      setTeacherID(getData.t_id);
    } catch (err) {
      console.error('Error fetching available dates:', err);
    }
  };

  const fetchAvailableTime = async (schedule_date) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/timeslots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          t_id: teacherID,
          schedule_date
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchAvailableTime(schedule_date);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      const getData = await response.json();
      setAvailableTime(getData.Data);
      setMaxHour(getData.maxHour);
      setSchedule_date(schedule_date);
    } catch (err) {
      console.error('Error fetching available time:', err);
    }
  };

  const handleSelectTimeSlot = (timeSlot) => {
    setErrors("");
    const slotIndex = selectedSlots.findIndex(slot => slot.start_time === timeSlot.start_time && slot.end_time === timeSlot.end_time);
    let newSelectedSlots = [...selectedSlots];

    if (slotIndex > -1) {
      newSelectedSlots.splice(slotIndex, 1); // Deselect if already selected
    } else {
      newSelectedSlots.push(timeSlot); // Select if not already selected
    }

    // Check if the selected slots are within the allowed range
    newSelectedSlots.sort((a, b) => new Date(`1970-01-01T${a.start_time}`) - new Date(`1970-01-01T${b.start_time}`));

    let validSelection = true;

    for (let i = 0; i < newSelectedSlots.length; i++) {
      let consecutiveHours = 0;
      for (let j = i; j < newSelectedSlots.length; j++) {
        const slotStart = new Date(`1970-01-01T${newSelectedSlots[j].start_time}`);
        const slotEnd = new Date(`1970-01-01T${newSelectedSlots[j].end_time}`);

        if (j > i) {
          const previousSlotEnd = new Date(`1970-01-01T${newSelectedSlots[j - 1].end_time}`);
          const gapHours = (slotStart - previousSlotEnd) / (1000 * 60 * 60);

          if (gapHours !== 0) {
            break;
          }
        }

        consecutiveHours += (slotEnd - slotStart) / (1000 * 60 * 60);
        if (consecutiveHours > maxHour) {
          validSelection = false;
          break;
        }
      }
      if (!validSelection) {
        break;
      }
    }

    if (validSelection) {
      setSelectedSlots(newSelectedSlots);
    } else {
      setErrors("You cannot select more than 2 consecutive hours.");
    }

  };

  const fetchAccBalance = async () => {
    try {
      const storedStudentID = localStorage.getItem('studentID');

      if (!storedStudentID) {
        throw new Error("Student ID is not found in session storage");
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/schedule_class`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          studentID: storedStudentID,
          t_id: teacher.t_id,
          selectedSlots
        })
      });
      console.log(selectedSlots);

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchAccBalance();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();

      setAccBalance(getData);
      setCurrency(getData.currency);

    } catch (err) {
      console.error("Error fetching account balance: ", err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const storedStudentID = localStorage.getItem('studentID');

    if (accBalance.acc_balance < accBalance.totalAmount) {
      setOpenAccountBalanceModal(false);

      Swal.fire({
        icon: 'error',
        title: 'Insufficient Balance',
        text: 'Your account balance is insufficient to complete this booking. Please Top-Up your account.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Cancel',
        showCancelButton: true,
        cancelButtonText: 'Top-Up',
        cancelButtonColor: '#3085d6'
      }).then((result) => {
        if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
          window.location.href = '/account_topup';
        }
      });
      setLoading(false);
      return;
    }

    const booked_date = new Date();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/confirm_booking`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          t_id: teacher.t_id,
          selectedSlots,
          totalAmount: accBalance.totalAmount,
          acc_balance: accBalance.acc_balance,
          booked_date: booked_date.toISOString(),
          schedule_date: schedule_date
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });


      if (!response.ok) {
        if (response.status === 400) {
          setOpenAccountBalanceModal(false);
          const data = await response.json();

          // Display the SweetAlert based on the error returned
          Swal.fire({
            icon: 'error',
            title: 'Booking Failed',
            text: data.error || 'An error occurred during the booking process.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });

          return;
        }
        setLoading(false);
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return handleSubmit(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      } else {
        const data = await response.json();

        if (data.message === 'Class booked successfully!') {
          setOpenAccountBalanceModal(false);
          setErrors('');

          const scheduleDate = schedule_date;
          // const startTime = selectedSlots[0].start_time; 
          // const endTime = selectedSlots[selectedSlots.length - 1].end_time;


          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {selectedSlots.map((slot, index) => (
              <Typography key={index}>
                {slot.start_time} - {slot.end_time}
              </Typography>
            ))}
          </Grid>;


          const slotsHtml = selectedSlots.map((slot) => {
            return `<p><strong>Schedule Time:</strong> ${slot.start_time} - ${slot.end_time}</p>`;
          }).join('');

          Swal.fire({
            icon: 'success',
            title: 'Class booked successfully!',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Go to dashboard',
            showCancelButton: true,
            cancelButtonText: 'Class Details',
            cancelButtonColor: '#3085d6',
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirect when "OK" is clicked
              window.location.href = '/dashboard';
            } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
              // Redirect when "Class Details" is clicked
              window.location.href = '/pendingApproval_class';
            }
          });
          setLoading(false);
        }
      }
    } catch (error) {
      setOpenAccountBalanceModal(false);
      Swal.fire({
        icon: 'error',
        title: 'Booking Failed',
        text: 'An unexpected error occurred. Please try again later.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      setLoading(false);
    }
  };

  const handleNavigate = (item) => {
    // Navigate to the dynamic URL with the teacher's name and ID
    navigate(`/English_Teacher_${item.t_id}-${item.t_fname}.html`);
  };

  // Top Up Modal

  const handleOpenTopUpModal = (newAccountBalance) => {
    setAmount(newAccountBalance);
    setOpenAccountBalanceModal(false);
    setOpenTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setOpenTopUpModal(false);
  };

  const handleSubmitPay = async (event) => {
    event.preventDefault();

    const storedStudentID = localStorage.getItem('studentID');

    setErrors("");

    if (!amount) {
      setErrors("Please add an amount");
      return;
    }
    const numericAmount = parseInt(amount, 10);
    if (numericAmount < 1000 || numericAmount > 15000) {
      setErrors("Amount must be between 1000 and 15000");
      return;
    }


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account_topup`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          amount


        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return handleSubmitPay(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      } else {
        const data = await response.json();

        if (data.error) {
          setErrors(data.error);
        } else {
          // setStu_Data1(data);
          setAmount("");


          window.payhere.onCompleted = function onCompleted(OrderID) {
            // alert("Payment completed. OrderID:" + amount);
            setOpenAccountBalanceModal(true);
            get_AccBalance();
          };


          window.payhere.onDismissed = function onDismissed() {

            console.log("Payment dismissed");
            setOpenAccountBalanceModal(true);
            get_AccBalance();
          };


          window.payhere.onError = function onError(error) {

            console.log("Error:" + error);
          };

          // console.log('stu_Data1.order_id',stu_Data1.order_id)

          const payment = {
            sandbox: true,
            merchant_id: data.merchantId,
            return_url: `${process.env.PRODUCTION_URL}/account_topup`,
            cancel_url: `${process.env.PRODUCTION_URL}/account_topup`,
            notify_url: 'https://englishteacher.sparkglobalsolutions.net/notifyWeb.php',
            order_id: data.order_id.toString(),
            items: 'Account Topup',
            currency: data.currency.toString(),
            amount: amount.toString(),
            first_name: data.first_name.toString(),
            last_name: data.last_name.toString(),
            email: data.email.toString(),
            phone: data.phone.toString(),
            address: data.address.toString(),
            custom_1: data.studentID.toString(),
            city: data.city.toString(),
            country: 'Sri Lanka',
            hash: data.hash.toString(),
          };
          window.payhere.startPayment(payment);
          setOpenTopUpModal(false);
          // setOpenTimeSlotModal(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);

    }
  };

  const get_AccBalance = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_accBalance`, {
      method: "POST",
      body: JSON.stringify({
        studentID,

      }),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });

    if (response.status === 403 || response.status === 401) {
      let refreshToken = '';
      if (typeof localStorage !== 'undefined') {
        refreshToken = window.localStorage.getItem('refreshToken');
      }

      try {
        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        });

        if (tokenResponse.status === 200) {
          const tokendata = await tokenResponse.json();
          window.localStorage.setItem('token', tokendata.accessToken);
          token = tokendata.accessToken;


          return get_AccBalance();
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        handleLogout();
      }

      return;
    } else {
      const data = await response.json();

      if (response.status === 200) {
        setAccBalance((prevState) => ({
          ...prevState,
          acc_balance: data.acc_balance
        }));
      }

    }
  }

  console.log(teacherData)

  return (
    <div>
      <Card sx={{ mb: 1 }}>
        <CardHeader title={<Typography sx={{ fontSize: '15px' }}>My Teachers</Typography>} sx={{ background: ' #00669e', color: 'white' }} />
        <CardContent>
          {loading1 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularIndeterminate />
            </Box>
          ) : (
            <Grid container sx={{ flexGrow: 1, justifyContent: 'space-between', mt: 0.2 }}>
              {teacherData.length === 0 ? (
                <Typography sx={{ mx: 'auto', display: 'flex', alignItems: 'center', gap: '5px', color: '#c0c0c0' }}> <ErrorOutlineIcon /> There are no booked teachers</Typography>
              ) : (
                teacherData.map((item) => (
                  <Grid
                    item
                    key={item.t_id}
                    xs={12}
                    sm={5.9}
                    sx={{
                      mb: 2,
                      py: 2,
                      borderRadius: 2,
                      boxShadow: 5,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      '@media (min-width: 600px)': {
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        boxShadow: 3
                      },
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'center', alignItems: 'center', borderRadius: 2 }}>
                      <Grid container alignItems="center" justifyContent="column">


                        <Box sx={{ display: 'flex', ml: 2, gap: 2 }}>

                          <Avatar alt="User Picture" onClick={() => handleNavigate(item)} src={item.profile} sx={{ width: 80, height: 80, border: 1, ml: 2, cursor: "pointer" }} />


                          <Box sx={{}}>
                            <Box>
                              <Typography onClick={() => handleNavigate(item)} sx={{ cursor: "pointer" }}>{item.t_fname} {item.t_lname} </Typography>
                            </Box>

                            <Box >
                              <Star value={item.rate} />
                            </Box>
                          </Box>
                        </Box>


                        <Grid sx={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', padding: '20px' }}>
                            <iframe
                              class="iframe"
                              src={item.introVideo1}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        </Grid>

                        <Box sx={{ mx: 3, display: 'flex', justifyContent: 'space-between', gap: 8, width: '100%' }}>

                          <Box>
                            <Box>
                              <Typography>{currency} {item.t_hourrate?.toFixed(2)}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ fontSize: 14 }}>Per Hour</Typography>
                            </Box>
                          </Box>

                          <Box sx={{ display: 'flex', gap: 2 }}>
                            {item.schedule_date ? (
                              <Typography
                                component="div"
                                sx={{
                                  color: "white",
                                  backgroundColor: "#0d2353",
                                  borderRadius: '8px',
                                  height: '40px',
                                  width: '140px',
                                  gap: '8px', 
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  cursor: 'pointer', 
                                  '&:hover': {
                                    backgroundColor: "#0d65c8",
                                  },
                                  '&:hover .icon': {
                                    transform: 'translateX(5px)', 
                                    transition: 'transform 0.5s ease', // Smooth animation
                                  },
                                }}
                                onClick={() => handleButtonClick(item.t_id)}
                              >
                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
                                  Book Again
                                  <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                                </Box>
                              </Typography>
                            ) : (
                              <Button
                                type="button" 
                                variant="contained"
                                onClick={() => handleNavigate(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  backgroundColor: "#0d2353",
                                  borderRadius: '8px',
                                  height: '40px',
                                }}
                              >
                                Profile
                              </Button>
                            )}
                          </Box>

                        </Box>

                      </Grid>
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
          )}
          {totalItems > 4 && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={itemsPerPage}
                rowsPerPageOptions={[]}
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog open={openTimeSlotModal} onClose={handleCloseTimeSlotModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', background: 'linear-gradient(to right , #101847, #00669e)', color: 'white' }}>
          <DialogTitle>Schedule a class</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTimeSlotModal} />
          </Tooltip>
        </Grid>

        <DialogContent>
          {/* <DialogContentText>Student Name</DialogContentText>
          <TextField fullWidth /> */}

          <Stack direction="row" justifyContent='space-between' >
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar src={teacher.profile} alt="Teacher" sx={{ height: 'auto', borderRadius: "50%", width: 90, height: 90 }} />
              </Grid>
              <Grid item>
                <Typography color='black'>{teacher.t_fname} {teacher.t_lname}</Typography>
              </Grid>
            </Grid>


            <Grid item container direction="column" justifyContent="center" alignItems="flex-end"  >
              <Grid item >
                <Star value={teacher.rate} />
              </Grid>

              <Grid item sx={{ mr: 2 }}>
                <Typography>LKR {teacher.t_hourrate?.toFixed(2)}</Typography>
                <Typography mt="6px" style={{ fontSize: 14, fontWeight: 100 }}>Per Hour</Typography>
              </Grid>
            </Grid>

          </Stack>



          {/* <DialogContentText sx={{ mt: 1 }}>Select Date?</DialogContentText> */}
          <FormControl fullWidth>
            <Select sx={{ mt: 1 }} value={date} onChange={handleDateChange} displayEmpty>
              <MenuItem value="" disabled>Please Select A Date</MenuItem>
              {availableDate.map((item, index) => (
                <MenuItem key={item.schedule_date} value={item.schedule_date}>
                  {new Date(item.schedule_date).toLocaleDateString("en-CA")}
                </MenuItem>
              ))}
            </Select>
            {errors && (
              <Typography variant="caption" display="block" sx={{ color: 'red', mt: 2 }}>
                {errors}
              </Typography>
            )}
          </FormControl>

          {date && (
            <>
              <DialogContentText sx={{ mt: 1, mb: 1 }}>Select Time Slots</DialogContentText>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Grid container spacing={1}>
                  {availableTime.map((item, index) => (
                    <Grid item key={index} >
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? '#00669e' : 'transparent',
                          color: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? 'white' : 'black',
                          '&:hover': {
                            backgroundColor: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? '#005bb5' : '#e0e0e0'
                          }
                        }}

                        onClick={() => {
                          handleTimeChange();
                          handleSelectTimeSlot(item);
                        }}
                      >
                        {item.start_time} - {item.end_time}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {errors1 && (
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 2 }}>
                    {errors1}
                  </Typography>
                )}
              </FormControl>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            sx={{
              type: "submit",
              dispaly: 'flex',
              justifyContent: 'center',
              color: 'white',
              backgroundColor: '#0d2353',
              '&:hover': { backgroundColor: '#0d65c8' },
              variant: 'contained',
              borderRadius: '6px',
              mb: 2.5,
              px: 2,
            }}
            onClick={() => handleopenAccountBalanceModal()}
          >
            Schedule a class
          </Button>
        </DialogActions>
      </Dialog>

      <CustomDialog open={openAccountBalanceModal} onClose={handleCloseAccountBalanceModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Close" >
            <CloseIcon sx={{ mt: 1, mr: 1, cursor: 'pointer', border: '1px solid black', borderRadius: 5, width: '35px', height: '35px' }} onClick={handleCloseAccountBalanceModal} />
          </Tooltip>
        </Grid>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container sx={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ color: '#101847', fontSize: 18 }}>
              Review and Confirm Your Booking Details
              <Divider sx={{ p: 0.5, mb: 3 }} />
            </Typography>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Schedule Date:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography>{schedule_date}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Schedule Time:</Typography>
              <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}>(Total Hours:{accBalance.totalHours})</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              {selectedSlots.map((slot, index) => (
                <Typography key={index}>
                  {slot.start_time} - {slot.end_time}
                </Typography>
              ))}
            </Grid>


            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Per Hour Rate:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography>{currency} {Number(accBalance.t_hourrate)?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ my: 2 }}>
              <Typography>Total Fee:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mt: 2 }} >
              <Typography sx={{ borderBottom: 1, borderTop: 1 }}>{currency} {Number(accBalance.totalAmount)?.toFixed(2)}
                <Grid container justifyContent="flex-end">
                </Grid>
              </Typography>
            </Grid>

            <Grid item xs={6}  >
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>Account Balance:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{currency} {Number(accBalance.acc_balance)?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>New Account Balance:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{currency} {Number(newAccountBalance)?.toFixed(2)}</Typography>
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          {accBalance.acc_balance >= accBalance.totalAmount ? (
            <Grid container direction="row" justifyContent="center" gap={2} pb={3}>
              <Button
                type="submit"
                variant="outlined"
                style={{
                  border: '2px solid #0d65c8',
                  borderColor: '#0d65c8',
                  borderRadius: '6px',
                  px: 2,
                }}

                onClick={handleChangeSheduleDate}
              >
                Change Slots
              </Button>

              <Button
                type="submit"
                sx={{
                  color: 'white',
                  backgroundColor: '#0d2353',
                  '&:hover': { backgroundColor: '#0d65c8' },
                  variant: 'contained',
                  borderRadius: '6px',
                  px: 2,
                }}
                onClick={handleSubmit}
                disabled={loading} 
                startIcon={loading  ? (
                  <CircularProgress size="30px"  />
                  ) : null}
              >
                Confirm Your Class
              </Button>

            </Grid>
          ) : (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography sx={{ textAlign: 'center', color: 'orange', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                  <ErrorOutlineIcon sx={{ color: 'orange', fontSize: '1.3rem' }} />
                  Your account balance is insufficient. Please Top up your account.
                </Typography>
              </Grid>

              <Grid container direction="row" justifyContent="center" gap={2} p={2}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    border: '2px solid #0d65c8',
                    borderColor: '#0d65c8',
                    borderRadius: '6px',
                    px: 2,
                  }}

                  onClick={handleChangeSheduleDate}
                >
                  Change Slots
                </Button>

                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: '#0d2353',
                    '&:hover': { backgroundColor: '#0d65c8' },
                    variant: 'contained',
                    borderRadius: '6px',
                  }}
                  onClick={() => handleOpenTopUpModal(Math.abs(newAccountBalance))}
                >
                  Top-up
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </CustomDialog>

      <Dialog open={openTopUpModal} onClose={handleCloseTopUpModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', mb: 2 }}>
          <DialogTitle >Top-Up your Account</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTopUpModal} />
          </Tooltip>
        </Grid>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card xs={12} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'left', width: '100%' }}>
            <CardContent>
              <Grid container direction="column" alignItems="left" sx={{ width: '100%' }}>
                <Grid sx={{ display: 'flex', alignContent: 'flex-end' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#fafafa', borderRadius: 2, boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)', p: 1.2 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: "500", color: 'black', }}>Account Balance : {accBalance.acc_balance} </Typography>
                    <Typography sx={{ ml: 0.5, pb: 0.2, fontSize: 15, fontWeight: "500", color: '#888885', alignContent: 'flex-end' }}>{currency} </Typography>
                  </Box>
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#fafafa', mt: 2 }}>
                  <Typography sx={{ mt: 4 }} >Amount</Typography>
                  <Typography sx={{ ml: 0.5, pb: 0.2, fontSize: 13, fontWeight: "500", color: '#888885', alignContent: 'flex-end' }}>{currency} </Typography>
                </Box>

                <Stack direction="row" justifyContent='space-between' spacing={2} sx={{ mt: 3 }} >
                  <Grid item xs={12} sx={{ mt: 5 }} >
                    <TextField
                      label="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      fullWidth
                    />
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    size="md"
                    sx={{ border: '1px solid black', borderRadius: 1, backgroundColor: '#101847', width: '65%', mt: 5 }}
                    onClick={(event) => handleSubmitPay(event, studentID, amount)}
                  >
                    Pay with card
                  </Button>
                </Stack>
                {errors ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0 }}>
                    {errors}
                  </Typography>
                  : null
                }



                <Grid item xs={12} sx={{ mt: 3, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href="https://www.payhere.lk" target="_blank" rel="noopener noreferrer">
                    <img src="https://www.payhere.lk/downloads/images/payhere_long_banner.png" alt="PayHere" style={{ maxWidth: '100%', height: 'auto' }} />
                  </a>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TeacherCard;