import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { MainListItems, SecondaryListItems, ThirdListItems } from './listItems'; // Ensure this import is correct

const drawerWidth = '100%'; // Adjust this as needed

export default function Dashboard() {
  return (
    <List component="nav" sx={{ display: { xs: 'none', md: 'block' }, width: drawerWidth }}>
      {MainListItems()}
      {/* <Divider sx={{ my: 1 }} /> */}
      {SecondaryListItems()}
      {/* <Divider sx={{ my: 1 }} /> */}
      {ThirdListItems()}
    </List>
  );
}