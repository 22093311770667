import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Box,
  Stack,
  Paper,
  Badge,
  Button
} from '@mui/material';
import {styled} from '@mui/material/styles';

const Item = styled(Paper)(({theme})=>({
  backgroundColor: theme.palette.mode === 'dark'? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function UnpaidCard() {
  const [classData, setClassData] = useState([]);
  const studentID = localStorage.getItem('studentID');
  

  useEffect(() => {
    if (studentID) {
      fetchClassData();
    }
  }, [studentID]);

  const fetchClassData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Unpaid_class`, {
        method: 'POST',
        body: JSON.stringify({ 
          studentID }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setClassData(getData); 
    } catch (err) {
      console.error('Failed to fetch data', err);
      setClassData([]); 
    }
  };

  return (
    <div>
      <Card sx={{ mb: 1 }}>
        <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Unpaid Classes</Typography>} sx={{ background: '#00669e', color: 'white' }} />
        <CardContent>
          <Grid container sx={{ flexGrow: 1, justifyContent: 'space-between', mt: 0.2 }}>
            {classData.map((item) => (

              <Grid item key={item.ssch_id} xs={12} sm={5.9} lg={3.9} sx={{ mb: 2, py: 2, borderRadius: 2, boxShadow: 2 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
                  <Avatar alt="User Picture" src={item.profile} sx={{ my: 2, width: 100, height: 100, border: 1 }} />

                  <Typography variant="body2" sx={{ fontSize: '18px', mb: 2 }}>{item.t_fname} {item.t_lname}</Typography>

                  <Badge badgeContent={'Teacher Approved'} color="success" 
                  sx={{
                          '& .MuiBadge-badge': {
                            whiteSpace: 'nowrap', // Ensure the text does not wrap
                            padding: '0 8px', // Increase padding to make the badge wider if necessary
                          },
                  }}></Badge>

                  <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                    <Grid item>
                      <Typography variant="body1">Class ID : {item.ssch_id}</Typography>
                    </Grid>
                  </Grid>

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',background: 'linear-gradient(to right , #101847, #00669e)',p:2}}>
                    <Stack alignItems="center">
                      <Stack direction="row" alignItems="center">
                        <Typography style={{color:'white', mt:2}}>{item.start_time} - {item.end_time}</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography style={{color:'white'}}>{new Date(item.schedule_date).toISOString().substring(0, 10)}</Typography>
                      </Stack>
                    </Stack>
                  </Box>

                  <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', my:3, }}>
                    <Grid container sx={{display:'flex', justifyContent:'space-between'}} >
                        <Grid item xs={3.8}>
                          <Item sx={{backgroundColor:'rgba(91, 180, 228, 0.4)'}}>
                            <Typography>Hour</Typography>
                            <Typography>05</Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={3.8}>
                          <Item sx={{backgroundColor:'rgba(91, 180, 228, 0.4)'}}>
                            <Typography>Min</Typography>
                            <Typography>05</Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={3.8} >
                          <Item sx={{backgroundColor:'rgba(91, 180, 228, 0.4)'}}>
                            <Typography>Sec</Typography>
                            <Typography>05</Typography>
                          </Item>
                        </Grid>
                      </Grid>  
                  </Box>

              <Button size="small" variant="outlined" mb="2">Any Question?</Button>
              

                  

                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default UnpaidCard;