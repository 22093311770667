import React from 'react'

const TeacherAgreement = () => {
  return (
    <div>
      Teacher Agreement
    </div>
  )
}

export default TeacherAgreement
