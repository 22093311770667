import * as React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Grid, Dialog, DialogContent, DialogTitle, styled, TextField, Select, InputAdornment } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import HailIcon from '@mui/icons-material/Hail';
import ForumIcon from '@mui/icons-material/Forum';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import TodayIcon from '@mui/icons-material/Today';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MessageIcon from '@mui/icons-material/Message';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TaskIcon from '@mui/icons-material/Task';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import UserDetails from "./loginPage/UserDetails";
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import DescriptionIcon from '@mui/icons-material/Description';
import BookOnlineIcon from '@mui/icons-material/BookOnline';

function CustomPopper(props) {
  return <Popper {...props} placement="top-start" />;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));


function NavbarLog() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const open = Boolean(anchorElNav);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [stu_Data, setStu_Data] = useState({
    stu_id: '',
    stu_fname: '',
    stu_lname: '',
    profile: '',
    acc_balance: '',
    phone_num: '',
    email: '',
    NIC: '',
    city: '',
    country: '',
    address: '',
    gender: '',
    birthday: ''
  });
  const studentID = localStorage.getItem('studentID');
  const navigate = useNavigate();
  const location = useLocation();
  const [currency, setCurrency] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t_id: urlT_id } = useParams();
  const [t_id, setT_id] = useState(urlT_id || "");
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [stu_fname, setStu_fname] = useState("");
  const [stu_lname, setStu_lname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Sri Lanka");
  const [NIC, setNIC] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const pathname = location.pathname;

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleButtonClick = async (id) => {
    setT_id(id);
    const sessionID = localStorage.getItem('studentID');

    if (sessionID) {
      try {
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: sessionID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
        });

        if (studentResponse.status === 403 || studentResponse.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }
      
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });
      
            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken); 
              token = tokendata.accessToken;
      
      
              return handleButtonClick(id);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout(); 
          }
      
          return; 
        }

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();
        if (studentData.temp == 0 && studentData.phone_num) {
          handleOpenProfileModal();
        } else if (studentData.temp == 0 && studentData.email) {
          setOpenUserDetails(true);
        } else {
          console.log("dash")
          handleOpenDashboard();
        }
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    } else {
    }
  };

  const handleOpenDashboard = () => {
    navigate("/dashboard")
  };

  const handleButtonClickProfile = async (id) => {
    setT_id(id);
    const sessionID = localStorage.getItem('studentID');

    if (sessionID) {
      try {
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: sessionID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
        });

        if (studentResponse.status === 403 || studentResponse.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }
      
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });
      
            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken); 
              token = tokendata.accessToken;
      
      
              return handleButtonClickProfile(id);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout(); 
          }
      
          return; 
        }

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();
        console.log(studentData);
        if (studentData.temp == 0 && studentData.phone_num) {
          handleOpenProfileModal();
        } else if (studentData.temp == 0 && studentData.email) {
          setOpenUserDetails(true);
        } else {
          console.log("dash")
          handleProfile();
        }

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    } else {
    }
  };

  const handleButtonClickMessage = async (id) => {
    setT_id(id);
    const sessionID = localStorage.getItem('studentID');

    if (sessionID) {
      try {
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: sessionID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
        });

        if (studentResponse.status === 403 || studentResponse.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }
      
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });
      
            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken); 
              token = tokendata.accessToken;
      
      
              return handleButtonClickMessage(id);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout(); 
          }
      
          return; 
        }

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();
        console.log(studentData);
        if (studentData.temp == 0 && studentData.phone_num) {
          handleOpenProfileModal();
        } else if (studentData.temp == 0 && studentData.email) {
          setOpenUserDetails(true);
        } else {
          console.log("dash")
          navigateMessage();
        }

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    } else {
    }
  };



  useEffect(() => {
    if (studentID) {
      fetchStu_Data();
    }
  }, [studentID]);

  const handleLogout = () => {
    localStorage.removeItem('studentID');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');


    if (location.pathname === '/') {
      window.location.reload();
    } else {
      navigate('/');
    }
  };

  const fetchStu_Data = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
        method: 'POST',
        body: JSON.stringify({
          studentID

        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }


      });
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchStu_Data()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      }else{
        let getData = await response.json();

      setStu_Data({
        stu_id: getData?.student?.stu_id || '',
        stu_fname: getData?.student?.stu_fname || '',
        stu_lname: getData?.student?.stu_lname || '',
        profile: getData?.student?.profile || '',
        acc_balance: getData?.student?.acc_balance || '',
        phone_num: getData?.student?.phone_num || '',
        email: getData?.student?.email || '',
        NIC: getData?.student?.NIC || '',
        city: getData?.student?.city || '',
        country: getData?.student?.country || '',
        address: getData?.student?.address || '',
        gender: getData?.student?.gender || '',
        birthday: getData?.student?.birthday || ''
      });
      }
    } catch (err) {

    }
  };


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfile = () => {
    setAnchorElUser(null);
    navigate("/profile_setting")
  };

  const navigateMessage = () => {
    navigate("/messages")
  };


  //Profile Model

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  };

  const handlefirstname = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastname = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handleemail = (event) => {
    setEmail(event.target.value);
    setErrors("")
  };

  const handleaddress = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecity = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountry = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false);
    setErrors("");
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();

    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!email) {
      newErrors.email = "Please enter the Email";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details`, {
          method: 'POST',
          body: JSON.stringify({
            studentID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            email,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }
      
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });
      
            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken); 
              token = tokendata.accessToken;
              return handleSubmitProfile(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout(); 
          }
      
          return; 
        }

        const data = await response.json();

        if (response.status === 200) {
          setStu_fname("");
          setStu_lname("");
          setEmail("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModal();
          // handleOpenTimeSlotModal(t_id); 
          navigate("/dashboard");
        }else{
          setErrors({...errors,email:"This email is already registered. Please use another email."});
        }

      } catch (error) {
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };



  return (
    <AppBar position="fixed" sx={{ background: 'linear-gradient(to right , #101847, #00669e)' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-start', display: { xs: 'flex', md: 'none' } }}>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >

                <AppBar position="static" sx={{ background: 'linear-gradient(to right , #101847, #00669e)' }}>
                  <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                      <img src="/images/White.png" alt="Your Logo" style={{ width: 150, marginRight: 10 }} />
                    </Link>

                    <IconButton edge="end" onClick={toggleDrawer(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Box style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }} onClick={() => handleButtonClick()}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </Box>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Icon icon="ic:sharp-person-search" width="1.3rem" height="1.3rem" />
                    </ListItemIcon>
                    <ListItemText primary="Find a Teacher" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/community_knowledge" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <GroupsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Community" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/today_class" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <TodayIcon />
                    </ListItemIcon>
                    <ListItemText primary="Today Class" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/upcoming_class" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upcoming Class" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/pendingApproval_class" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <PendingActionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pending Approvals" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/completed_classes" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <TaskIcon />
                    </ListItemIcon>
                    <ListItemText primary="Completed Classes" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/myteachers" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Teachers" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={() => handleButtonClickMessage()}>
                  {/* <Link to="/messages" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}> */}
                    <ListItemIcon>
                      <MessageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Messages" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }} />
                  {/* </Link> */}
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/account_topup" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Icon icon="fa-brands:cc-visa" width="1.3rem" height="1.3rem" />
                    </ListItemIcon>
                    <ListItemText primary="Account Top-Up" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/slip_upload" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bank Payments" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/topuphistory" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Icon icon="subway:file-1" width="1.3rem" height="1.3rem" />
                    </ListItemIcon>
                    <ListItemText primary="Top-Up History" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/billing_history" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Icon icon="material-symbols:content-paste" width="1.3rem" height="1.3rem" />
                    </ListItemIcon>
                    <ListItemText primary="Billing History" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/My_Post" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <ForumIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Post" />
                  </Link>
                </MenuItem>
                
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/ticket" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                    <BookOnlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tickets" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={() => handleButtonClickProfile()}>
                  {/* <Link to="/profile_setting" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}></Link> */}
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }} />
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/privacy_policy" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                  </Link>
                </MenuItem>

            <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/T_C" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terms & Conditions" />
                  </Link>
                </MenuItem>
              </Box>
            </Drawer>

      
            <IconButton
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Grid sx={{ flexGrow: 1, justifyContent: 'center', display: { xs: 'flex', md: 'none' } }}>
              <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                <img src="/images/White.png" alt="Your Logo" style={{ width: 150 }} />
              </Link>
            </Grid>

            <Grid sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' } }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleOpenUserMenu}
                  size="small"
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  sx={{ ml: 7 }}
                >
                  <Avatar alt="User Picture" src={stu_Data?.profile} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Box>

          {!drawerOpen && (
            <Grid sx={{ flexGrow: 1, justifyContent: 'flex-start', display: { xs: 'none', md: 'flex' } }}>
              <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                <img src="/images/White.png" alt="Your Logo" style={{ width: 150 }} />
              </Link>
            </Grid>
          )}

          {/* <Tooltip title="Account settings">
              <IconButton
                onClick={handleOpenUserMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar alt="User Picture" src={stu_Data?.profile} />
              </IconButton>
            </Tooltip> */}


          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>

            <MenuItem>
              <Box
                onClick={handleButtonClick}
                sx={{
                  // color: "white",
                  // fontFamily: "sans-serif",
                  // margin: 0,
                  // textDecoration: pathname == '/dashboard' ? "underline" : "none",
                  textDecoration : "none",
                color: "white",
                backgroundColor: pathname == '/dashboard' ? "#9a9a9a3d" : "transparent", // Change background color
                padding: "4px 10px", // Optional: Add padding for better visibility
                borderRadius: "8px" 
                }} 
              >
                <p style={{ margin: 0, fontFamily: 'sans-serif' }}>Dashboard</p>
              </Box>
            </MenuItem>


            <MenuItem>
              <NavLink to="/" style={({ isActive }) => ({
                textDecoration : "none",
                color: "white",
                backgroundColor: isActive ? "#9a9a9a3d" : "transparent", // Change background color
                padding: "4px 10px", // Optional: Add padding for better visibility
                borderRadius: "8px" 
              })}>
                <p style={{ margin: 0, fontFamily: 'sans-serif' }}>Find a Teacher</p>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink to="/community_knowledge" style={({ isActive }) => ({
                textDecoration : "none",
                color: "white",
                backgroundColor: isActive ? "#9a9a9a3d" : "transparent", // Change background color
                padding: "4px 10px", // Optional: Add padding for better visibility
                borderRadius: "8px" 
              })}>
                <p style={{ margin: 0, fontFamily: 'sans-serif' }}>Community</p>
              </NavLink>
            </MenuItem>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleOpenUserMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar alt="User Picture" src={stu_Data?.profile} />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}

              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 0.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >


              <MenuItem onClick={handleCloseUserMenu}>
                <Avatar alt="User Picture" src={stu_Data?.profile} />
                <Grid>
                  <Typography onClick={() => handleButtonClickProfile()} fontSize="small">{stu_Data.stu_fname} {stu_Data.stu_lname}</Typography>
                  <Typography onClick={() => handleButtonClickProfile()} fontSize="small" style={{ color: 'gray' }}>Student ID: {stu_Data.stu_id}</Typography>
                </Grid>
              </MenuItem>
              <Divider />

              <MenuItem onClick={() => handleButtonClickMessage()}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center', gap: 1
                }}>
                  <MessageIcon />
                  <Typography >Message</Typography>
                </Box>
              </MenuItem>

              <MenuItem onClick={() => handleButtonClickProfile()}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center', gap: 1
                }}>
                  <SettingsIcon />
                  <Typography>Profile</Typography>
                </Box>


              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center', gap: 1
                }}>
                  <LogoutIcon />
                  <Typography >Logout</Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>

        </Toolbar>
      </Container>

      <CustomDialog open={openProfileModal} onClose={handleCloseProfileModal} fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black'}}>
          <DialogTitle sx={{ ml: 1 }} >Please Enter your Details</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 3, cursor: 'pointer' }} onClick={handleCloseProfileModal} />
          </Tooltip>
        </Box>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <form

            style={{
              width: "100%",
              backgroundColor: "linear-gradient(to right , #101847, #00669e",
              color: "white"
            }}
            onSubmit={handleSubmitProfile}

          >
            <Grid container spacing={1} sx={{ px: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="stu_fname"
                  name="firstName"
                  label="First Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",


                  }}
                  fullWidth
                  value={stu_fname}
                  onChange={handlefirstname}

                />
                {errors.stu_fname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_fname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={stu_lname}
                  onChange={handlelastname}

                />
                {errors.stu_lname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_lname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1
                  }}
                  fullWidth
                  value={email}
                  onChange={handleemail}
                />
                {errors.email ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.email}
                  </Typography> : null
                }
              </Grid >

              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="House No, Lane Name"
                  label="House No, Lane Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1

                  }}
                  fullWidth
                  value={address}
                  onChange={handleaddress}

                />
                {errors.address ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.address}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="city"
                  name="City"
                  label="City"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={city}
                  onChange={handlecity}

                />
                {errors.city ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.city}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  value={country}
                  onChange={handlecountry}
                  options={countries}
                  sx={{ width: "100%" }}
                  PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                  ListboxProps={{
                    sx: {
                      maxHeight: '200px', // Set height for dropdown content
                      overflowY: 'auto', // Enable scrolling only inside the list
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                    />
                  )}
                />
              </Grid>


              <Grid item
                mb={1}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="md"
                >
                  Save
                </Button>

              </Grid>
            </Grid>
          </form>

        </DialogContent>
      </CustomDialog>

      <UserDetails setOpenUserDetails={setOpenUserDetails} openUserDetails={openUserDetails} />
    </AppBar>
  );
}

export default NavbarLog;


