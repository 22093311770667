import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CircularProgress, Typography, Chip, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Icon } from '@iconify/react/dist/iconify.js'
import IconButton from '@mui/material/IconButton'
import {  useTheme } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';

const MessageCard = ({ item }) => {
    const theme = useTheme()
    const navigate =useNavigate();

    const getBackgroundColor = item => {
        if (item?.type === 'admin') {
            return theme.palette.mode === 'dark' ? '#36454F' : '#D8F5FE'
        }

        return 'inherit'
    }

    return (
        <div>
            <Box sx={{ mt: 2 }}>
                <Card sx={{ cursor: '', backgroundColor: getBackgroundColor(item), border:1 , borderColor: 'grey.500'}}>
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

                                <Chip
                                    label={`Replied By ${item?.type? item?.type: ''}`}
                                    size='small'
                                    sx={{
                                        backgroundColor: item?.type === 'teacher' ? '#6c757d' : '#32a74d',
                                        color: '#ffffff',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        '& .MuiChip-label': {
                                            textOverflow: 'clip'
                                        }
                                    }}
                                />
                                
                            </Box>
                            <Box>
                                <Chip
                                    label={new Date(item?.date).toLocaleString("en-CA")}
                                    size='small'
                                    sx={{
                                       
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        '& .MuiChip-label': {
                                            textOverflow: 'clip'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        {item?.message && (
                            <Typography sx={{ fontWeight: '600', mt: 4 }}>{item.message}</Typography>
                        )}
                        {item?.sys_url && (
                            <Typography sx={{ fontWeight: '300', mt: 4 }}>System URL : {item.sys_url}</Typography>
                        )}
                        {item?.file && (
                            <Grid sx={{ display: 'flex', mb: -2, mt: 4 }}>
                                <Typography sx={{ fontWeight: 400 }}>Attachment : </Typography>
                                <Tooltip title='View Attachment'>
                                    <Link to={item.file} target='_blank'>
                                    <IconButton
                                        aria-label='View'
                                        size='small'
                                        sx={{ height: '32px', width: '32px', color: '#17A2B8', mb: -2, mt: 0 }}
                                        
                                    >
                                        <Icon icon='mdi:document'>BR</Icon>
                                    </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                        )}

                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default MessageCard