import { createTheme } from '@mui/material/styles';

// Define your theme here
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
            boxShadow: 'none', // Remove the default boxShadow from DataGrid
          },
        cell: {
          display: 'flex',
          alignItems: 'center', // Vertically center content in DataGrid cells
          justifyContent: 'center', // Horizontally center content
        },
        columnHeaders: {
          textTransform: 'uppercase', // Make all text in the header capitalized
        },
      }
    },
    MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', // Add a custom boxShadow to the Card
          borderRadius: '10px',
          }
        }
      },
  }
});

export default theme;
