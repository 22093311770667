import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress sx={{color: 'linear-gradient(to right , #101847, #00669e)'}}/>
    </Box>
  );
}