import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { io } from "socket.io-client";
import MessageProfile from "./MessageProfile";
import MessageContent from "./MessageContent";
import useLogout from "../../hooks/logouthook";


const socket = io("https://api.englishteacher.lk:5000");

const Message = () => {
  const { t_id, studentID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const adminId = localStorage.getItem("studentID");
  const navigate = useNavigate();
  const {handleLogout} = useLogout()

  console.log(messages)

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  useEffect(() => {
    socket.on("message", (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    socket.on("client_unread", (unreadmsg) => {
      if (unreadmsg.room !== selectedRoom) {
        updateunreadmsg(unreadmsg);
      }
      updateStudentLastMsg(unreadmsg);
    });

    socket.on("all_messages", (data) => {
      console.log(data)
      setMessages(data.result);
    });

    return () => {
      socket.off("message");
      socket.off("client_unread");
      socket.off("all_messages");
    };
  }, [selectedRoom]);

  useEffect(() => {
    // Fetch all teacher data for the student
    const fetchAllTeachers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/messagesTeachers`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
               Authorization: `Bearer ${token}`

            },
            body: JSON.stringify({ stu_id: adminId }),
          }
        );

        if (response.status == 403 || response.status == 401) {

          let refreshToken = ''
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken')
          }
    
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                refreshToken: refreshToken
              })
            })
            if (tokenResponse.status == 200) {
              const tokendata = await tokenResponse.json()
              window.localStorage.setItem('token', tokendata.accessToken)
              token = tokendata.accessToken
              fetchAllTeachers();
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error(error)
          }
        }

        if (response.status === 200) {
          const data = await response.json();
          setTableData(data.result);
        } else {
          setTableData([]);
        }
      } catch (error) {
        console.error("Error fetching all teachers:", error);
      }
    };

    fetchAllTeachers();
  }, [adminId]);

  useEffect(() => {
    // Fetch chat data for the specific teacher (t_id) and student (studentID)
    const fetchTeacherChat = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/messagesTeachers`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
               Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ stu_id: studentID, t_id }),
          }
        );

        if (response.status == 403 || response.status == 401) {

          let refreshToken = ''
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken')
          }
    
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                refreshToken: refreshToken
              })
            })
            if (tokenResponse.status == 200) {
              const tokendata = await tokenResponse.json()
              window.localStorage.setItem('token', tokendata.accessToken)
              token = tokendata.accessToken
              fetchTeacherChat();
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error(error)
          }
        }

        if (response.status === 200) {
          const data = await response.json();
          if (data.result.length > 0) {
            const selectedChat = data.result.find(chat => chat.t_id === parseInt(t_id));
            if (selectedChat) {
              setSelectedRoom(selectedChat.id);
              setSelectedUser(selectedChat.t_id);
              setSelectedUserProfile(selectedChat);
              getallMessages(selectedChat);
            }
          }
        } else {
          setTableData([]);
        }
      } catch (error) {
        console.error("Error fetching teacher chat:", error);
      }
    };

    if (t_id && studentID) {
      fetchTeacherChat();
    }
  }, [t_id, studentID]);

  const handleProfile = (profile) => {
    if (selectedRoom) {
      socket.emit("leave_room", selectedRoom);
    }
    setSelectedRoom(profile.id);
    setSelectedUser(profile.t_id);
    setSelectedUserProfile(profile);
    socket.emit("join_room", profile.id);
    socket.emit("all_messages", profile.id);
    removeunreadmsg(profile.id);
  };

  const getallMessages = (data) => {
    socket.emit("join_room", data.id);
    socket.emit("all_messages", data.id);
  };

  const handleSendSMS = () => {
    if (message || filePath) {
      socket.emit("message", {
        send: adminId,
        to: selectedUser,
        message: message.trim(),
        sender: "student",
        room: selectedRoom,
        datetime: new Date(),
        URL: filePath,
      });
      const msg = {
        send: adminId,
        to: selectedUser,
        message: message.trim(),
        sender: "student",
        room: selectedRoom,
        datetime: new Date(),
        URL: filePath,
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      setMessage("");
      setFile("");
      setFilePath("");
    }
  };

  const updateStudentLastMsg = (msg) => {
    setTableData((prevData) => {
      const updatedData = prevData.map((table) =>
        table.id === msg.room
          ? { ...table, last_msg: msg.message, datetime: new Date() }
          : table
      );
      return updatedData.sort(
        (a, b) => new Date(b.datetime) - new Date(a.datetime)
      );
    });
  };

  const updateunreadmsg = (data) => {
    if (data.sender === "teacher") {
      setTableData((prevData) =>
        prevData.map((table) =>
          table.id === data.room
            ? { ...table, stu_unread: (table.stu_unread || 0) + 1 }
            : table
        )
      );
    } else {
      setTableData((prevData) =>
        prevData.map((table) =>
          table.id === data.room
            ? { ...table, t_unread: (table.t_unread || 0) + 1 }
            : table
        )
      );
    }
  };

  const removeunreadmsg = (id) => {
    setTableData((prevData) =>
      prevData.map((table) =>
        table.id === id ? { ...table, stu_unread: 0 } : table
      )
    );
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", adminId);
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/upload-file`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setFilePath(data.filePath);
          event.target.value =""
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const removeuploadfile = () => {
    setFile("");
    // setFilePath("");
    // setLoading(false);
  };

  return (
    <div>
      <Box
        className="app-chat"
        sx={{
          width: "100%",
          minHeight: "calc(100vh - 170px)",
          display: "flex",
          borderRadius: 1,
          overflow: "hidden",
          position: "relative",
          backgroundColor: "background.paper",
          boxShadow: 6,
          border: '1px solid rgba(76, 78, 100, 0.12)'
        }}
      >
        <MessageProfile
          tableData={tableData}
          handleProfile={handleProfile}
          selectedRoom={selectedRoom}
          handleLeftSidebarToggle={() => setLeftSidebarOpen(!leftSidebarOpen)}
          leftSidebarOpen={leftSidebarOpen}
        />

        <Box
          sx={{
            width: 0,
            flexGrow: 1,
            height: "100%",
            backgroundColor: "action.hover",
            minHeight: "calc(100vh - 170px)",
          }}
        >
          <MessageContent
            selectedRoom={selectedRoom}
            selectedUserProfile={selectedUserProfile}
            handleLeftSidebarToggle={() => setLeftSidebarOpen(!leftSidebarOpen)}
            allmessages={messages}
            message={message}
            setMessage={setMessage}
            handleSendSMS={handleSendSMS}
            tableData={tableData}
            handleChange={handleChange}
            file={file}
            loading={loading}
            filePath={filePath}
            removeuploadfile={removeuploadfile}
          />
        </Box>
      </Box>
    </div>
  );
};

Message.contentHeightFixed = true;

export default Message;




