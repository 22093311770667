import React from 'react';
import { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardContent,
  CardHeader, 
  Stack, 
  Typography,
  Button,
  Grid, 

} from '@mui/material';

import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Date_Picker from '../components/date_picker';
import {Menu, MenuItem } from '@mui/material';
import DropDown from '../components/dropdown';


function BookNow() {

  const [anchorEl, setAnchorEl] = useState(null); 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); 
  };

  const handleClose = () => {
    setAnchorEl(null); 
  };

  
    return (
        <div className="home-page">
          
        <NavbarLog/>
         <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
          <CardHeader title={<Typography sx={{ fontSize:20, display:'flex', justifyContent: 'center'}}>Book Now</Typography>} />
            <CardContent sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                <Typography sx={{mb:2}}>Please Pick date and time for book your teacher</Typography>

                {/* <Stack direction="row" justifyContent="center">
                  <Date_Picker xs={12} sm={6} />
                  <Button xs={12} sm={6} sx={{ border: '1px solid black', ml: 10, width: '20%' }}>
                    Set Time
                  </Button>
                </Stack> */}
                <Grid  sx={{display:'flex', justifyContent:'center'}}>
                  {/* <Button
                    onClick={handleClick}
                    sx={{ border: '1px solid black', position: 'relative' }}
                    size="large"
                  >
                    Select Date
                  </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={handleClose}>Option 1</MenuItem>
                      <MenuItem onClick={handleClose}>Option 2</MenuItem>
                      <MenuItem onClick={handleClose}>Option 3</MenuItem>
                    </Menu> */}

                    <DropDown/>
                  </Grid>

            </CardContent>
          </Card>
         </Container>
            <Footer/>
        </div>
    )
}

export default BookNow;


