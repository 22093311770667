import React, { useState, forwardRef, useEffect, useRef } from 'react'
import { CircularProgress, Typography, Chip, Card, CardContent, Box, TextField, Button, Fab, Zoom, Avatar, Divider, Grow, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import PhotoIcon from '@mui/icons-material/Photo';
import ArticleIcon from '@mui/icons-material/Article';
// import Icon from 'src/@core/components/icon'
import TodayIcon from '@mui/icons-material/Today';
import { styled } from '@mui/material/styles'
import AddPost from './AddPost';
import ForumCard from './ForumCard';
import CommentPopup from './CommentPopup';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer'
import RandomTeachers from '../common_forum/RandomTeachers';
import useLogout from '../../hooks/logouthook';
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Popper from '@mui/material/Popper';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';

function CustomPopper(props) {
    return <Popper {...props} placement="top-start" />;
    }
  
  const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: '10px',
      border: '5px solid #00669e',
    },
  }));

const ForumMain = () => {
    const [tableData, setTableData] = useState([])
    const [totalPost, setTotalPost] = useState(0)
    const [subComments, setSubComments] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [likesArray, setLikesArray] = useState([])
    const [message, setMessage] = useState('')
    const [isLoadingComments, setIsLoadingComments] = useState(false)
    const [page, setPage] = useState(1);
    const [addOpenPost, setAddOpenPost] = useState(false);
    const [commentOpen, setCommentOpen] = useState(false)
    const [forumObject, setForumObject] = useState(null)
    const [pendingPostOpen, setPendingPostOpen] = useState(false)
    const [isHovered, setIsHovered] = useState(false);
    const [userProfile, setUserProfile] = useState('')
    const [triggerFileInput, setTriggerFileInput] = useState(false)
    const [subPage, setSubPage] = useState(0)
    const [totalComments, setTotalComments] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [banner, setBanner] = useState([])
    const [otherBanner, setOtherBanner] = useState([])
    const [randomTeacherP, setRandomTeacherP] = useState([])

    const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openMobileNumberModal, setOpenMobileNumberModal] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [errors1, setErrors1] = useState("");
  const [otp, setOTP] = useState(new Array(4).fill(""));
  const [studentID, setStudentID] = useState("");
  const [openOTPModal, setOpenOTPModal] = React.useState(false);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [stu_fname, setStu_fname] = useState("");
  const [stu_lname, setStu_lname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Sri Lanka");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileOrEmail, setMobileOrEmail] = useState('mobile')
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [enterPassword, setEnterPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [renewPassword, setReNewPassword] = useState('');
  const [shownewPassword, setShowNewPassword] = useState('');
  const [showrenewPassword, setShowReNewPassword] = useState('');
  const [newpasswordError, setNewPasswordError] = useState ('');
  const [emailpassword, setEmailpassword] = useState('');
  const [showemailPassword, setShowemailPassword] = useState(false);
  const [openUserDetailsEmail, setOpenUserDetailsEmail] = useState(false);
  const [emailPassworderror, setEmailPassworderror] = useState('');
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const studentsID = localStorage.getItem('studentID');
  const [phone, setPhone] = useState("");
  const [mobileError, setMobileError] = useState("");
  const { t_id: urlT_id } = useParams();
  const [t_id, setT_id] = useState(urlT_id || "");
  const [errors2, setErrors2] = useState("");


    const navigate = useNavigate()
    const { handleLogout } = useLogout();

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    // Intersection Observer
    const { ref, inView } = useInView({
        triggerOnce: false, // Keep triggering as the element comes into view
        threshold: 1.0 // Element should be fully visible
    })

    useEffect(() => {
        fetchRandomTeacher()
        fetchBanner()
        fetchOtherBanner()
        fetchLikes()
        fetchForumCount()
        fetchStu_Data()
    }, [])

    useEffect(() => {
        if (inView) fetchForums()
    }, [inView])

    useEffect(() => {
        if (subPage === 0) {

            return
        }

        fetchSubMessage(forumObject?.fro_id)
    }, [subPage])

    const fetchForums = async () => {
        if (isLoading || !hasMore) return
        setIsLoading(true)

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/log_all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    fetchForums()
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              } else if (response.status == 200) {
                const data = await response.json()
                setTableData(prevData => [...prevData, ...data.result])
                setHasMore(data.result.length > 0) // Assuming no more data when API returns an empty array
                setPage(prevPage => prevPage + 1)
                setIsLoading(false)
            } else {
                setTableData((prevData) => [...prevData])
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error:', error)
        }

    }

    const fetchReset = async () => {

        setIsLoading(true)

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/log_all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: 1,
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    fetchReset()
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }else if (response.status == 200) {
                const data = await response.json()
                setTableData(data.result)
                setHasMore(data.result.length > 0) // Assuming no more data when API returns an empty array
                setPage(prevPage => prevPage + 1)
                setIsLoading(false)
            } else {
                setTableData((prevData) => [...prevData])
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchLikes = async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/like`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: adminId
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    fetchLikes()
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }
            else if (response.status == 200) {
                const data = await response.json()
                setLikesArray(data.result)

            } else {
                setLikesArray([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchSubMessage = async (id) => {
        setIsLoadingComments(true)
        console.log(subPage)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/sub-comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    post_id: id,
                    page: subPage
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    fetchSubMessage(id)
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }

            if (response.status == 200) {
                const data = await response.json()
                setSubComments(prevComments => [...prevComments, ...data.result])
                setIsLoadingComments(false)
                setTotalComments(data.totalRecords)
            } else {
                setIsLoadingComments(false)
                setSubComments(prevComments => [...prevComments])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleCommentClick = (post) => {
        setCommentOpen(true)
        if (post) {
            setForumObject(post)
        }
        fetchSubMessage(post.fro_id)

    };

    const handleLike = async (post) => {
        const index = likesArray.findIndex(item => item.fro_id === post.fro_id);

        const tableIndex = tableData.findIndex(item => item.fro_id === post.fro_id);
        const newArray = [...tableData]

        if (index !== -1) {
            // Modify the specific object's attribute
            if (newArray[tableIndex]) {
                newArray[tableIndex].det_like -= 1 // Update as per your requirement
            }

            // Update state with the modified array
            setTableData(newArray);

            // Remove the object if it exists
            setLikesArray(prevLikes => prevLikes.filter(item => item.fro_id !== post.fro_id));
        } else {
            // Update anotherState when the item is not found
            if (newArray[tableIndex]) {
                newArray[tableIndex].det_like += 1 // Update as per your requirement
            }

            // Update state with the modified array
            setTableData(newArray);

            // Add the object if it does not exist
            setLikesArray(prevLikes => [...prevLikes, { fro_id: post.fro_id }]);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/addLike`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: post.fro_id,
                    t_id: adminId
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    handleLike(post)
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }

        } catch (error) {
            console.error(error);
        }
    }

    const handleSendSMS = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/add-comment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: id,
                    t_id: adminId,
                    message: message
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    handleSendSMS(id)
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              } else if (response.status == 200) {
                setMessage('')
                const data = await response.json()

                const tableIndex = tableData.findIndex(item => item.fro_id === id);
                const newArray = [...tableData]

                if (tableIndex) {
                    newArray[tableIndex].comments += 1 // Update as per your requirement
                }

                // Update state with the Post array
                setTableData(newArray);

                setSubComments((preData) => {
                    const updatedData = [...preData, data.result]

                    const sortData = updatedData.sort((a, b) => b.d_id - a.d_id);

                    return sortData;
                })

            }

        } catch (error) {
            console.error(error);
        }
    }

    const fetchForumCount = async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/forum-count`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                })
            })

            
            if (response.status == 200) {
                const data = await response.json()
                setTotalPost(data.result)

            } else {

            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleCommentPage = () => {
        setSubPage(subPage + 1)

    }

    const fetchStu_Data = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
                method: 'POST',
                body: JSON.stringify({
                    studentID: adminId

                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    fetchStu_Data()
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }

            if (!response.ok) {
                throw new Error(`HTTP error: Status ${response.status}`);
            }

            const getData = await response.json();

            setUserProfile(getData?.student?.profile || '');
        } catch (err) {
            console.log(err)
        }
    };

    const fetchBanner = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/banner`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            })

            if (response.status == 200) {
                const data = await response.json()
                setBanner(data.result)
            } else {
                setBanner([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchOtherBanner = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/other-banner`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            })

            if (response.status == 200) {
                const data = await response.json()
                setOtherBanner(data.result)
            } else {
                setOtherBanner([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const fetchRandomTeacher = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/random-teacher`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            })

            if (response.status == 200) {
                const data = await response.json()
                setRandomTeacherP(data.results)
            } else {
                setRandomTeacherP([])
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    //Profile Model

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  };

  const handlefirstname = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastname = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handleemail = (event) => {
    setEmail(event.target.value);
    setErrors("")
  };

  const handleaddress = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecity = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountry = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false);
    setErrors("");
  };

  const handleCloseUserDetailsEmail = () => {
    setOpenUserDetailsEmail(false);
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();

    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!email) {
      newErrors.email = "Please enter the Email";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details`, {
          method: 'POST',
          body: JSON.stringify({
            studentID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            email,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

          }
        });

        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            console.log("object")
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfile(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          setStu_fname("");
          setStu_lname("");
          setEmail("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModal();
          navigate('/community_knowledge');
        } else {
          setErrors({ ...errors, email: "This email is already registered. Please use another email." });
        }

      } catch (error) {
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

 

  //ProfileEmail Model


  const handlefirstnameEmail = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastnameEmail = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handlePhone = (event) => {
    let valuemob = event.target.value;
    setErrors({ phone: "" }); // Initialize the errors state
    setMobileError("");

    if (!valuemob) {
      setErrors({ phone: "" }); // Clear errors if input is empty
    } else if (!/^\+?[\d]+$/.test(valuemob)) {
      setErrors({ phone: "Invalid mobile number. Only digits and an optional '+' are allowed." });
    } else {
      if (valuemob.startsWith("7")) {
        if (valuemob.length !== 9) {
          setErrors({ phone: "Invalid mobile number. Must be 9 digits when starting with '7'." });
        }
      } else if (valuemob.startsWith("07")) {
        if (valuemob.length !== 10) {
          setErrors({ phone: "Invalid mobile number. Must be 10 digits when starting with '07'." });
        }
      } else if (valuemob.startsWith("947")) {
        if (valuemob.length !== 11) {
          setErrors({ phone: "Invalid mobile number. Must be 11 digits when starting with '947'." });
        }
      } else if (valuemob.startsWith("+947")) {
        if (valuemob.length !== 12) {
          setErrors({ phone: "Invalid mobile number. Must be 12 digits when starting with '+947'." });
        }
      } else {
        setErrors({ phone: "Invalid mobile number format." });
      }
    }

    setPhone(valuemob);
  };


  const handleaddressEmail = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecityEmail = (event) => {
    setCity(event.target.value);
    setErrors("")
  };


  const handlecountryEmail = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };


  const handleCloseProfileModalEmail = () => {
    setOpenUserDetailsEmail(false);
    setErrors("");
  };

  const capitalizeFirstLetterEmail = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfileEmail = async (event) => {
    event.preventDefault();

    let newErrors = {};


    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!phone) {
      newErrors.phone = "Please enter the Mobile Number";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details_Email`, {
          method: 'POST',
          body: JSON.stringify({
            studentID: studentsID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            phone,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });


        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            console.log("object")
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfileEmail(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          setStu_fname("");
          setStu_lname("");
          setPhone("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModalEmail();
          navigate('/community_knowledge');

        } else if (response.status === 400) {
          setMobileError(data.message);
        }

      } catch (error) {
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const handleButtonClickAsk = async (id) => {
    setT_id(id);
    const sessionID = localStorage.getItem('studentID');

    if (sessionID) {
      try {
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: sessionID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
        });

        if (studentResponse.status === 403 || studentResponse.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }
      
          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });
      
            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken); 
              token = tokendata.accessToken;
      
      
              return handleButtonClickAsk(id);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout(); 
          }
      
          return; 
        }

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();
        console.log(studentData);
        if (studentData.temp == 0 && studentData.phone_num) {
            setOpenProfileModal(true);
        } else if (studentData.temp == 0 && studentData.email) {
            setOpenUserDetailsEmail(true);
        } else {
            navigateAsk();
        }

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    } else {
    }
  };

  const navigateAsk = () => {
    setAddOpenPost(true)
  };
  

    return (
        <div>


            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>

                <Typography variant='h5' >
                    Knowledge & Community
                </Typography>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2.5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', backgroundColor: "" }}>

                    <Card sx={{ width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px', }}>
                        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar
                                alt='John Doe'
                                sx={{ width: 50, height: 50 }}
                                src={userProfile ? userProfile : '/images/avatars/1.png'}
                            />
                            <Typography sx={{ flexGrow: 1, border: "1px solid #4c4e64de", borderRadius: '20px', p: 1.5, fontWeight: 500, cursor: 'pointer' }} onClick={handleButtonClickAsk} >Ask a question</Typography>
                        </CardContent>

                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer', width: 'auto' }}
                                onClick={() => {
                                    setAddOpenPost(true)
                                    setTriggerFileInput(true)
                                }}>
                                {/* <Icon icon="pajamas:media" style={{ color: '#00669E' }} /> */}

                                {/* <PhotoIcon color="primary" />
                                <Typography sx={{ fontWeight: 500 }}>Media</Typography> */}
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer', width: 'auto' }}
                                onClick={() => {
                                    setAddOpenPost(true)
                                    setTriggerFileInput(false)
                                }}>
                                {/* <Icon icon="material-symbols:article" style={{ color: '#ffa500' }} /> */}

                                {/* <ArticleIcon sx={{ color: "orange" }} />
                                <Typography sx={{ fontWeight: 500 }}>Write Article</Typography> */}
                            </Box>

                            {/* <Box sx={{ width: 80, height: 24 }}></Box> */}
                        </CardContent>

                    </Card>

                    <Divider sx={{ my: 1 }} />

                    {tableData.map((post, index) => (
                        <Card key={index} sx={{ width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px' }}>
                            <CardContent sx={{ p: '20px' }}>

                                <ForumCard post={post} likesArray={likesArray} handleCommentClick={handleCommentClick} handleLike={handleLike} setTableData={setTableData} fetchReset={fetchReset} />

                            </CardContent>
                        </Card>
                    ))}


                    {hasMore && (
                        <Grow in={true} timeout={500}>
                            <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                {isLoading && <CircularProgress />}
                            </Box>
                        </Grow>
                    )}


                </Box>

                <Box
                    sx={{
                        backgroundColor: '',
                        maxWidth: '300px',
                        width: '100%',
                        display: { xs: 'none', md: 'block' }
                    }}
                >
                    {banner.map((data, index) => (
                        <img
                            key={index}
                            src={data.url}
                            alt='post'
                            style={{ width: '100%', height: 'auto', objectFit: 'contain', }} // Ensure image fills the container
                        />
                    ))}

                    <Box sx={{
                        display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '300px',
                        width: '300px', my: 1
                    }}>
                        {randomTeacherP.map((data, index) => (
                            <RandomTeachers teacher={data} key={index} />
                        ))}
                    </Box>

                    <Box sx={{
                        maxWidth: '300px',
                        width: '300px',
                        mb: 1,
                        position: 'sticky',
                        top: 80,
                        maxHeight: `calc(100vh - 400px)`
                    }}>
                        <Box>
                            {otherBanner.map((data, index) => (
                                <img
                                    key={index}
                                    src={data.url}
                                    alt='post'
                                    style={{ width: '100%', height: '300px', objectFit: 'contain', }} // Ensure image fills the container
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <AddPost addOpenPost={addOpenPost} setAddOpenPost={setAddOpenPost} fetchForums={fetchReset} triggerFileInput={triggerFileInput} />

            <CommentPopup setCommentOpen={setCommentOpen} commentOpen={commentOpen} post={forumObject} isLoadingComments={isLoadingComments} subComments={subComments} setSubComments={setSubComments} handleLike={handleLike} likesArray={likesArray} handleSendSMS={handleSendSMS} setMessage={setMessage} message={message} handleCommentPage={handleCommentPage} setSubPage={setSubPage} totalComments={totalComments} />

            <CustomDialog open={openProfileModal} onClose={handleCloseProfileModal} fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
          <DialogTitle sx={{ ml: 1 }} >Please Enter your Details</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 3, cursor: 'pointer' }} onClick={handleCloseProfileModal} />
          </Tooltip>
        </Box>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <form

            style={{
              width: "100%",
              backgroundColor: "linear-gradient(to right , #101847, #00669e",
              color: "white"
            }}
            onSubmit={handleSubmitProfile}

          >
            <Grid container spacing={1} sx={{ px: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="stu_fname"
                  name="firstName"
                  label="First Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",


                  }}
                  fullWidth
                  value={stu_fname}
                  onChange={handlefirstname}

                />
                {errors.stu_fname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_fname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={stu_lname}
                  onChange={handlelastname}

                />
                {errors.stu_lname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_lname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} >
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1
                  }}
                  fullWidth
                  value={email}
                  onChange={handleemail}
                />
                {errors.email ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.email}
                  </Typography> : null
                }
              </Grid >

              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="House No, Lane Name"
                  label="House No, Lane Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1

                  }}
                  fullWidth
                  value={address}
                  onChange={handleaddress}

                />
                {errors.address ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.address}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="city"
                  name="City"
                  label="City"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={city}
                  onChange={handlecity}

                />
                {errors.city ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.city}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  value={country}
                  onChange={handlecountry}
                  options={countries}
                  sx={{ width: "100%" }}
                  PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                  ListboxProps={{
                    sx: {
                      maxHeight: '200px', // Set height for dropdown content
                      overflowY: 'auto', // Enable scrolling only inside the list
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item
                mb={1}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="md"
                >
                  Save
                </Button>

              </Grid>
            </Grid>
          </form>

        </DialogContent>
          </CustomDialog>

            <Dialog open={openUserDetailsEmail} onClose={handleCloseUserDetailsEmail} fullWidth>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
                <DialogTitle sx={{ ml: 1 }} >Please Enter your Details</DialogTitle>
                <Tooltip title="Close">
                    <CloseIcon sx={{ mt: 2.5, mr: 3, cursor: 'pointer' }} onClick={handleCloseUserDetailsEmail} />
                </Tooltip>
                </Box>

                <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <form

                    style={{
                    width: "100%",
                    backgroundColor: "linear-gradient(to right , #101847, #00669e",
                    color: "white"
                    }}
                    onSubmit={handleSubmitProfileEmail}

                >
                    <Grid container spacing={1} sx={{ px: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        id="stu_fname"
                        name="firstName"
                        label="First Name"
                        size="large"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px !important",


                        }}
                        fullWidth
                        value={stu_fname}
                        onChange={handlefirstnameEmail}

                        />
                        {errors.stu_fname ?
                        <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {errors.stu_fname}
                        </Typography> : null
                        }
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        size="large"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px !important",

                        }}
                        fullWidth
                        value={stu_lname}
                        onChange={handlelastnameEmail}

                        />
                        {errors.stu_lname ?
                        <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {errors.stu_lname}
                        </Typography> : null
                        }

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        id="phone"
                        name="phone"
                        label="Mobile Number"
                        size="large"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px !important",
                            mt: 1
                        }}
                        fullWidth
                        value={phone}
                        onChange={handlePhone}
                        />
                        {errors.phone ?
                        <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {errors.phone}
                        </Typography> : null
                        }
                        {mobileError ?
                        <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {mobileError}
                        </Typography> : null
                        }
                    </Grid >

                    <Grid item xs={12}>
                        <TextField
                        id="address"
                        name="House No, Lane Name"
                        label="House No, Lane Name"
                        size="large"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px !important",
                            mt: 1

                        }}
                        fullWidth
                        value={address}
                        onChange={handleaddressEmail}

                        />
                        {errors.address ?
                        <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {errors.address}
                        </Typography> : null
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        id="city"
                        name="City"
                        label="City"
                        size="large"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px !important",

                        }}
                        fullWidth
                        value={city}
                        onChange={handlecityEmail}

                        />
                        {errors.city ?
                        <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {errors.city}
                        </Typography> : null
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                        disablePortal
                        options={countries}
                        value={country}
                        onChange={handlecountry}
                        sx={{ width: "100%" }}
                        PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Country"
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "10px !important",
                            }}
                            />
                        )}
                        />
                    </Grid>

                    <Grid item
                        mb={1}
                        xs={12}
                        sx={{

                        display: "flex",
                        justifyContent: "center",
                        }}
                    >

                        <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="md"
                        >
                        Save
                        </Button>

                    </Grid>
                    </Grid>
                </form>

                </DialogContent>
            </Dialog>
        </div>
    )
}


export default ForumMain
