import React from 'react';
import {
  Container,
  Card,
  CardContent, 
  Grid, 
  Avatar, 
  Typography, 

} from '@mui/material';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import SideMenu from '../components/sidemenu';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useLogout from '../hooks/logouthook';


function Common() {
  const [stu_Data, setStu_Data] = useState({
    stu_id: '',
    stu_fname: '',
    stu_lname: '',
    profile: '',
    acc_balance: '',
    phone_num: '',
    email: '',
    NIC: '',
    city: '',
    country: '',
    address: '',
    gender: '',
    birthday: ''
  });
    const studentID = localStorage.getItem('studentID');
    const {handleLogout} = useLogout()

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }



    useEffect(() => {
        if (studentID) { 
          fetchStu_Data();
        }
      }, [studentID]);
      
      const fetchStu_Data = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
            method: 'POST',
            body: JSON.stringify({
              studentID
    
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
          }
            
           
          });
          if (response.status == 403 || response.status == 401) {

            let refreshToken = ''
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken')
            }
    
            try {
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  refreshToken: refreshToken
                })
              })
              if (tokenResponse.status == 200) {
                const tokendata = await tokenResponse.json()
                window.localStorage.setItem('token', tokendata.accessToken)
                token = tokendata.accessToken
                fetchStu_Data()
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error(error)
            }
          }else{
            let getData = await response.json();
    
            setStu_Data({
              stu_id: getData?.student?.stu_id || '',
              stu_fname: getData?.student?.stu_fname || '',
              stu_lname: getData?.student?.stu_lname || '',
              profile: getData?.student?.profile || '',
              acc_balance: getData?.student?.acc_balance || '',
              phone_num: getData?.student?.phone_num || '',
              email: getData?.student?.email || '',
              NIC: getData?.student?.NIC || '',
              city: getData?.student?.city || '',
              country: getData?.student?.country || '',
              address: getData?.student?.address || '',
              gender: getData?.student?.gender || '',
              birthday: getData?.student?.birthday || ''
            });
          }
          
        } catch (err) {
          
        } 
      };
    

    return (
    
        <Grid xs={12} md={3} >
          <Grid item  sx={{ background: 'linear-gradient(to right , #101847, #00669e)',py:2,borderRadius: 2,boxShadow: 2, flexDirection: 'column',display: 'flex', alignItems: 'center'}}>
            <Avatar alt="User Picture" src={stu_Data?.profile} sx={{ my: 2,width: 150, height: 150  }}/>
            
            <Link to={`/profile_setting`} style={{ textDecoration: 'none', color: 'white' }}>
            <Typography variant="body2" sx={{ fontSize:'18px',mb:1}} style={{color:'white'}}>{stu_Data.stu_fname} {stu_Data.stu_lname}</Typography>
            </Link>
          </Grid>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2}}>
            <SideMenu/>
          </div>
        </Grid>
        
      );

 
}

    
export default Common;