import { CircularProgress, Typography, Chip } from '@mui/material'
import React, { useState, forwardRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import DatePicker from 'react-datepicker'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import Pagination from '@mui/material/Pagination'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useNavigate } from 'react-router-dom'
import TicketCard from './TicketCard'
import AddTickets from './AddTicket.'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@iconify/react/dist/iconify.js';
import useLogout from '../../hooks/logouthook'
import {CardHeader} from '@mui/material'


const AllTickets = () => {
    const [searchValue, setSearchValue] = useState('')
    const [status, setStatus] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [searchIcon, setSearchIcon] = useState(false)
    const [allTickets, setAllTickets] = useState([])
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0);
    const [addTicketOpen, setAddTicketOpen] = useState(false)
    const { handleLogout } = useLogout();

    const navigate = useNavigate()
    const theme = useTheme()
  
    const hide = useMediaQuery(theme.breakpoints.down('sm'))

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('userID')
    }

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

  
    useEffect(() => {
        fetchAllTicket()
    }, [page])

    const fetchAllTicket = async () => {
        if (searchValue || status) {
            setSearchIcon(true)
        }
        try {
            setIsLoading(true)

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/all-ticket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                    searchValue: searchValue,
                    status: status
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    fetchAllTicket()
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }
            const data = await response.json()
            if (response.status == 200) {
                setAllTickets(data.result);
                setTotalRecords(data.totalRecords); // Update the total records state
                const pageCount = Math.ceil(data.totalRecords / 10);
                setPageCount(pageCount);
            } else {
                setAllTickets([]);
                setTotalRecords(0); 
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            setAllTickets([]);
            setTotalRecords(0); 
        }
    };


    const TicketReset = async () => {
        try {
            setIsLoading(true)

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/all-ticket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                    searchValue: '',
                    status: ''
                })
            })
            if (response.status == 403 || response.status == 401) {

                let refreshToken = ''
                if (typeof localStorage !== 'undefined') {
                  refreshToken = window.localStorage.getItem('refreshToken')
                }
        
                try {
                  const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      refreshToken: refreshToken
                    })
                  })
                  if (tokenResponse.status == 200) {
                    const tokendata = await tokenResponse.json()
                    window.localStorage.setItem('token', tokendata.accessToken)
                    token = tokendata.accessToken
                    TicketReset()
                  } else {
                    handleLogout();
                  }
                } catch (error) {
                  console.error(error)
                }
              }
            const data = await response.json()
            if (response.status == 200) {
                setIsLoading(false)
                setAllTickets(data.result)
                const total = data.totalRecords
                const pageCount = Math.ceil(total / 10)
                setPageCount(pageCount)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleReset = () => {
        setSearchValue('')
        setStatus('')
        setPage(1)
        TicketReset()
        setSearchIcon(false)
    }

    const handleKeyPress = event => {
        // Check if the pressed key is Enter (key code 13)
        if (event.key === 'Enter') {
            fetchAllTicket()
        }
    }

    const handleChange = (event, value) => {
        setPage(value)
    }

    const handleAddTicket = () => {
        setAddTicketOpen(true)
    }

    console.log(page)
 
    return (
        <div>
            <Box sx={{ width: '100%', typography: 'body1', mb: 4 }}>
            <CardHeader
                    title={<Typography sx={{ fontSize: '15px' }}>Support Tickets</Typography>}
                    sx={{ background: '#00669e', color: 'white', mb: 1 }}
                    action={
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: '#101847', mt: 0.5, mr:1 }}  
                        onClick={handleAddTicket}
                        color="primary"
                        startIcon={<Icon icon="ei:plus" width="25" height="25" />}
                      >
                        Add Ticket
                      </Button>
                    }
                  />
          
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant='h5' sx={{}}>
                        Tickets
                    </Typography>
                </Box>
            */}
  
                <Box sx={{ mt: 2, p:2 }}>
                {totalRecords > 10 && (
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        size='small'
                                        label='Type Here'
                                        id='outlined-basic'
                                        variant='outlined'
                                        value={searchValue}
                                        placeholder='ID/Category/Priority'
                                        fullWidth
                                        onChange={e => setSearchValue(e.target.value)}
                                        onKeyDown={handleKeyPress}
                                    />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormControl size='small' sx={{ width: '100%' }}>
                                        <InputLabel id='controlled-select-label'>Status</InputLabel>
                                        <Select
                                            value={status}
                                            label='Status'
                                            id='controlled-select'
                                            onChange={e => setStatus(e.target.value)}
                                            labelId='controlled-select-label'
                                        >
                                            <MenuItem value=''>
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value='pending'>Open</MenuItem>
                                            <MenuItem value='hold'>Hold</MenuItem>
                                            <MenuItem value='closed'>Close</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            fullWidth
                                            endIcon={<Icon icon='iconamoon:search-thin' />}
                                            onClick={fetchAllTicket}
                                        >
                                            SEARCH
                                        </Button>
                                        <Button
                                            variant='contained'
                                            sx={{}}
                                            fullWidth
                                            endIcon={<Icon icon='bx:reset' />}
                                            onClick={handleReset}
                                            color='error'
                                        >
                                            RESET
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                         
                            {searchIcon && (
                                <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                                    <div>Filter :</div>

                                    {searchValue && <Chip size='small' label={searchValue} color='primary' />}
                                    {status && <Chip size='small' label={status} color='primary' />}
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                   )}
                {/* <Box sx={{ textAlign: '' }}>
                        <Button
                            variant='contained'
                            sx={{ backgroundColor: '', mt: 2.5 }}
                            onClick={handleAddTicket}
                            color='primary'
                            startIcon={<Icon icon="ei:plus" width="25" height="25" style={{}} />}
                        >
                            Add ticket
                        </Button>
                    </Box> */}
                    {isLoading ? (
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <CircularProgress sx={{ mb: 2 }} />
                        </Box>
                    ) : (
                        <>
                            {allTickets.length > 0 ? (
                                <>
                                    <Box>
                                        {allTickets.map((item, index) => (
                                            <TicketCard item={item} key={index} fetchAllTicket={fetchAllTicket} />
                                        ))}
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                        <Pagination count={pageCount} color='primary' page={page} onChange={handleChange} />
                                    </Box>
                                </>
                            ) : (
                                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <Typography variant='h6' sx={{ mb: 2 }}>No support ticket request found</Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
                  
            </Box>
            <AddTickets setAddTicketOpen={setAddTicketOpen} addTicketOpen={addTicketOpen} fetchAllTicket={fetchAllTicket} />
        </div>
    )
}

export default AllTickets