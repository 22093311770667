import React from 'react';
import { useEffect } from 'react';
import AllTickets from '../../components/tickets/AllTickets';
import {
  Container,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Typography,
} from '@mui/material';
import NavbarLog from '../../components/navbarlogged';
import TempSidebar from '../../components/TempSidebar';
import Footer from '../../components/footer'; import { Helmet } from "react-helmet";
import themeConfig from '../../config/themeConfig';


const TicketPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Tickets - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
      <NavbarLog />
      <Container sx={{ py: 3, mt: 4 }}>
        <Card sx={{ my: 3 }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
            <Grid container spacing={2} sx={{ mb: 2, backgroundColor: '' }}>



              <Grid item xs={12} md={3.2} sx={{}}>
                <TempSidebar />
              </Grid>

              <Grid item xs={12} md={8.8} >
                <Card sx={{ mb: 1, width: "100%" }}>
                  {/* <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Tickets</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} /> */}
                  <CardContent sx={{p:0}}>
                    <AllTickets />
                  </CardContent>
                </Card>

              </Grid>


              {/* <Card sx={{ mb: 1, width: "100%" }}>
                <CardHeader title={<Typography sx={{ fontSize: '15px' }}>My Posts</Typography>} sx={{ background: 'linear-gradient(to right , #101847, #00669e)', color: 'white' }} />
                <CardContent>
                  <AllTickets />
                </CardContent>
              </Card> */}
            </Grid>
          </CardContent>
        </Card>

      </Container>
      <Footer />
    </div>
  )
}

export default TicketPage
