import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';


export default function Star1({ value, onChange }) {
  

  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
    >
     
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
      />
      </Box>
  );
}