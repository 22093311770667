import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    window.localStorage.removeItem('studentID');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
    navigate('/');
    window.location.reload(); // This reloads the page after navigating
  };

  return { handleLogout };
};

export default useLogout;
