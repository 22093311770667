import React from "react";
import { useEffect, useState } from 'react';
import {
    Container, Card,
    CardHeader,
    CardContent,
    Grid, Typography

} from "@mui/material";
import NavbarLog from "../components/navbarlogged";
import Footer from "../components/footer";
import ForumMain from "../components/forum_details/ForumMain";
import YourPost from "../components/forum_details/yourpost/YourPost";
import Common from "../components/common_part";
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';


const YourPostPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <div>
            <Helmet>
                <title>My Posts - {themeConfig.templateName}</title>
                <meta name="description" content="This is the home page of your site." />
            </Helmet>
            <NavbarLog />

            <Container maxWidth="lg" sx={{ mb: 15, mt: 15 }}>
                <Card sx={{ my: 3 }}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap: 1 }}>

                            <Common />

                            <Grid item xs={12} md={8.8} sx={{ mb: 2 }}>

                                <div>
                                    {/* <Card sx={{ mb: 1, width: "100%" }}>
                                        <CardContent>
                                            <YourPost />
                                        </CardContent>
                                    </Card> */}
                                    <Card sx={{ mb: 1, width: "100%" }}>
                                        <CardHeader title={<Typography sx={{ fontSize: '15px' }}>My Posts</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                                        <CardContent>
                                            <YourPost />
                                        </CardContent>
                                    </Card>
                                </div>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Container>

            <Footer />
        </div>
    )
}

export default YourPostPage