import React, { useState, forwardRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Box } from '@mui/material'
import Swal from 'sweetalert2'
// import Icon from 'src/@core/components/icon'
import TodayIcon from '@mui/icons-material/Today';
import TextField from '@mui/material/TextField'
import { CircularProgress, Chip, Card, CardContent, Divider, Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAvatar from '@mui/material/Avatar'
// import CustomAvatar from 'src/@core/components/mui/avatar'
import { getInitials } from '../name'
import { useTheme } from '@mui/material/styles'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImagePreview from './ImagePreview'
import Grid from '@mui/material/Grid';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';

const ChatFormWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: '3px', // specify the border width
    borderColor: theme.palette.divider,
    padding: theme.spacing('5px', 2),
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    maxWidth: '100%'
}))

const CommentPopup = ({ commentOpen, setCommentOpen, isLoadingComments, post, subComments, setSubComments, handleLike, likesArray, handleSendSMS, message, setMessage, handleCommentPage, setSubPage, totalComments }) => {

    const [imageUrl, setImageUrl] = useState([])
    const [imagePreview, setImagePreview] = useState(false)



    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('userID')
    }

    const handleClose = () => {
        setCommentOpen(false)
        setSubComments([])
        setSubPage(0)
    }

    const handleImagePreview = (img) => {
        setImagePreview(true)
        setImageUrl(img)
    }

    const handleKeyPress = event => {
        // Check if the pressed key is Enter (key code 13)
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSendSMS(post?.fro_id)
        }
      }

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' fullWidth maxWidth='md' open={commentOpen}>
                <DialogTitle id='customized-dialog-title' sx={{}}>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{ top: 10, right: 10, position: 'absolute' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box>
                            {post?.type == 'student' ? (
                                <>
                                    {(post?.additionalInfo?.profile) ? (
                                        <MuiAvatar
                                            src={post?.additionalInfo?.profile}
                                            alt={`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`}
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    ) : (
                                        <Avatar
                                            skin='light'
                                            sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                        >
                                            {getInitials(`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`)}
                                        </Avatar>
                                    )}
                                </>
                            ) : post?.type == 'teacher' ? (
                                <>
                                    {(post?.additionalInfo?.profile) ? (
                                        <MuiAvatar
                                            src={post?.additionalInfo?.profile}
                                            alt={`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`}
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    ) : (
                                        <Avatar
                                            skin='light'
                                            sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                        >
                                            {getInitials(`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`)}
                                        </Avatar>
                                    )}
                                </>
                            ) : (
                                <MuiAvatar
                                    src='/images/et_logo.png'
                                    alt='admin'
                                    sx={{ width: 40, height: 40 }}
                                />
                            )}

                        </Box>
                        <Box>
                            {post?.type == 'student' && (
                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>{post.additionalInfo ? post.additionalInfo?.stu_fname : ''} {post.additionalInfo ? post.additionalInfo?.stu_lname : ''} - ({post.type == 'student' ? 'S' : 'T'})</Typography>
                            )}
                            {post?.type == 'teacher' && (
                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>{post.additionalInfo ? post.additionalInfo?.t_fname : ''} {post.additionalInfo ? post.additionalInfo?.t_lname : ''} - ({post.type == 'student' ? 'S' : 'T'})</Typography>
                            )}
                            {post?.type == 'admin' && (
                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>EnglishTeacher.lk</Typography>
                            )}
                        </Box>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    {post?.text && (
                        <Box dangerouslySetInnerHTML={{ __html: post.text }}></Box>
                    )}

                    {post?.img.length == 1 ? (
                        <Box sx={{ width: '100%', paddingBottom: '56.25%', mt: 1, borderRadius: 0.5, backgroundColor: '#dbdbdb', position: 'relative', overflow: 'hidden', cursor: 'pointer' }} onClick={() => handleImagePreview(post?.img)}>

                            <img
                                src={post?.img[0]}
                                alt='post'
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'contain', borderRadius: 0.5 }} // Ensure image fills the container
                            />
                        </Box>
                    ) : post?.img.length > 1 ? (

                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Grid container rowSpacing={1} columnSpacing={2}>
                                {post?.img.map((item, index) => (
                                    <Grid item xs={12} sm={6} key={index} onClick={() => handleImagePreview(post?.img)}>
                                        <img

                                            src={item}
                                            alt={index}
                                            loading="lazy"
                                            style={{ objectFit: 'cover', width: '100%', height: 200, cursor: 'pointer' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ) : null}



                    {post?.url2 && (
                        <Box className="youtube-container" sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000', mt: 1 }}>
                            <iframe
                                title="YouTube video"
                                src={post.url2}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            />
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1, mt: 1.5 }}>
                        <Chip icon={<ChatBubbleOutlineOutlinedIcon style={{ color: 'black' }} />} label={post?.comments} />
                        <Chip icon={likesArray.some(item => item.fro_id === post?.fro_id) ? <FavoriteIcon color='error' /> : <FavoriteBorderOutlinedIcon />} label={post?.det_like} onClick={() => handleLike(post)} />
                    </Box>

                    <ChatFormWrapper>
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={6}
                                value={message}
                                size='small'
                                placeholder='Type your comment here…'
                                onChange={e => setMessage(e.target.value)}
                                sx={{ '& .MuiOutlinedInput-input': { pl: 0 }, '& fieldset': { border: '0 !important' } }}
                                onKeyDown={handleKeyPress}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton aria-label='sendsms'
                                onClick={() => handleSendSMS(post?.fro_id)} disabled={!message}
                            >

                                <SendIcon />
                            </IconButton>
                        </Box>
                    </ChatFormWrapper>

                    {subComments.length > 0 && (
                        <Box sx={{ backgroundColor: '', pb: 1 }}>
                            {subComments.map((comments, index) => (
                                <Box key={index}>
                                    {comments.comment && (
                                        <Box sx={{ mt: 2 }}>
                                            <Card sx={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}>
                                                <CardContent sx={{ px: 1, pt: 1, m: 0, paddingBottom: '15px !important' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Box>
                                                            {comments?.type == 'student' ? (
                                                                <>
                                                                    {(comments?.userDetails?.profile) ? (
                                                                        <MuiAvatar
                                                                            src={comments?.userDetails?.profile}
                                                                            alt={`${comments.userDetails?.stu_fname} ${comments.userDetails?.stu_lname}`}
                                                                            sx={{ width: 30, height: 30 }}
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            skin='light'
                                                                            sx={{ width: 30, height: 30, fontSize: '1rem', textTransform: 'uppercase' }}
                                                                        >
                                                                            {getInitials(`${comments.userDetails?.stu_fname} ${comments.userDetails?.stu_lname}`)}
                                                                        </Avatar>
                                                                    )}
                                                                </>
                                                            ) : comments?.type == 'teacher' ? (
                                                                <>
                                                                    {(comments?.userDetails?.profile) ? (
                                                                        <MuiAvatar
                                                                            src={comments?.userDetails?.profile}
                                                                            alt={`${comments.userDetails?.t_fname} ${comments.userDetails?.t_lname}`}
                                                                            sx={{ width: 30, height: 30 }}
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            skin='light'
                                                                            sx={{ width: 30, height: 30, fontSize: '1rem', textTransform: 'uppercase' }}
                                                                        >
                                                                            {getInitials(`${comments.userDetails?.t_fname} ${comments.userDetails?.t_lname}`)}
                                                                        </Avatar>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <MuiAvatar
                                                                    src='/images/et_logo.png'
                                                                    alt='admin'
                                                                    sx={{ width: 30, height: 30 }}
                                                                />
                                                            )}

                                                        </Box>
                                                        <Box>
                                                            {comments?.type == 'student' && (
                                                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>{comments.userDetails ? comments.userDetails?.stu_fname : ''} {comments.userDetails ? comments.userDetails?.stu_lname : ''} - ({comments.type == 'student' ? 'S' : 'T'})</Typography>
                                                            )}
                                                            {comments?.type == 'teacher' && (
                                                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>{comments.userDetails ? comments.userDetails?.t_fname : ''} {comments.userDetails ? comments.userDetails?.t_lname : ''} - ({comments.type == 'student' ? 'S' : 'T'})</Typography>
                                                            )}
                                                            {comments?.type == 'admin' && (
                                                                <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>EnglishTeacher.lk</Typography>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                    <Typography sx={{ mt: 0.5, pr: 2, pl: 5 }}>{comments.comment}</Typography>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    )}
                                </Box>
                            ))}

                        </Box>
                    )}

                    {isLoadingComments && (
                        <Box sx={{ mt: 6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <CircularProgress sx={{ mb: 4 }} />
                        </Box>
                    )}

                    {(totalComments != subComments.length) && !isLoadingComments && totalComments.length !== 0 && (
                        <Button variant="contained" size='small' startIcon={<TodayIcon />} onClick={() => handleCommentPage()}>
                            view more comments
                        </Button>
                    )}

                </DialogContent>
            </Dialog>
            <ImagePreview imagePreview={imagePreview} setImagePreview={setImagePreview} imgUrls={imageUrl} />
        </>
    )
}

export default CommentPopup