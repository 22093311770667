import React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import CloseIcon from "@mui/icons-material/Close";

// ** Icon Imports
// import Icon from 'src/@core/components/icon'
import TodayIcon from '@mui/icons-material/Today';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ImagePreview = ({ imgUrls, imagePreview, setImagePreview }) => {

    const handleClose = () => {
        setImagePreview(false)
    }

    return (
        <div>
            <Dialog onClose={handleClose} open={imagePreview} fullScreen sx={{ p: 0, m: 0 }}>
                <DialogContent sx={{ p: 0, backgroundColor: '', position: 'relative', height:'100vh' }}>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: 'gray',
                            ":hover": { backgroundColor: 'gray' },
                            zIndex: 2,
                        }}
                    >
                        
                        <CloseIcon/>
                    </IconButton>


                    <Box sx={{height: '100%'}}>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 1 }}
                            loop={true}
                            scrollbar={{ draggable: true }}

                            // onSlideChange={() => console.log('slide change')}
                            // onSwiper={(swiper) => console.log(swiper)}
                            style={{ backgroundColor: 'black', height: '100%' }}
                        >
                            {imgUrls.map((img, index) => (
                                <SwiperSlide key={index}>
                                    <img src={img} alt='swiper 3' style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
                                </SwiperSlide>
                            ))}
                            {/* Add more SwiperSlide components as needed */}
                        </Swiper>

                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ImagePreview