import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Box, CircularProgress, Alert, AlertTitle, } from "@mui/material";
import Swal from 'sweetalert2'
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';

const EmailUnsubscribe = () => {
    const [logo, setLogo] = useState('')
    const [loading, setLoading] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    const [email, setEmail] = useState('')
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    console.log(searchParams.get(email));

    // useEffect(() => {
    //     const parseemail = JSON.parse(searchParams.get('email'))
    //     setEmail(parseemail);
    //   }, [searchParams]);

    useEffect(() => {
        fetchSystemSetting();
    }, []);


    
    const fetchSystemSetting = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            if (response.status == 200) {
                const data = await response.json()
                setLogo(data.result.logo)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleUnsubscribe = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/email-unsubscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                })
            })
            const data = await response.json()
            if (response.status == 200) {
                setLoading(false)
                setButtonDisable(true)
                Swal.fire({
                    title: `${data.message}`,
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    customClass: {
                        container: 'custom-swal-container'
                    }
                })
            } else {
                setLoading(false)
                Swal.fire({
                    title: `${data.message}`,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    customClass: {
                        container: 'custom-swal-container' 
                    }
                })
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <div>
             <Helmet>
             <title>Email Unsubscribe - {themeConfig.templateName}</title>
                </Helmet>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', px: 2 }}>
                <Box>

                    <Card variant="outlined" sx={{ boxShadow: 2, }}>
                        <CardContent sx={{ maxWidth: 400, p: 3, boxShadow: 3 }}>
                            <Box sx={{ textAlign: 'center', mb: 6, }}>
                                <img
                                    src={logo ? logo : ''}
                                    alt='English teacher'
                                    style={{ width: '200px', height: 'auto' }}
                                />

                            </Box>
                            <Typography variant="h6" color="primary" gutterBottom sx={{ textAlign: 'center' }}>
                                Email: {email}
                            </Typography>

                            <Alert
                                severity="warning"
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#FFF4E5',
                                    color: '#8B4500',
                                    border: '1px solid #FFB74D',
                                    p: 1
                                }}
                            >
                                <AlertTitle><strong>Important Notice</strong></AlertTitle>
                                By clicking <strong>"Unsubscribe"</strong>, you will unsubscribe from all emails except for Forgot Password notifications.
                            </Alert>

                            <Box sx={{ textAlign: 'center' }}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 4, px: 3, py: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
                                    onClick={handleUnsubscribe}
                                    startIcon={loading ? (
                                        <CircularProgress size={20} />
                                    ) : null}
                                    disabled={loading || buttonDisable}
                                >
                                    Unsubscribe
                                </Button>
                            </Box>
                        </CardContent>

                    </Card>
                </Box>
            </Box>

        </div>
    )
}



export default EmailUnsubscribe