import React from 'react';
import {
  Container,
  Card,
  CardContent, 
  Grid, 
  Avatar, 
  Typography, 

} from '@mui/material';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import TeacherCard from '../components/teachercard';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';
import { useLocation } from 'react-router-dom';



function MyTeachers() {

  const [stu_Data, setStu_Data] = useState({});
  const studentID = localStorage.getItem('studentID');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 
  

  return (
    <div className="home-page">
      <Helmet>
        <title>My Teachers - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                  <Hidden smDown>
                    <Common />
                  </Hidden>

                <Grid xs={12} md={8.8} sx={{mb:2}}>
                <TeacherCard/>

                </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
        <Footer/>
    </div>
  );
}

    
    export default MyTeachers;