import { CircularProgress, Typography, Chip, Divider, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react/dist/iconify.js'
import MessageCard from './MessageCard'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert';
import useLogout from '../../hooks/logouthook'

const OneTicketView = ({ id }) => {
  const [OneTicket, setOneTicket] = useState(null)
  const [allMessages, setAllMessages] = useState([])
  const [message, setMessage] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const navigate = useNavigate();
  const { handleLogout } = useLogout();


  let adminId = ''
  if (typeof localStorage !== 'undefined') {
    adminId = window.localStorage.getItem('studentID')
  }

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  useEffect(() => {
    fetchOneTicket()
    fetchTicketDetails()
  }, [id])

  const fetchOneTicket = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/one-ticket-header`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          id: id
        })
      })
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchOneTicket()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      }
      const data = await response.json()
      if (response.status === 200) {
        setOneTicket(data.result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTicketDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/one-ticket-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          id: id
        })
      })
      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchTicketDetails()
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      }
      const data = await response.json()
      if (response.status === 200) {
        setAllMessages(data.result)

      }
    } catch (error) {
      console.error(error)
    }
  }

  const getStatusColor = priority => {
    return priority === 'immediate'
      ? '#D52D00'
      : priority === 'high'
        ? '#DA5301'
        : priority === 'medium'
          ? '#FFA300'
          : priority === 'low'
            ? '#FFBE64'
            : null
  }

  const getStatusName = priority => {
    return priority === 'immediate'
      ? 'Immediate'
      : priority === 'high'
        ? 'High'
        : priority === 'medium'
          ? 'Medium'
          : priority === 'low'
            ? 'Low'
            : null
  }

  const getCurrentStatus = priority => {
    return priority === 'pending'
      ? '#FFA500'
      : priority === 'closed'
        ? '#DC3545'
        : priority === 'hold'
          ? '#007BFF'
          : priority === 'ongoing'
          ? '#28A745'
          : null
  }

  const handleInputImageChange = async event => {
    setSelectedFile(event.target.files[0])
    event.target.value = ''
  }

  const handleSendSMS = async () => {
    try {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('message', message)
      formData.append('id', id)
      formData.append('adminId', adminId)


      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/send-message`, {
        method: 'POST',
        body: formData
      })

      if (response.status === 201) {
        removeuploadfile()
        document.getElementById('account-settings-upload-image').value = ''
        setSelectedFile(null)
        setMessage('')
        fetchTicketDetails()
        

      } else {
        console.error('Failed to upload file. Server returned:', response.status, response.statusText)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleRefresh = () => {
    fetchOneTicket()
    fetchTicketDetails()
  }

  const handleTicketList = () => {
    navigate(`/ticket`)
  }


  const handleClose = async () => {
    Swal.fire({
      title: `Close ticket ${id}`,
      text: 'Are you sure you want to close this ticket?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        container: 'custom-swal-container' // Add a custom class to the container
      }
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/ticket-close`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: id,
              adminId: adminId
            })
          })

          const data = await response.json()
          if (response.status === 200) {
            Swal.fire({
              title: 'Confirmed!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
              }
            })
            handleRefresh()
          }
        } catch (error) {
          console.error(error)
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
      }
    })
  }

  const handleReopen = async () => {

    Swal.fire({
      title: `Reopen ticket ${id}`,
      text: 'Are you sure you want to reopen this ticket?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      customClass: {
        container: 'custom-swal-container' // Add a custom class to the container
      }
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/ticket-reopen`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              id: id,
              adminId: adminId
            })
          })

          if (response.status == 403 || response.status == 401) {

            let refreshToken = ''
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken')
            }

            try {
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  refreshToken: refreshToken
                })
              })
              if (tokenResponse.status == 200) {
                const tokendata = await tokenResponse.json()
                window.localStorage.setItem('token', tokendata.accessToken)
                token = tokendata.accessToken
                handleReopen()
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error(error)
            }
          }

          const data = await response.json();
          if (response.status === 200) {
            Swal.fire({
              title: 'Confirmed!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
              }
            })
            handleRefresh()
          }
        } catch (error) {
          console.error(error)
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
      }
    })

  }

  const truncateText = (text) => {
    if (text.length <= 20) {
      return text;
    }

    return text.slice(0, 15) + '...';
  };

  const removeuploadfile = () => {
    setSelectedFile('')
  }

  return (
    <div>
      <Card>
        <CardContent>

          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography variant='h4' sx={{ fontWeight: 400 }}>
                {id}.
              </Typography>
              <Typography variant='h4' sx={{ fontWeight: 600, textTransform: 'capitalize' }}>
                {OneTicket ? OneTicket.subject : ''}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              justifyContent: { xs: 'normal', md: 'space-between' },
              mt: 2,
              gap: 2
            }}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Chip
                label={getStatusName(OneTicket?.priority)}
                size='small'
                sx={{
                  backgroundColor: getStatusColor(OneTicket?.priority),
                  color: '#ffffff',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  '& .MuiChip-label': {
                    textOverflow: 'clip'
                  }
                }}
              />
              <Chip
                label={OneTicket?.status}
                size='small'
                sx={{
                  backgroundColor: getCurrentStatus(OneTicket?.status),
                  color: '#ffffff',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  '& .MuiChip-label': {
                    textOverflow: 'clip'
                  },
                  textTransform: 'capitalize'
                }}
              />
              {(OneTicket?.close_comment == 'reopened ticket by admin' || OneTicket?.close_comment == 'reopened ticket by student') && (
                <Chip
                  label={'Reopened'}
                  size='small'
                  sx={{
                    backgroundColor: '#7F889B',
                    color: '#ffffff',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    '& .MuiChip-label': {
                      textOverflow: 'clip'
                    },
                    cursor: 'pointer'
                  }}
                />
              )}
            </Box>
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: { xs: 'flex-start', md: 'flex-end' } }}
            >
              <Button
                variant='contained'
                size='small'
                sx={{ backgroundColor: '#7F889B', ':hover': { backgroundColor: '#7F889B' } }}
                startIcon={<Icon icon='material-symbols:refresh' />}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
              <Button
                variant='contained'
                size='small'
                startIcon={<Icon icon='ion:play-back-outline' />}
                onClick={handleTicketList}
              >
                Ticket List
              </Button>
              {OneTicket?.status !== 'closed' && (
                <Button
                  variant='contained'
                  size='small'
                  sx={{ backgroundColor: '#17A2B8', ':hover': { backgroundColor: '#17A2B8' } }}
                  startIcon={<Icon icon='mdi:send' />}
                  onClick={handleClose}
                >
                  Close
                </Button>
              )}
              {OneTicket?.status == 'closed' && (
                <Button
                  variant='contained'
                  size='small'
                  sx={{ backgroundColor: '#17A2B8', ':hover': { backgroundColor: '#17A2B8' } }}
                  startIcon={<Icon icon='mdi:send' />}
                  onClick={handleReopen}
                >
                  Reopen Ticket
                </Button>
              )}

            </Box>
          </Box>

          {OneTicket?.status !== 'closed' && (
            <Card sx={{ mt: 4 }}>
              <CardContent>
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  label='Message'
                  placeholder='Type your message...'
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  sx={{ '& .MuiOutlinedInput-root': { alignItems: 'baseline', whiteSpace: 'pre-line' }, mb: 2 }}
                />
                {/* <Typography>Attachment : </Typography> */}
                {/* <div>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={handleInputImageChange}
                    id='account-settings-upload-image'
                  />
                </div> */}
                <IconButton
                  size='small'
                  component='label'
                  htmlFor='account-settings-upload-image'
                  sx={{ color: 'text.primary', fontSize: '1rem', fontWeight: 600, }}
                >
                  Attachment : <Icon icon='mdi:attachment' fontSize='1.375rem' />
                  <input hidden type='file' onChange={handleInputImageChange}  id='account-settings-upload-image' />
                </IconButton>
                {selectedFile && (
                  <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto', }}>
                    <Box sx={{ p: 1, color: selectedFile ? '' : 'text.disabled', backgroundColor: '#b1dbf1', height: 30 }}>
                      {selectedFile ? truncateText(selectedFile.name) : ''}
                    </Box>
                    <Box aria-label='sendsms' sx={{ backgroundColor: '#b1dbf1', display: 'flex', justifyContent: 'center', alignItems: "center", height: 30, cursor: 'pointer' }} onClick={removeuploadfile}>
                      <Icon icon='iconamoon:close-fill' />
                    </Box>
                  </Box>
                )}

                {OneTicket?.note && (
                  <Alert icon={false} severity='warning' sx={{ mt: 2 }}>
                    <AlertTitle sx={{ fontWeight: 600, mb: theme => `${theme.spacing(1)} !important` }}>
                      {OneTicket ? OneTicket.note : ''}
                    </AlertTitle>
                  </Alert>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    variant='contained'
                    sx={{ mt: 4 }}
                    startIcon={<Icon icon='mdi:send' />}
                    onClick={handleSendSMS}
                    disabled={!message && !selectedFile}
                  >
                    Send
                  </Button>
                </Box>
              </CardContent>
            </Card>
          )}


          <Box>
            {allMessages.map((item, index) => (
              <MessageCard item={item} key={index} />
            ))}
          </Box>
        </CardContent>
      </Card>

    </div>
  )
}

export default OneTicketView