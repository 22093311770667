
import React from 'react';
import {
Container,
Box,
Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NavbarLog from '../components/navbarlogged';
import Message from '../components/message/Message';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Icon } from "@iconify/react";
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';
import Footer from '../components/footer';


function Messages() {

return (
<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<Box>
<Helmet>
        <title>Messages - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
<NavbarLog />
</Box>
<Container maxWidth="lg" sx={{ mt: 10, flexGrow: 1 }} >

    <Message />

  </Container>

  <Footer/>

</Box>
);
}

export default Messages;