import React from 'react';
import {  Grid, Typography, MenuItem, Box  } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {Link}  from 'react-router-dom';
import { Icon } from "@iconify/react";


{/* <span style={{marginLeft: 5}}></span> */}

function Footer() {
  return (
    <footer style={{
      background: 'linear-gradient(to right , #101847, #00669e)',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      position: 'fixed',
      bottom: '0',
      width: '100%',
      padding: '1rem',
      zIndex:6
    }}>
      <Grid container sx={{
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '13px',
      }}>

        <Grid item xs={12} md='auto' sx={{
          // display: 'flex',
          display: { xs: 'none', md: 'flex' },
          justifyContent: { xs: 'center', md: 'flex-start' },
          alignItems: 'center', 
        }}>
          <Link to={"/privacy_policy"} target='_blank' style={{ color: '#fff', marginRight: '0.5rem',textDecoration:'none'}}>Privacy Policy </Link>
          <Typography sx={{color: '#fff', marginRight: '0.5rem'}}>|</Typography>
          <Link to={"/T_C"} target='_blank' style={{ color: '#fff',textDecoration:'none' }}>T & C</Link>
        </Grid>

        <Grid item xs={12} md='auto' sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center', 
          textAlign: 'center',
          gap: '0.5rem',
        }}>
          <p style={{ margin: 0 }}>
            Copyright © All Rights Reserved By AVIC MEDIA SOLUTIONS (PVT) LTD | Made In
          </p>
          <img
            src="/images/slflag-emoji.png"
            alt="flag"
            style={{ width: '1.2rem', height: 'auto', verticalAlign: 'middle' }}
          />
          <p style={{ margin: 0 }}>With</p>
          <img
            src="/images/heart-emoji.png"
            alt="heart"
            style={{ width: '1.2rem', height: 'auto', verticalAlign: 'middle' }}
          />
          <p style={{ margin: 0 }}>| Platform By Spark</p>
        </Grid>

        <Grid item xs={12} md='auto' sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end' },
          alignItems: 'center', 
          gap: '0.5rem',
        }}>
          <a href="https://www.youtube.com/channel/UCtvYTYGNKlLCYJLcrlsN9xQ" target='_blank' style={{ color: '#fff' }}>
            <YouTubeIcon />
          </a>
          <a href="https://www.tiktok.com/@englishteacher.lk" target='_blank' style={{ color: '#fff' }}>
          <Icon icon="ant-design:tik-tok-filled" width="1.4rem" height="1.4rem" />
          </a>
          <a href="https://www.facebook.com/www.EnglishTeacher.lk/" target='_blank' style={{ color: '#fff'  }}>
            <FacebookIcon />
          </a>
          
        </Grid>

      
      </Grid>
    </footer>
  );
}
export default Footer;