import React from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardContent, 
  Grid, 
  Avatar, 
  Typography,
  Box,
  Stack,
  Paper,
  Badge,
  Button, 
  TextField

} from '@mui/material';
import {styled} from '@mui/material/styles';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
// import SideMenu from '../components/sidemenu';
import { useEffect, useState } from 'react';
import BillingHistoryTable from '../components/billing_history_table';
import { useNavigate, useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';





const Item = styled(Paper)(({theme})=>({
    backgroundColor: theme.palette.mode === 'dark'? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));
  
  
function BillingHistory() {

  const studentID = localStorage.getItem('studentID');
  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="home-page">
      <Helmet>
        <title>Billing History - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                  <Hidden smDown>
                    <Common />
                  </Hidden>

                  
                <Grid xs={12} md={8.8} sx={{mb:2}}>

                <div>
                <Card sx={{ mb: 1, width: "100%" }}>
                  {/* <CardHeader title={<Typography sx={{display:'flex', justifyContent:'center', fontSize:20}}>Billing History</Typography>} /> */}
                  <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Billing History</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                    <CardContent>
                      <BillingHistoryTable/>
                    </CardContent>
                  </Card>
                    </div>
                
                </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
      <Footer/>
    </div>
  );
}

    
    export default BillingHistory;