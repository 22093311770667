import React from 'react'
import { Card, CardContent, Typography, Box, Select, TextField, Tooltip, Stack, Avatar, Rating, Button } from '@mui/material';
import { getInitials } from '../name';
import useCurrencyMark from '../../hooks/currencyMark';
import { useNavigate } from 'react-router-dom';

const RandomTeachers = ({ teacher }) => {
   
    const navigate = useNavigate();
    const {currency} = useCurrencyMark()

    const fullName = () => {
        const cfullname = teacher?.t_fname + ' ' + teacher?.t_lname

        return cfullname
    }

    const handleTeacherProfile = (item) => {
        // Navigate to the dynamic URL with the teacher's name and ID
        navigate(`/English_Teacher_${item.t_id}-${item.t_fname}.html`);
        };
      

    return (
        <div>
           
            <Card  onClick={() => handleTeacherProfile(teacher)} sx={{width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px', cursor:'pointer' }}>
                <CardContent sx={{ position: 'relative', padding: '0px !important' }}>

                    <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '120px',
                        background: "rgb(0,102,158)",
                        background: "linear-gradient(90deg, rgba(0,102,158,1) 0%, rgba(8,61,112,1) 53%, rgba(16,24,71,1) 100%)",
                        top: 0,
                        zIndex: 1,
                    }}>
                    </Box>

                    <Box sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 6,
                        position: 'relative', // Ensure content is above the background
                        zIndex: 2, // Ensure the content is on top of the background
                        pt: 5
                    }}>
                        {teacher?.profile ? (
                            <>
                                <Avatar
                                    alt='Remy Sharp'
                                    src={teacher?.profile}
                                    sx={{ width: 150, height: 150, cursor: 'pointer' }}

                                />
                            </>
                        ) : (
                            <>
                                <Avatar
                                    alt='Remy Sharp'
                                    src='/images/avatars/1.png'
                                    sx={{ width: 150, height: 150, fontSize: 50, cursor: 'pointer' }}
                                >
                                    {getInitials(fullName())}
                                </Avatar>
                            </>
                        )}
                    </Box>

                    <Typography
                        sx={{ textTransform: 'capitalize', textAlign: 'center', fontSize: 22, fontWeight: 600, color: '' }}
                    >
                        {fullName()}
                    </Typography>

                    <Box sx={{ textAlign: 'center' }}>
                        <Rating
                            name='read-only'
                            value={teacher?.rate}
                            precision={0.5}
                            readOnly
                            sx={{
                                '& .MuiRating-iconEmpty': {
                                    color: '#dfb45f' // Change the empty star color
                                }
                            }}
                        />
                    </Box>

                    <Box sx={{ textAlign: 'center' }}>
                        <Typography>{currency} {teacher?.t_hourrate?.toFixed(2)}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="small"
                            sx={{
                                color: "white",
                                backgroundColor: "#0d2353",
                                borderRadius: '8px',
                                padding: '10px 20px',

                            }}
                        onClick={() => handleTeacherProfile(teacher)}
                        >
                            Book A Class
                        </Button>
                    </Box>
                </CardContent>


            </Card>
        </div>
    )
}

export default RandomTeachers