import React from 'react';
import {
  Container,
  Card,
  CardContent, 
  Grid, 
  Avatar, 
  Typography, 

} from '@mui/material';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
// import SideMenu from '../components/sidemenu';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import UnpaidCard1 from '../components/unpaidcard';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';


function UnpaidClasses() {

  const [stu_Data, setStu_Data] = useState({});
  const studentID = localStorage.getItem('studentID');
  // console.log(stu_Data)
  
  
  // useEffect(() => {
  //   if (studentID) { 
  //     fetchStu_Data();
  //   }
  // }, [studentID]);
  
  // const fetchStu_Data = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         studentID

  //       }),
  //       headers: {
  //         'Content-Type': 'application/json'
  //     }
        
       
  //     });
  //     if (!response.ok) {
  //       throw new Error(`HTTP error: Status ${response.status}`);
  //     }
      
      
  //     let getData = await response.json();

  //     setStu_Data(getData[0])
  //   } catch (err) {
      
  //   } 
  // };

  

  return (
    <div className="home-page">
      <Helmet>
        <title>Unpaid Classes - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                  {/* <Grid xs={12} md={3} sx={{mb:2}}>
                    <Grid item  sx={{ background: 'linear-gradient(to right , #101847, #00669e)',py:4,borderRadius: 2,boxShadow: 2, flexDirection: 'column',display: 'flex', alignItems: 'center'}}>
                      <Avatar alt="User Picture" src={stu_Data.profile} sx={{ my: 2,width: 150, height: 150 }} />

                      <Link to={`/profile_setting`} style={{ textDecoration: 'none', color: 'white' }}>
                      <Typography variant="body2" sx={{ fontSize:'18px',mb:2}} style={{color:'white'}}>{stu_Data.stu_fname} {stu_Data.stu_lname}</Typography>
                      </Link>
                    </Grid>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2}}>
                     <SideMenu/>
                    </div>
                  </Grid> */}
                  <Common/>

                  <Grid xs={12} md={8.8} sx={{mb:2}}>
                    <UnpaidCard1/>
                  </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
        <Footer/>
    </div>
  );
}

    
    export default UnpaidClasses;