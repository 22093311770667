import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Box,
  Stack,
  Paper,
  Badge,
  Button
} from '@mui/material';
import {styled} from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import CircularIndeterminate from '../components/loading';
import { useNavigate } from 'react-router-dom';
import useLogout from '../hooks/logouthook';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


const Item = styled(Paper)(({theme})=>({
  backgroundColor: theme.palette.mode === 'dark'? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function PendingApprovalCard1() {
  const [classData, setClassData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const studentID = localStorage.getItem('studentID');
  const [page, setPage] = useState(0);
  const itemsPerPage = 6;
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate('');
  const {handleLogout} = useLogout()

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleViewAll = () => {
  //   navigate('/pendingApproval_class');
  // };


  useEffect(() => {
    fetchClassData(page);
}, [studentID, page]);

const fetchClassData = async (page) => {
  setLoading(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/PendingApprovals_class`, {
      method: 'POST',
      body: JSON.stringify({ 
        studentID,
        page,
        itemsPerPage
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, 
      }
    });
    if (response.status === 403 || response.status === 401) {
      let refreshToken = '';
      if (typeof localStorage !== 'undefined') {
        refreshToken = window.localStorage.getItem('refreshToken');
      }
  
      try {
        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        });
  
        if (tokenResponse.status === 200) {
          const tokendata = await tokenResponse.json();
          window.localStorage.setItem('token', tokendata.accessToken); 
          token = tokendata.accessToken;
  
  
          return fetchClassData(page);
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        handleLogout(); 
      }
  
      return; 
    }
  

    if (!response.ok) {
      throw new Error(`HTTP error: Status ${response.status}`);
    }

    const getData = await response.json();
    setClassData(getData.data); 
    setTotalItems(getData.totalItems);
    setLoading(false);
  } catch (err) {
    console.error('Failed to fetch data', err);
    setClassData([]); 
  }
};

const handleNavigate = (item) => {
  // Navigate to the dynamic URL with the teacher's name and ID
  navigate(`/English_Teacher_${item.t_id}-${item.t_fname}.html`);
  };

  
  const handlehome = () => {
    navigate('/')
  }


return (
  <div>
    <Card sx={{ mb: 1 }}>
      <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Pending Approvals</Typography>} sx={{ background: '#00669e', color: 'white', mb:1}}/>
      <CardContent>
      {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
           <CircularIndeterminate />
         </Box>
      ) : (
        <Grid container sx={{ flexGrow: 1 }}>
          {classData.length === 0 ? (
            
            <>
            <Typography 
              sx={{ mx: 'auto', display: 'flex', alignItems: 'center', color: '#c0c0c0', mb:2 }}>
              <ErrorOutlineIcon /> There are no pending approvals
            </Typography>
           
           <Grid container direction="column" justifyContent="center" alignItems="center" sx={{backgroundColor:"#dbe9f2b8", borderRadius:2, py:2,  boxShadow: "inset 0px 0px 2px #10184724;", cursor: "pointer" }}>
           <ControlPointIcon sx={{ fontSize: 50, mb: 1, color:"#00669eab"  }} onClick={handlehome} />
           <Typography onClick={handlehome} sx={{ justifyContent: "center", textAlign: 'center', color:"#00669eab" }}>Book A Class</Typography>
         </Grid>
         </>
          ) : (
            classData.map((item) => (
              <Grid item key={item.ssch_id} xs={12} sm={5.9} lg={3.7} sx={{ py: 2, borderRadius: 2, boxShadow: 3, mr:2, mb:2}}>
                <Paper elevation={24} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>

                  <Avatar alt="User Picture" onClick={()=>handleNavigate(item)} src={item.profile} sx={{  width: 100, height: 100, border: 1, mt:2, cursor: "pointer" }} />
                 
                
                    <Typography variant="body2" onClick={()=>handleNavigate(item)} sx={{ fontSize: '18px', mb: 2, cursor: "pointer" }}>{item.t_fname} {item.t_lname}</Typography>
                
                  
                  <Stack direction="row" spacing={1 }>
                    <Chip label="Pending Approval" color="warning" sx={{height:18, fontSize:11, backgroundColor:"#ffa500", color: 'white'}} />
                    {item.reschedule === 1 && (
                    <Chip label="Rescheduled"   sx={{ height: 18, fontSize:11, backgroundColor: '#808080', color: 'white'}} />
                    )}
                  </Stack>

                  <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                  <Grid item sx={{ mb: 1 }}>
                    <Typography variant="body1">Class ID : {item.ssch_id}</Typography>
                  </Grid>
                  </Grid>

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', background: 'linear-gradient(to right , #101847, #00669e)', p:1, mb:3}}>
                    <Stack alignItems="center">
                      <Stack direction="row" alignItems="center">
                        <Typography style={{color:'white', mt:2}}>{item.start_time} - {item.end_time}</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography style={{color:'white'}}>{new Date(item.schedule_date).toISOString().substring(0, 10)}</Typography>
                      </Stack>
                    </Stack>
                  </Box>

                </div>
              </Grid>
            ))
          )}
        </Grid>
        )}
        {totalItems > 3 && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={itemsPerPage}
            rowsPerPageOptions={[]}
          />
           {/* <Button variant="contained" size='small' onClick={handleViewAll} sx={{mr:2, backgroundColor: "#0d2353"}}>
            View All
          </Button> */}
        </Box>
      )}
      </CardContent>
    </Card>
  </div>
);
}

export default PendingApprovalCard1;