import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';

Star.propTypes = {
  value: PropTypes.number.isRequired
};



export default function Star({value}) {
  

  return (
    <Box
      sx={{
        '& > legend': { mt: 1 },
      }}
    >
      
      
      <Rating name="read-only" value={value || 0} readOnly />
      
    </Box>
  )
}
