import React from 'react';
import {
  Container,
  Card,
  CardContent, 
  CardHeader,
  Grid,
  Button, 
  Avatar, 
  Typography, 

} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, MenuItem,Box, Select,TextField, Tooltip, Stack} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Navbar from '../components/navbar';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import TeacherCard from '../components/teachercard';
import Star from '../components/star';
import { Link } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useNavigate } from 'react-router-dom';
import ForumMain from '../components/common_forum/ForumMain';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';



function Forum() {

  const [stu_Data, setStu_Data] = useState({});
  const studentID = localStorage.getItem('studentID');
  const [openAddForumModal,setOpenAddForumModal] = useState(false);
  const navigate = useNavigate();

 
  return (
    <div className="home-page">
      <Helmet>
        <title>Community - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        {localStorage.getItem('studentID') ? <NavbarLog/> : <Navbar/>}
        <Container maxWidth="lg" sx={{mb: 15, mt:13}}  >
          <ForumMain />

        </Container>
        
        <Footer/>
    </div>
  );

}
    
    export default Forum;