// ** React Imports
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Next Import
import { Link, useSearchParams } from 'react-router-dom'

// ** MUI Components
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import useMediaQuery from '@mui/material/useMediaQuery'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide';
import { FormHelperText } from '@mui/material'
import Swal from 'sweetalert2'

// ** Icon Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// ** Configs
import themeConfig from '../config/themeConfig'



import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'


const RightWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 400
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 500
  }
}))

const BoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const MaskImg = styled('img')(({ theme }) => ({
  zIndex: -1,
  objectFit: 'cover',
  width: '100%',
  position: 'absolute',
  minHeight: '100vh'
}))


const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.18px',
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }
}))

const schema = yup.object().shape({
  newpassword: yup.string().required('Password is required'),
  confirrmpassword: yup.string().required('Confirm Password is required')
    .oneOf([yup.ref('newpassword'), null], 'Passwords must match'),
})

const ResetPassword = () => {
  // ** States
  const [values, setValues] = useState({
   
    showNewPassword: false,
   


    showConfirmNewPassword: false
  });
 

  const [systemSetting, setSystemSetting] = useState(null);

  const navigate = useNavigate('');

  // ** Hooks
  const theme = useTheme()

  // ** Vars

  const hidden = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    // fetchSystemSetting()
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  // const fetchSystemSetting = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //     })
  //     if (response.status == 200) {
  //       const data = await response.json()
  //       setSystemSetting(data.result)
  //     }
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  const {

    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    // defaultValues,
    resolver: yupResolver(schema)
  })

  // Handle New Password
  const handleNewPasswordChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword })
  }

  // Handle Confirm New Password
  const handleConfirmNewPasswordChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword })
  }


  const onSubmit = async (data) => {
    console.log("New Password:", data.newpassword);
  
    Swal.fire({
      title: 'Change Password',
      text: 'Are you sure you want to change the password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              token: searchParams.get("token"),
              newPassword: data.newpassword
            
            })
          });

          const result = await response.json();
  
          if (response.status === 200) {
            Swal.fire({
              title: 'Success!',
              text: result.message,
              icon: 'success'
            }).then(() => {
              navigate('/');
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: result.message || 'An error occurred',
              icon: 'error'
            });
  
            if (result.error === 'Token has expired. Please request a new password reset.') {
              navigate('/forgot-password');
            }
          }
        } catch (error) {
          console.error(error);
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while processing your request',
            icon: 'error'
          });
        }
      }
    });
  };
  
  return (
    <Box className='content-right' sx={{ display: 'flex', width: '100%', height: '100vh' }}>
      {!hidden ? (
        <Box sx={{ flexGrow: 1, opacity: 1, position: 'relative' }}>
          <MaskImg src={`/images/forgotpassword.png`} />

          <Box
            sx={{
              position: 'absolute',
              top: '50%',  // Vertically center the box
              left: '50%', // Horizontally center the box
              transform: 'translate(-50%, -50%)', // Move the box back by 50% of its width and height to truly center it

              zIndex: 2, // Keeps the login icon on top of the background image
            }}
          >


            <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 1000 }} easing={{
              enter: 'ease-out',  // Slows down at the end
              exit: 'ease-in',     // Starts slow and speeds up when exiting
            }}>
              <img
                src='/images/resetpassword.png'
                alt='welcome'
                style={{ width: 600, height: 'auto', objectFit: 'contain' }}
              />
            </Slide>

          </Box>
        </Box>
      ) : null}
      <RightWrapper>
        <Box
          sx={{
            p: 10,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(45deg, #101847, #00669e)',
            boxShadow: '0 25px 50px 2px ',
            broder: 1,
            borderColor: 'red',

          }}
        >
          <BoxWrapper>
            <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center', mb: 10 }}>
              <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 1000 }} easing={{
                enter: 'ease-out',  // Slows down at the end
                exit: 'ease-in',     // Starts slow and speeds up when exiting
              }}>
                <img
                  src={systemSetting ? systemSetting.logo_white : ''}
                  alt='English teacher'
                  style={{ width: '100%', height: 'auto' }}
                />
              </Slide>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 6 }}>

              <TypographyStyled variant='h5' sx={{ color: 'white' }}>Reset Password 🔒</TypographyStyled>
              <Typography variant='body2' sx={{ color: 'white' }}>
                Your new password must be different from previously used passwords
              </Typography>
            </Box>
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ mb: 4 }} size='small'>
                <InputLabel htmlFor='auth-reset-password-v2-new-password' sx={{ color: '', '&.Mui-focused': { color: '#5BB4E4' } }}>New Password</InputLabel>
                <OutlinedInput
                  autoFocus
                  label='New Password'
                  id='auth-reset-password-v2-new-password'
                  type={values.showNewPassword ? 'text' : 'password'}
                  {...register('newpassword')}
                  error={Boolean(errors.newpassword)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={handleClickShowNewPassword}
                        onMouseDown={e => e.preventDefault()}
                        aria-label='toggle password visibility'
                      >
                        <Icon icon={values.showNewPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderColor: 'white',
                    backgroundColor: 'white'

                  }}
                />
                <FormHelperText error id="user-view-security-current-password">
                  {errors.newpassword && errors.newpassword.message}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 4 }} size='small'>
                <InputLabel htmlFor='auth-reset-password-v2-confirm-password' sx={{ color: '', '&.Mui-focused': { color: '#5BB4E4' } }}>Confirm Password</InputLabel>
                <OutlinedInput
                  label='Confirm Password'
                  id='auth-reset-password-v2-confirm-password'
                  type={values.showConfirmNewPassword ? 'text' : 'password'}
                  {...register('confirrmpassword')}
                  error={Boolean(errors.confirrmpassword)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onMouseDown={e => e.preventDefault()}
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmNewPassword}
                      >
                        <Icon icon={values.showConfirmNewPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderColor: 'white',
                    backgroundColor: 'white'

                  }}
                />
                <FormHelperText error id="user-view-security-current-password">
                  {errors.confirrmpassword && errors.confirrmpassword.message}
                </FormHelperText>
              </FormControl>

              <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 5.25, backgroundColor: '#5BB4E4', ":hover": { backgroundColor: '#5BB4E4' } }}>
                Set New Password
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Link to="/" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      '& svg': { mr: 1.5 },
                      alignItems: 'center',
                      color: 'white',
                      textDecoration: 'none',
                      justifyContent: 'center'
                    }}
                  >
                    <Icon icon='mdi:chevron-left' fontSize='2rem' />
                    <span>Back to login</span>
                  </Typography>
                </Link>
              </Box>
            </form>
          </BoxWrapper>
        </Box>
      </RightWrapper>
    </Box>
  )
}


export default ResetPassword
